import { Component } from "react";
import styles from "./Leg.module.scss";
import moment from "moment";
import {
  Walk,
  Ferry,
  Rail,
  Bus,
  Tram,
  Underground,
  Drive,
  Park,
  Cycle,
  CycleDock,
  Destination,
  Plane,
  EvStation,
} from "../../utils/index";
import axios from "axios";
import { ThemeContext } from "../../Context/ThemeContext";

const dots = ["", ".", "..", "...", "...."];
const loadingInterval = Math.round(2000 / (dots.length * 2));
let timer;
class Leg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pressed: false,
      timeDifference: 0,
      evChargeText: "",
    };
  }

  handleAddAnimationToEvChargeText = (tempText) => {
    let dotsIndex = 0;

    timer = setInterval(() => {
      this.setState({ evChargeText: tempText + dots[dotsIndex] });
      dotsIndex += 1;

      if (dotsIndex === dots.length) {
        dotsIndex = 0;
      }
    }, loadingInterval);

    return;
  };

  componentDidMount = () => {
    if (
      this.props.leg.mode === "EvStation" &&
      this.props.leg.chargePoint !== undefined &&
      this.props.leg.chargePoint.id !== undefined
    ) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            "chargePoint/" +
            this.props.leg.chargePoint.id,
          {
            headers: {
              Authorization: this.props.apiToken,
              "Accept-Language": "en-GB",
            },
          }
        )
        .then((response) => {
          if (
            this.props.showTravelPlan &&
            this.props.travelForecast !== undefined
          ) {
            if (this.props.leg.chargePoint.isReservable) {
              if (
                response.data &&
                (this.props.travelForecast.evReservation === undefined ||
                  this.props.travelForecast.evReservation === null ||
                  this.props.travelForecast.evReservation.status ===
                    undefined ||
                  this.props.travelForecast.evReservation.status === "")
              ) {
                this.setState({ evChargeText: "Reserve now" });
              } else if (
                this.props.travelForecast.evReservation.status === "RESERVED"
              ) {
                this.setState({ evChargeText: "Start charging" });
              } else if (
                this.props.travelForecast.evReservation.status === "STARTED"
              ) {
                this.setState({ evChargeText: "Stop charging" });
              } else {
                this.setState({ evChargeText: "Not available" });
              }
            } else {
              this.setState({ evChargeText: "" });
              //this.setState({ evChargeText: "Request charge" });
            }
          } else if (this.props.leg.chargePoint.isReservable) {
            this.setState({ evChargeText: "To reserve, get a travel plan" });
          } else {
            this.setState({ evChargeText: "For support, get a travel plan" });
          }
        });
    }
  };

  dwellTimeUpdated = (event) => {
    var newDate = new Date(
      new Date(this.props.leg.arrivalTime).toDateString() +
        " " +
        event.target.value
    );
    var timeDifferenceTemp = 0;

    if (typeof this.props.leg.dwellTimeActive === "undefined") {
      timeDifferenceTemp =
        newDate.getTime() - new Date(this.props.leg.arrivalTime).getTime();
    } else {
      timeDifferenceTemp =
        newDate.getTime() - new Date(this.props.leg.arrivalTime).getTime();
    }

    this.setState({ timeDifference: timeDifferenceTemp });
  };
  toggleClass = (event) => {
    this.setState({ pressed: !this.state.pressed });
    if (this.state.pressed) {
      this.props.onUpdateTimeClicked(
        this.props.legIndex,
        this.state.timeDifference
      );
    }
  };

  handleJourneyLegClicked = (e) => {
    e.stopPropagation();
    const leg = this.props.leg;
    this.props.onJourneyLegClicked(
      leg,
      this.props.leg.boundingBox,
      this.props.legIndex,
      false
    );
  };

  handleRandom = () => {
    this.toggleSelection();
  };

  handleReserveButtonClick = () => {
    if (
      this.state.evChargeText === "To reserve, get a travel plan" ||
      this.state.evChargeText === "For support, get a travel plan"
    ) {
      this.toggleSelection();
      return;
    }

    if (this.state.evChargeText === "Request charge") {
      window.open("https://co-charger.com/charge-my-ev/", "_blank");
      return;
    }

    if (this.state.evChargeText === "Reserve now") {
      // reserve charge point
      this.handleAddAnimationToEvChargeText("Reserve now");
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            "reserveDestination/" +
            this.props.leg.chargePoint.id +
            "/" +
            this.props.leg.chargePoint.selectedEvse +
            "/" +
            this.props.travelForecast.id,
          {
            headers: {
              Authorization: this.props.apiToken,
              "Accept-Language": "en-GB",
            },
          }
        )
        .then((response) => {
          console.log(response);

          this.props.showInfoPopup(
            "Sorry, there is a technical problem with the charge point reservation system. No reservation has been made."
          );
          clearInterval(timer);
          this.setState({ evChargeText: "Start charging" });
        });
      return;
    }

    if (this.state.evChargeText === "Start charging") {
      // send request to OCPI to start charging
      this.handleAddAnimationToEvChargeText("Start charging");

      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            "startSession/" +
            this.props.travelForecast.id,
          {
            headers: {
              Authorization: this.props.apiToken,
              "Accept-Language": "en-GB",
            },
          }
        )
        .then((response) => {
          console.log(response);
          clearInterval(timer);
          this.setState({ evChargeText: "Stop charging" });
        });
      return;
    }

    if (this.state.evChargeText === "Stop charging") {
      // send request to OCPI to Stop charging
      this.handleAddAnimationToEvChargeText("Stop charging");
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
            "endSession/" +
            this.props.travelForecast.id,
          {
            headers: {
              Authorization: this.props.apiToken,
              "Accept-Language": "en-GB",
            },
          }
        )
        .then((response) => {
          console.log(response);
          clearInterval(timer);
          this.setState({ evChargeText: "Reserve now" });
        });
    }
  };

  toggleSelection = () => {
    this.props.toggleSelection();
  };

  showInfoPopup = (info) => {
    console.log("1");
    this.props.showInfoPopup(info);
  };

  toggleRequestAssistance = (travelforecastId) => {
    this.props.toggleRequestAssistance(travelforecastId);
  };

  render() {
    var {
      departureTime,
      estimatedDepartureTime,
      arrivalTime,
      estimatedArrivalTime,
      distance,
      operatorName,
      duration,
      endLocation,
      startLocation,
      mode,
      routeCode,
      dwellTimeActive,
      timeDifferenceDeparture,
      timeDifferenceArrival,
      intermediateStops,
    } = this.props.leg;

    var fareAmount;
    if (this.props.fareDetails) {
      fareAmount =
        this.props.fareDetails.currency === "GBP"
          ? "£" + this.props.fareDetails.price.toFixed(2)
          : this.props.fareDetails.price.toFixed(2) +
            " " +
            this.props.fareDetails.currency;
    }

    if (dwellTimeActive) {
      departureTime = new Date(
        new Date(departureTime).getTime() + timeDifferenceDeparture
      );
      arrivalTime = new Date(
        new Date(arrivalTime).getTime() + timeDifferenceArrival
      );
    }

    const legIcon = {
      Walk: <Walk className={styles["Walk"]} />,
      Rail: <Rail className={styles["Rail"]} />,
      Bus: <Bus className={styles["Bus"]} />,
      Ferry: <Ferry className={styles["Ferry"]} />,
      Cycle: <Cycle className={styles["Cycle"]} />,
      CycleDock: <CycleDock className={styles["Cycle"]} />,
      CycleStreetsQuietest: <Cycle className={styles["Cycle"]} />,
      CycleStreetsFastest: <Cycle className={styles["Cycle"]} />,
      CycleStreetsBalanced: <Cycle className={styles["Cycle"]} />,
      CycleStreetsDefault: <Cycle className={styles["Cycle"]} />,
      Tram: <Tram className={styles["Tram"]} />,
      Metro: <Tram className={styles["Metro"]} />,
      Coach: <Bus className={styles["Coach"]} />,
      Underground: <Underground className={styles["Underground"]} />,
      Drive: <Drive className={styles["Drive"]} />,
      ElectricVehicle: <Drive className={styles["Drive"]} />,
      Park: <Park className={styles["Park"]} />,
      PlatformChange: <Walk className={styles["Walk"]} />,
      EvStation: <EvStation className={styles["EvStation"]} />,
      Flight: <Plane className={styles["Plane"]} />,
      Destination: <Destination className={styles["Destination"]} />,
    }[mode];

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <li
            role="button"
            tabIndex="0"
            aria-pressed="false"
            className={
              this.props.className === "active"
                ? styles["legActive"]
                : styles["leg"]
            }
            style={{ "--theme-color": theme.theme.primaryColour }}
            onClick={this.handleJourneyLegClicked}
          >
            <div className={styles["leg-sub"]}>
              <div className={styles["leg-icon-outer-container"]}>
                <div className={styles["leg-icon-inner-container"]}>
                  <div data-cy={"legs"} className={styles["leg-icon"]}>
                    {legIcon}
                  </div>
                </div>
              </div>
              <div className={styles["leg-title-outer-container"]}>
                <div className={styles["leg-title-inner-container"]}>
                  <div className={styles["leg-title"]}>
                    <div className={styles["leg-subtitle-container"]}>
                      <span className={styles["leg-subtitle"]}>
                        {mode !== "Flight"
                          ? moment.parseZone(departureTime).format("H:mm") + " "
                          : ""}
                        {this.props.showExtendedDate
                          ? moment
                              .parseZone(departureTime)
                              .format(" on ddd D MMM") + " "
                          : ""}

                        {estimatedDepartureTime !== undefined && (
                          <span className={styles["leg-subtitle-estimated"]}>
                            Est.
                            {" " +
                              moment
                                .parseZone(estimatedDepartureTime)
                                .format("H:mm")}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className={styles["leg-content"]}>
                      {mode === "Destination" &&
                      this.props.legIndex !== this.props.legLength - 1 ? (
                        <div>
                          <span>{endLocation.description}</span>
                          <br />
                          {this.props?.allowEditTime ? (
                            <button
                              className={styles["a"]}
                              onClick={this.toggleClass}
                            >
                              {this.state.pressed
                                ? "Update departure time"
                                : "Edit departure time"}
                            </button>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : mode === "Destination" &&
                        this.props.legIndex === this.props.legLength - 1 ? (
                        endLocation.description
                      ) : mode === "PlatformChange" ? (
                        "Change platform at " + endLocation.description
                      ) : mode !== "EvStation" ? (
                        mode === "CycleDock" ? (
                          " Collect bike at " +
                          startLocation.description +
                          " and dock at " +
                          endLocation.description
                        ) : (
                          startLocation.description +
                          " to " +
                          endLocation.description
                        )
                      ) : (
                        "Charge at " + startLocation.description
                      )}
                    </div>
                    {mode === "EvStation" && this.state.evChargeText !== "" && (
                      <div className={styles["Button"]}>
                        <div className={styles["button-container"]}>
                          <button
                            className={styles["a"]}
                            style={{ color: theme.theme.primaryColour }}
                            onClick={(e) => {
                              e.stopPropagation();
                              this.handleReserveButtonClick();
                            }}
                          >
                            {this.state.evChargeText}
                          </button>
                        </div>
                      </div>
                    )}
                    {routeCode && mode === "Underground"
                      ? " (" + routeCode + " line) "
                      : routeCode && mode === "Bus"
                      ? " (Bus " + routeCode + ") "
                      : routeCode
                      ? " (" + routeCode + ") "
                      : ""}
                    {distance <= 0 ? (
                      <div className={styles["leg-subtitle-container"]}>
                        <span className={styles["leg-subtitle"]}>
                          {operatorName}
                        </span>
                      </div>
                    ) : (
                      <div className={styles["leg-subtitle-container"]}>
                        <span className={styles["leg-subtitle"]}>
                          {distance ? distance.toFixed(2) : ""}
                          {distance
                            ? theme.isKilometres
                              ? " kilometres "
                              : " miles "
                            : ""}
                          {this.props.hasIntermediateStops ? (
                            this.props.isIntermediateStopsVisible ? (
                              <button
                                className={styles["show-directions-button"]}
                                onClick={() => {
                                  this.props.toggleIntermediateStopsModal();
                                }}
                                style={{ color: theme.theme.primaryColour }}
                              >
                                {intermediateStops &&
                                intermediateStops.length > 0
                                  ? intermediateStops.length === 1
                                    ? " Show 1 stop"
                                    : " Show " +
                                      intermediateStops.length +
                                      " stops"
                                  : ""}
                              </button>
                            ) : intermediateStops &&
                              intermediateStops.length > 0 ? (
                              intermediateStops.length === 1 ? (
                                " 1 stop"
                              ) : (
                                " " + intermediateStops.length + " stops"
                              )
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    )}
                    <div className={styles["leg-subtitle-container"]}>
                      <span className={styles["leg-subtitle"]}>
                        {mode !== "Flight" ? (
                          mode !== "Destination" ? (
                            moment.parseZone(arrivalTime).format("H:mm") + " "
                          ) : !this.state.pressed &&
                            mode === "Destination" &&
                            this.props.legIndex !== this.props.legLength - 1 ? (
                            moment.parseZone(arrivalTime).format("H:mm") + " "
                          ) : this.state.pressed &&
                            mode === "Destination" &&
                            this.props.legIndex !== this.props.legLength - 1 ? (
                            <input
                              className={styles["input"]}
                              type="time"
                              onChange={this.dwellTimeUpdated}
                              defaultValue={moment
                                .parseZone(arrivalTime)
                                .format("H:mm")}
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {estimatedArrivalTime !== undefined && (
                          <span className={styles["leg-subtitle-estimated"]}>
                            Est.
                            {" " +
                              moment
                                .parseZone(estimatedArrivalTime)
                                .format("H:mm")}
                          </span>
                        )}
                      </span>
                      {this.props.hasDirections ? (
                        <button
                          className={styles["show-directions-button"]}
                          onClick={() => {
                            this.props.toggleDirectionsModal();
                          }}
                          style={{ color: theme.theme.primaryColour }}
                        >
                          Show directions
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles["leg-detail-outer-container"]}>
                <div className={styles["leg-detail-inner-container"]}>
                  <div className={styles["leg-detail"]}>
                    {Math.floor(duration / 60) === 0
                      ? ""
                      : Math.floor(duration / 60) + " h"}{" "}
                    {duration % 60 === 0 ? "" : (duration % 60) + " min"}
                  </div>
                  {fareAmount &&
                  !theme?.hideClientElements?.includes("hideFares") ? (
                    <div
                      style={{ paddingTop: "35px" }}
                      className={styles["leg-detail"]}
                    >
                      {fareAmount}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </li>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Leg;
