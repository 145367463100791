import styles from "./EditPublicTransport.module.scss";
import {
  BusActive,
  BusInactive,
  TramActive,
  TramInactive,
  TrainActive,
  TrainInactive,
} from "../../utils/index";
import { useIntl } from "react-intl";
import { ThemeContext } from "../../Context/ThemeContext";
import { useContext } from "react";

const EditPublicTransport = (props) => {
  const intl = useIntl();
  const theme = useContext(ThemeContext);

  const { handlefilterBus, handlefilterTram, handlefilterTrain } = props;

  const { filterBus, filterTram, filterTrain } = props;

  return (
    <div>
      <div
        onClick={handlefilterBus}
        style={{
          background: filterBus
            ? theme.theme.primaryColour !== null
              ? theme.theme.primaryColour
              : "black"
            : "white",
        }}
        className={`${styles["numeric-button-container"]} ${
          filterBus ? styles["active"] : styles["inactive"]
        }`}
        tabIndex="0"
      >
        {filterBus ? (
          <div className={styles["button-container-icon-box"]}>
            <BusActive className={styles["numeric-button-container-icon"]} />
          </div>
        ) : (
          <div className={styles["button-container-icon-box"]}>
            <BusInactive className={styles["numeric-button-container-icon"]} />
          </div>
        )}
        <div className={styles["numeric-button-text-box"]}>
          <p className={styles["numeric-button-text"]}>
            <p>{intl.formatMessage({ id: "bus" })}</p>
          </p>
        </div>
      </div>
      <div
        onClick={handlefilterTram}
        style={{
          background: filterTram
            ? theme.theme.primaryColour !== null
              ? theme.theme.primaryColour
              : "black"
            : "white",
        }}
        className={`${styles["numeric-button-container-right"]} ${
          filterTram ? styles["active"] : styles["inactive"]
        }`}
        tabIndex="0"
      >
        {filterTram ? (
          <div className={styles["button-container-icon-box"]}>
            <TramActive className={styles["numeric-button-container-icon"]} />
          </div>
        ) : (
          <div className={styles["button-container-icon-box"]}>
            <TramInactive className={styles["numeric-button-container-icon"]} />
          </div>
        )}
        <div className={styles["numeric-button-text-box"]}>
          <p className={styles["numeric-button-text"]}>
            <p>{intl.formatMessage({ id: "tram" })}</p>
          </p>
        </div>
      </div>
      <div
        onClick={handlefilterTrain}
        style={{
          background: filterTrain
            ? theme.theme.primaryColour !== null
              ? theme.theme.primaryColour
              : "black"
            : "white",
        }}
        className={`${styles["numeric-button-container"]} ${
          filterTrain ? styles["active"] : styles["inactive"]
        }`}
        tabIndex="0"
      >
        {filterTrain ? (
          <div className={styles["button-container-icon-box"]}>
            <TrainActive className={styles["numeric-button-container-icon"]} />
          </div>
        ) : (
          <div className={styles["button-container-icon-box"]}>
            <TrainInactive
              className={styles["numeric-button-container-icon"]}
            />
          </div>
        )}
        <div className={styles["numeric-button-text-box"]}>
          <p className={styles["numeric-button-text"]}>
            <p>{intl.formatMessage({ id: "train" })}</p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditPublicTransport;
