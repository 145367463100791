import React, { Component } from "react";
import styles from "./TravelPlanCollection.module.scss";
import TravelPlan from "../TravelPlan";
import NoPlan from "../NoPlans";
import { FormattedMessage } from "react-intl";

class TravelPlanCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previousTravelPlans: null,
      filterPast: false,
    };
  }

  componentDidMount = () => {};

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    this.setState({
      filterPast: nextProps.filterPast,
    });
    if (nextProps.previousTravelPlans != null) {
      var filteredPlans = nextProps.previousTravelPlans.filter(
        (travelPlan) => new Date(travelPlan.filteringTime) > new Date()
      );
      if (filteredPlans.length === 0) {
        this.setState({
          previousTravelPlans: null,
        });
      } else {
        this.setState({
          previousTravelPlans: nextProps.previousTravelPlans,
        });
      }
    }
  };

  activateTravelPlan = (travelPlanOrder) => {
    if (travelPlanOrder !== null) {
      if (!this.props.filterPast) {
        this.props.previousTravelPlans[
          travelPlanOrder
        ].journeyPlan.response.journeyOptions.forEach(function (journey) {
          journey.active = true;
        });
      } else {
        if (this.props.pastTravelPlans[travelPlanOrder].isItinerary === true) {
          this.props.pastTravelPlans[
            travelPlanOrder
          ].itinerary.responseList.journeyOptions.forEach(function (itinerary) {
            itinerary.active = true;
          });
        } else {
          this.props.pastTravelPlans[
            travelPlanOrder
          ].journeyPlan.response.journeyOptions.forEach(function (journey) {
            journey.active = true;
          });
        }
      }
    }
  };

  logout = () => {
    let logout = window.confirm("Do you want to log out from Travel Assistant");
    if (logout) this.props.logout();
  };

  showTravelPlanDetails = (order) => {
    if (order !== null) {
      this.props.journeys.journeyOptions.forEach(function (journey) {
        if (journey.order === order) {
          journey.show = true;
        } else {
          journey.show = false;
        }
      });
    } else {
      this.props.journeys.journeyOptions.forEach(function (journey) {
        journey.show = true;
      });
    }
  };

  toggleActiveTravelPlan = (travelPlanOrder) => {
    this.activateTravelPlan(travelPlanOrder);
  };

  render() {
    const { previousTravelPlans } = this.state;
    return (
      <div className={styles["JourneyCollection"]}>
        <ol className={styles["journey-list"]}>
          {this.props.filterPast ? (
            this.props.pastTravelPlans === null ? (
              <FormattedMessage id="noPastTrips">
                {(text) => <NoPlan NoPlanMessage={text} />}
              </FormattedMessage>
            ) : (
              this.props.pastTravelPlans.map((travelPlan, index) => (
                <TravelPlan
                  key={index + travelPlan.travelForecast.journeyOptionId}
                  travelForecast={travelPlan.travelForecast}
                  journeyRequest={
                    travelPlan.itinerary !== undefined
                      ? travelPlan.itinerary.requestList
                      : travelPlan.journeyPlan.request
                  }
                  journeyResponse={
                    travelPlan.itinerary !== undefined
                      ? travelPlan.itinerary.responseList
                      : travelPlan.journeyPlan.response
                  }
                  onJourneyClicked={this.handleJourneyClicked}
                  order={index}
                  toggleActiveTravelPlan={this.toggleActiveTravelPlan}
                  getPreviousTravelPlan={this.props.getPreviousTravelPlan}
                  isPreviousTravelPlan={false}
                  assistanceSettings={this.props.assistanceSettings}
                  destinationGroupAssistanceSettings={
                    this.props.destinationGroupAssistanceSettings
                  }
                  toggleRequestAssistance={this.props.toggleRequestAssistance}
                />
              ))
            )
          ) : previousTravelPlans === null ? (
            <FormattedMessage id="noPlannedTrips">
              {(text) => <NoPlan NoPlanMessage={text} />}
            </FormattedMessage>
          ) : (
            previousTravelPlans
              .slice(0)
              .reverse()
              .map((travelPlan, index) => (
                <TravelPlan
                  key={index + travelPlan.travelForecast.journeyOptionId}
                  travelForecast={travelPlan.travelForecast}
                  journeyRequest={
                    travelPlan.itinerary !== undefined
                      ? travelPlan.itinerary.requestList
                      : travelPlan.journeyPlan.request
                  }
                  journeyResponse={
                    travelPlan.itinerary !== undefined
                      ? travelPlan.itinerary.responseList
                      : travelPlan.journeyPlan.response
                  }
                  onJourneyClicked={this.handleJourneyClicked}
                  order={index}
                  toggleActiveTravelPlan={this.toggleActiveTravelPlan}
                  toggleRequestAssistance={this.props.toggleRequestAssistance}
                  getPreviousTravelPlan={this.props.getPreviousTravelPlan}
                  isPreviousTravelPlan={true}
                  assistanceSettings={this.props.assistanceSettings}
                  destinationGroupAssistanceSettings={
                    this.props.destinationGroupAssistanceSettings
                  }
                />
              ))
          )}
        </ol>
        <div className={styles["copy-button-container"]}>
          <button
            type="button"
            className={styles["api-button"]}
            onClick={this.logout}
          >
            <span className={styles["api-button-span"]}>
              <FormattedMessage id="logout" />
            </span>
          </button>
        </div>
      </div>
    );
  }
}

export default TravelPlanCollection;
