import styles from "./MarkerOptions.module.scss";
import loadable from "@loadable/component";
import { ThemeContext } from "../../Context/ThemeContext";
import { useContext } from "react";

const FontAwesomeIcon = loadable(
  () => import("@fortawesome/react-fontawesome"),
  {
    resolveComponent: (components) => components.FontAwesomeIcon,
  }
);

const MarkerOption = ({ index, param, active, text }) => {
  const theme = useContext(ThemeContext);

  const _renderTypedMarkerSwitch = (
    param,
    text,
    unCircledColor,
    primaryColor,
    secondaryColor
  ) => {
    const isLong = text.length > 20;
    return (
      <div
        style={{ backgroundColor: secondaryColor }}
        className={styles["numeric-button-container"]}
        data-cy="markerOption"
      >
        <div className={styles["step-free-container-icon"]}>
          <FontAwesomeIcon
            title={text}
            icon={param}
            color={primaryColor}
            size="2x"
          />
        </div>
        <div className={styles["numeric-button-text-box"]}>
          <p
            style={{ color: unCircledColor, fontSize: isLong ? 12 : 14 }}
            className={styles["numeric-step-free-button-text-box"]}
          >
            {text}
          </p>
        </div>
      </div>
    );
  };

  if (active) {
    return _renderTypedMarkerSwitch(
      param,
      text,
      "white",
      "white",
      theme.theme.primaryColour
    );
  } else {
    return _renderTypedMarkerSwitch(param, text, "black", "#484848", "white");
  }
};

export default MarkerOption;
