import React from "react";
import moment from "moment";
import styles from "./LegDetails.module.scss";
import { IconMapping } from "../../../utils/IconMapping";
const LegDetails = ({
  mode,
  time,
  location,
  routeCode,
  theme,
  handleReserveButtonClick,
  evChargeText,
  isDeparture,
  backgroundColor,
}) => {
  const IconComponent = IconMapping[mode];
  const legIcon = IconComponent ? (
    <IconComponent className={styles[mode] || ""} />
  ) : null;
  return (
    <div className={styles["modal-content"]}>
      <div className={styles["leg-sub"]}>
        <div
          className={styles["green-circle"]}
          style={{
            backgroundColor: backgroundColor,
          }}
        >
          {legIcon}
        </div>
        <div className={styles["leg-title-outer-container"]}>
          <div className={styles["leg-title-inner-container"]}>
            <div className={styles["leg-title"]}>
              {location.description}
              {mode === "EvStation" && (
                <div className={styles["Button"]}>
                  <div className={styles["button-container"]}>
                    <button
                      className={styles["a"]}
                      style={{ color: theme.theme.primaryColour }}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleReserveButtonClick && handleReserveButtonClick(e);
                      }}
                    >
                      {evChargeText}
                    </button>
                  </div>
                </div>
              )}
              {routeCode && mode === "Underground"
                ? " (" + routeCode + " line) "
                : routeCode && mode === "Bus"
                ? " (Bus " + routeCode + ") "
                : routeCode
                ? " (" + routeCode + ") "
                : ""}
              <div className={styles["leg-subtitle-container"]}>
                <span className={styles["leg-subtitle"]}>
                  {isDeparture ? "Dep " : "Arr "}
                  {mode !== "Flight"
                    ? moment.parseZone(time).format("H:mm")
                    : ""}
                  {moment.parseZone(time).format(" on ddd D MMM")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegDetails;
