import Modal from "../common/Modal";
import MarkerOptions from "../MarkerOptions/MarkerOptions";
import styles from "../MarkerOptions/MarkerOptions.module.scss";
import { useIntl } from "react-intl";

const MarkerModal = (props) => {
  const intl = useIntl();
  const {
    toggleModal,
    markerOptions,
    markerOptionsAction,
    isTrafficForecastAvailable,
    trafficForecast,
    switchTrafficForecast,
    isUserWayActive,
  } = props;

  return (
    <Modal
      title={intl.formatMessage({ id: "showOnMap" })}
      close={toggleModal}
      updateText={intl.formatMessage({ id: "apply" })}
      finish={toggleModal}
      isUserWayActive={isUserWayActive}
    >
      <div className={styles["marker-modal-container"]}>
        <MarkerOptions
          markerOptions={markerOptions}
          markerOptionsAction={markerOptionsAction}
          isTrafficForecastAvailable={isTrafficForecastAvailable}
          trafficForecast={trafficForecast}
          switchTrafficForecast={switchTrafficForecast}
        />
      </div>
    </Modal>
  );
};

export default MarkerModal;
