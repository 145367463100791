import Modal from "../common/Modal";
import DOMPurify from "dompurify";
import moment from "moment";
import LegDetails from "../common/LegDetail/LegDetail";
import { ThemeContext } from "../../Context/ThemeContext";
import styles from "./IntermediateStopsModel.module.scss";

const IntermediateStopsModel = (props) => {
  const { toggleModal, leg, isUserWayActive } = props;

  var {
    departureTime,
    arrivalTime,
    distance,
    operatorName,
    duration,
    endLocation,
    startLocation,
    mode,
    routeCode,
  } = leg;

  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <Modal
          title={"Intermediate Stops"}
          close={toggleModal}
          finish={toggleModal}
          shouldOverflow={true}
          isUserWayActive={isUserWayActive}
        >
          <LegDetails
            mode={mode}
            time={departureTime}
            location={startLocation}
            operatorName={operatorName}
            distance={distance}
            duration={duration}
            isDeparture={true}
            routeCode={routeCode}
            backgroundColor={theme.theme.primaryColour}
            theme={theme}
          />
          <div className={styles["content-wrapper"]}>
            <div className={styles["green-column-wrapper"]}>
              <div
                className={styles["green-column"]}
                style={{
                  backgroundColor: theme.theme.primaryColour,
                }}
              ></div>
            </div>
            <ul className={styles.IntermediateStopsList}>
              {leg.intermediateStops.map((stop) => (
                <li
                  key={stop.location.id}
                  className={styles.intermediateStopsItem}
                >
                  <div
                    className={styles.stationInfo}
                    style={{ "--primary-color": theme.theme.primaryColour }}
                  >
                    <div className={styles.stationName}>
                      {stop.location.description}
                    </div>
                    <div className={styles.stationDepartureTime}>
                      {stop.departureTime
                        ? moment.parseZone(stop.arrivalTime).format("HH:mm")
                        : ""}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <LegDetails
            mode={mode}
            time={arrivalTime}
            location={endLocation}
            operatorName={operatorName}
            distance={distance}
            duration={duration}
            isDeparture={false}
            backgroundColor={theme.theme.primaryColour}
            theme={theme}
          />
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
};

export default IntermediateStopsModel;
