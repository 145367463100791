import MarkerOption from "./MarkerOption";
import styles from "./MarkerOptions.module.scss";

const MarkerOptions = ({
  markerOptions,
  markerOptionsAction,
  isTrafficForecastAvailable,
  trafficForecast,
  switchTrafficForecast,
}) => {
  if (!markerOptions) return null;

  const organiseByHeading = (array) => {
    const enrichedArray = array.map((element, index) => ({
      ...element,
      index,
      heading: element.heading || "",
    }));

    return Object.values(
      enrichedArray.reduce((acc, element) => {
        const { heading } = element;
        acc[heading] = acc[heading] || [];
        acc[heading].push(element);
        return acc;
      }, {})
    );
  };

  const organisedMarkers = organiseByHeading(markerOptions);

  return (
    <>
      {organisedMarkers.map((group, groupIndex) =>
        group.map((markerOption, index) => {
          const { marker, heading, isLoading, isFocused } = markerOption;
          if (!marker?.icon) return null;
          return (
            <>
              {index === 0 && (
                <div style={{ width: "100%" }}>
                  <b className={styles["modal-subtitle"]}>{heading}</b>
                </div>
              )}
              <div onClick={() => markerOptionsAction(markerOption.index)}>
                <MarkerOption
                  index={index}
                  markerOptionsCount={markerOptions.length}
                  param={marker.icon}
                  active={isFocused}
                  loading={isLoading}
                  text={marker.name}
                />
              </div>
            </>
          );
        })
      )}
      {isTrafficForecastAvailable && (
        <div onClick={switchTrafficForecast}>
          <MarkerOption
            index={markerOptions.length}
            param={"exclamation"}
            active={trafficForecast}
            loading={false}
            text={"Disruptions"}
          />
        </div>
      )}
    </>
  );
};

export default MarkerOptions;
