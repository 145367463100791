import { useState, useEffect } from "react";

const useSessionTimeout = (expiryTime) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  useEffect(() => {
    if (!expiryTime || expiryTime <= new Date().getTime()) {
      return;
    }

    const checkSession = () => {
      const currentTime = new Date().getTime();
      if (currentTime >= expiryTime) {
        setIsSessionExpired(true);
        handleSessionExpiry();
      }
    };

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkSession();
      }
    };

    const handleFocus = () => {
      checkSession();
    };

    const interval = setInterval(checkSession, 1000);

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleFocus);

    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleFocus);
    };
  }, [expiryTime]);

  const handleSessionExpiry = () => {
    console.log("Session has expired");
  };

  return isSessionExpired;
};

export default useSessionTimeout;
