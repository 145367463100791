import React, { useState, useContext } from "react";
import styles from "./Modal.module.scss";
import { ThemeContext } from "../../../Context/ThemeContext";
import { isMobile } from "react-device-detect";
import { ReactComponent as Close } from "../../assets/round-close-24px.svg";

const Modal = ({
  children,
  isUserWayActive,
  title,
  close,
  finish,
  updateText,
}) => {
  const [isUpdateButtonHovered, setIsUpdateButtonHovered] = useState(false);
  const theme = useContext(ThemeContext);

  return (
    <div className={styles["modal"]}>
      <div
        className={styles["content"]}
        role="dialog"
      >
        <div className={styles["header"]}>
          <div className={styles["title-container"]}>
            <div className={styles["title"]}>
              <span>{title}</span>
            </div>
          </div>
          <div className={styles["button-container"]}>
            <button
              type="button"
              className={styles["button"]}
              onClick={close}
            >
              <Close className={styles["close"]} />
            </button>
          </div>
        </div>
        <div className={styles["main"]}>
          <section>{children}</section>
        </div>
      </div>
      {updateText && (
        <div
          className={styles["footer"]}
          style={{
            marginBottom: theme?.hideClientElements?.includes("hideFooter")
              ? -40
              : 0,
          }}
        >
          <div className={styles["confirm-button-container"]}>
            <button
              style={{
                background: !isUpdateButtonHovered
                  ? theme.theme.primaryColour
                  : theme.theme.hoverColour,
                maxWidth: isMobile && isUserWayActive ? "85%" : "100%",
              }}
              data-cy={"modalUpdateButton"}
              type="button"
              onMouseEnter={() => setIsUpdateButtonHovered(true)}
              onMouseLeave={() => setIsUpdateButtonHovered(false)}
              className={styles["confirm-button"]}
              onClick={finish}
            >
              <span className={styles["confirm-button-span"]}>
                {updateText}
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
