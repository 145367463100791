import { useContext } from "react";
import styles from "./EditPassengers.module.scss";
import { ReactComponent as Adults } from "../assets/Adults.svg";
import { ReactComponent as AdultsActive } from "../assets/Adults_w.svg";
import { ReactComponent as Children } from "../assets/Children.svg";
import { ReactComponent as ChildrenActive } from "../assets/Children_w.svg";
import { ReactComponent as Minus } from "../assets/minus_passive.svg";
import { ReactComponent as MinusActive } from "../assets/minus_active.svg";
import { ReactComponent as Plus } from "../assets/plus_passive.svg";
import { ReactComponent as PlusActive } from "../assets/plus_active.svg";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../Context/ThemeContext";
import styled from "styled-components";

const EditPassengers = (props) => {
  const { handleAdultFilterQuantity, handleChildFilterQuantity } = props;
  const { filterAdults, filterChildren } = props;
  const theme = useContext(ThemeContext);

  const StyledMinusActive = styled(MinusActive)`
    circle,
    path {
      fill: ${theme.theme.primaryColour} !important;
    }
  `;

  const StyledPlusActive = styled(PlusActive)`
    circle,
    path {
      fill: ${theme.theme.primaryColour} !important;
    }
  `;

  return (
    <div>
      <div
        style={{
          background: filterAdults > 0 ? theme.theme.primaryColour : "white",
        }}
        className={`${styles["numeric-button-container"]} ${
          filterAdults > 0 ? styles["active"] : styles["inactive"]
        }`}
      >
        <div className={styles["numeric-button-container-icon-box"]}>
          <div
            className={styles["numeric-button-sign-container"]}
            onKeyPress={() => handleAdultFilterQuantity(-1)}
            onClick={() => handleAdultFilterQuantity(-1)}
            tabIndex="0"
            title="Reduce the number of adults travelling by one"
          >
            {filterAdults > 0 ? (
              <StyledMinusActive
                data-cy={"minusAdultButton"}
                className={styles["numeric-button-container-minus"]}
              />
            ) : (
              <Minus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-minus"]}
              />
            )}
          </div>
          {filterAdults > 0 ? (
            <AdultsActive className={styles["numeric-button-container-icon"]} />
          ) : (
            <Adults className={styles["numeric-button-container-icon"]} />
          )}
          <div
            className={styles["numeric-button-sign-container"]}
            onKeyPress={() => handleAdultFilterQuantity(1)}
            onClick={() => handleAdultFilterQuantity(1)}
            tabIndex="0"
            title="Increase the number of adults travelling by one"
          >
            {filterAdults > 0 ? (
              <StyledPlusActive
                data-cy={"addAdultButtonActive"}
                className={styles["numeric-button-container-plus"]}
              />
            ) : (
              <Plus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-plus"]}
              />
            )}
          </div>
        </div>
        <div
          className={styles["numeric-button-text-box"]}
          tabIndex="0"
        >
          {filterAdults !== 1 ? (
            <span>
              {filterAdults} <FormattedMessage id="filterAdultsTxt" />{" "}
            </span>
          ) : (
            <span>
              {filterAdults} <FormattedMessage id="filterAdultTxt" />{" "}
            </span>
          )}
        </div>
      </div>
      <div
        style={{
          background: filterChildren > 0 ? theme.theme.primaryColour : "white",
        }}
        className={`${styles["numeric-button-container-right"]} ${
          filterChildren > 0 ? styles["active"] : styles["inactive"]
        }`}
      >
        <div className={styles["numeric-button-container-icon-box"]}>
          <div
            className={styles["numeric-button-sign-container"]}
            onClick={() => handleChildFilterQuantity(-1)}
            onKeyPress={() => handleChildFilterQuantity(-1)}
            tabIndex="0"
            title="Reduce the number of children travelling by one"
          >
            {filterChildren > 0 ? (
              <StyledMinusActive
                data-cy={"minusChildrenButtonActive"}
                className={styles["numeric-button-container-minus"]}
              />
            ) : (
              <Minus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                className={styles["numeric-button-container-minus"]}
              />
            )}
          </div>
          {filterChildren > 0 ? (
            <ChildrenActive
              className={styles["numeric-button-container-icon"]}
            />
          ) : (
            <Children className={styles["numeric-button-container-icon"]} />
          )}
          <div
            className={styles["numeric-button-sign-container"]}
            onClick={() => handleChildFilterQuantity(1)}
            onKeyPress={() => handleChildFilterQuantity(1)}
            tabIndex="0"
            title="Increase the number of children travelling by one"
          >
            {filterChildren > 0 ? (
              <StyledPlusActive
                data-cy={"addChildrenButtonActive"}
                className={styles["numeric-button-container-plus"]}
              />
            ) : (
              <Plus
                style={{ backgroundColor: "#404040", borderRadius: "50%" }}
                data-cy={"addChildrenButton"}
                className={styles["numeric-button-container-plus"]}
              />
            )}
          </div>
        </div>
        <div
          className={styles["numeric-button-text-box"]}
          tabIndex="0"
        >
          {filterChildren !== 1 ? (
            <span>
              {filterChildren} <FormattedMessage id="filterChildrenTxt" />{" "}
            </span>
          ) : (
            <span>
              {filterChildren} <FormattedMessage id="filterChildTxt" />{" "}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditPassengers;
