import {
  Walk,
  Ferry,
  Rail,
  Bus,
  Tram,
  Underground,
  ChevronRight,
  Drive,
  Park,
  Cycle,
  CycleDock,
  Destination,
  Plane,
  EvStation,
  Alert,
  CarbonInfo,
} from "./index";

export const IconMapping = {
  Walk: Walk,
  Rail: Rail,
  Bus: Bus,
  Ferry: Ferry,
  Cycle: Cycle,
  CycleDock: CycleDock,
  CycleStreetsQuietest: Cycle,
  CycleStreetsFastest: Cycle,
  CycleStreetsBalanced: Cycle,
  CycleStreetsDefault: Cycle,
  Tram: Tram,
  Metro: Tram,
  Coach: Bus,
  Underground: Underground,
  Drive: Drive,
  ElectricVehicle: Drive,
  Park: Park,
  PlatformChange: Walk,
  EvStation: EvStation,
  Flight: Plane,
  Destination: Destination,
};
