import Modal from "../common/Modal";
import DOMPurify from "dompurify";
import styles from "../Leg/Leg.module.scss";
import moment from "moment";

import { ThemeContext } from "../../Context/ThemeContext";
import { IconMapping } from "../../utils/IconMapping";

const DirectionsModal = (props) => {
  const { toggleModal, leg, isUserWayActive } = props;

  const resultArray = leg.directions.map((entry) => `<li>${entry}</li>`);
  const resultString = resultArray.join("</br>");
  const finalHTML = `<ul>${resultString}</ul>`;

  var {
    departureTime,
    arrivalTime,
    distance,
    operatorName,
    duration,
    endLocation,
    startLocation,
    mode,
    routeCode,
  } = leg;
  const IconComponent = IconMapping[mode];
  const legIcon = IconComponent ? (
    <IconComponent className={styles[mode] || ""} />
  ) : null;
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <Modal
          title={"Turn by Turn Directions"}
          close={toggleModal}
          finish={toggleModal}
          isUserWayActive={isUserWayActive}
        >
          <div className={styles["leg-sub"]}>
            <div className={styles["leg-icon-outer-container"]}>
              <div className={styles["leg-icon-inner-container"]}>
                <div
                  data-cy={"legs"}
                  className={styles["leg-icon"]}
                >
                  {legIcon}
                </div>
              </div>
            </div>
            <div className={styles["leg-title-outer-container"]}>
              <div className={styles["leg-title-inner-container"]}>
                <div className={styles["leg-title"]}>
                  <div className={styles["leg-subtitle-container"]}>
                    <span className={styles["leg-subtitle"]}>
                      {mode !== "Flight"
                        ? moment.parseZone(departureTime).format("H:mm")
                        : ""}
                      {moment.parseZone(departureTime).format(" on ddd D MMM")}
                    </span>
                  </div>
                  {mode === "Destination" ? (
                    <div>
                      <span>{endLocation.description}</span>
                    </div>
                  ) : mode === "Destination" ? (
                    endLocation.description
                  ) : mode !== "EvStation" ? (
                    mode === "CycleDock" ? (
                      " Collect bike at " +
                      startLocation.description +
                      " and dock at " +
                      endLocation.description
                    ) : (
                      startLocation.description +
                      " to " +
                      endLocation.description
                    )
                  ) : (
                    "Charge at " + startLocation.description
                  )}
                  {mode === "EvStation" && (
                    <div className={styles["Button"]}>
                      <div className={styles["button-container"]}>
                        <button
                          className={styles["a"]}
                          style={{ color: theme.theme.primaryColour }}
                          onClick={(e) => {
                            e.stopPropagation();
                            this.handleReserveButtonClick();
                          }}
                        >
                          {this.state.evChargeText}
                        </button>
                      </div>
                    </div>
                  )}
                  {routeCode && mode === "Underground"
                    ? " (" + routeCode + " line) "
                    : routeCode && mode === "Bus"
                    ? " (Bus " + routeCode + ") "
                    : routeCode
                    ? " (" + routeCode + ") "
                    : ""}
                  {distance <= 0 ? (
                    <div className={styles["leg-subtitle-container"]}>
                      <span className={styles["leg-subtitle"]}>
                        {operatorName}
                      </span>
                    </div>
                  ) : (
                    <div className={styles["leg-subtitle-container"]}>
                      <span className={styles["leg-subtitle"]}>
                        {distance ? distance.toFixed(2) : ""}
                        {theme.isKilometres ? " kilometres" : " miles"}
                      </span>
                    </div>
                  )}
                  <div className={styles["leg-subtitle-container"]}>
                    <span className={styles["leg-subtitle"]}>
                      {mode !== "Flight" ? (
                        mode !== "Destination" ? (
                          moment.parseZone(arrivalTime).format("H:mm")
                        ) : true === "Destination" ? (
                          moment.parseZone(arrivalTime).format("H:mm")
                        ) : true ? (
                          <input
                            className={styles["input"]}
                            type="time"
                            onChange={this.dwellTimeUpdated}
                            defaultValue={moment
                              .parseZone(arrivalTime)
                              .format("H:mm")}
                          />
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["leg-detail-outer-container"]}>
              <div className={styles["leg-detail-inner-container"]}>
                <div className={styles["leg-detail"]}>
                  {Math.floor(duration / 60) === 0
                    ? ""
                    : Math.floor(duration / 60) + " h"}{" "}
                  {duration % 60 === 0 ? "" : (duration % 60) + " min"}
                </div>
              </div>
            </div>
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(finalHTML, {
                ADD_ATTR: ["target"],
                // sanitize: true, TODO: Re-implement this!
              }),
            }}
          />
        </Modal>
      )}
    </ThemeContext.Consumer>
  );
};

export default DirectionsModal;
