import React, { PureComponent } from "react";
import { ReactComponent as LocationIcon } from "../../components/assets/room-24px.svg";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import loadable from "@loadable/component";
import styles from "./LocationOn.module.scss";

const FontAwesomeIcon = loadable(
  () => import("@fortawesome/react-fontawesome"),
  {
    resolveComponent: (components) => components.FontAwesomeIcon,
  }
);
library.add(fas);

export default class ClusteringLocationOn extends PureComponent {
  render() {
    const { onClick } = this.props;
    var color = "black";
    var tempIcon = "";
    const event = this.props.event;
    if (
      event !== undefined &&
      event.markerDetail !== undefined &&
      event.markerDetail.color !== undefined
    ) {
      color = event.markerDetail.color;
    }

    if (event !== undefined) {
      if (
        event.markerDetail !== undefined &&
        event.markerDetail.type !== undefined
      ) {
        if (
          event.markerDetail.circleActive !== undefined &&
          event.markerDetail.circleActive === true
        ) {
          tempIcon = (
            <div
              onClick={onClick}
              className={styles["marker-container"]}
              style={{ backgroundColor: event.markerDetail.activePrimaryColor }}
            >
              <FontAwesomeIcon
                title={event.markerDetail.text}
                icon={event.markerDetail.type}
                color={event.markerDetail.activeSecondaryColor}
                style={{
                  width: "1rem",
                  height: "1rem",
                }}
              />
            </div>
          );
        } else {
          tempIcon = (
            <FontAwesomeIcon
              className={styles["Default-layer-3x"]}
              icon={event.markerDetail.type}
              onClick={onClick}
              fixedWidth
              style={{
                fontSize: "43px",
              }}
              color={color}
            />
          );
        }
      } else {
        tempIcon = (
          <LocationIcon
            fill={color}
            onClick={onClick}
            className={styles["LocationOn"]}
          />
        );
      }
    }

    return tempIcon;
  }
}
