import { useEffect, useState, useContext } from "react";
import ClusteringLocationOn from "../common/ClusteringLocationOn";
import ClusterMarker from "../MapMarkers/ClusterMarker";
import { Marker } from "@urbica/react-map-gl";
import ClusterContext from "./ClusterContext";

//Handles the full process for displaying a markerGroup
const MarkerGroup = ({
  _restServicePost,
  markerOption,
  isMultiDestination,
  queryString,
  primaryGroupShortCode,
  setActiveEventSelectedOnMap,
  onClickCluster,
  mapBounds,
  zoom,
}) => {
  const { registerMarkers, unRegisterMarkers } = useContext(ClusterContext);

  //Decides whether to use static or dynamic loading. Will attempt to load dynamically until it gets a static result from the backend
  const [usingBackendCache, setUsingBackendCache] = useState(true);

  //Static events, rendered and clustered using a clusterhandler
  const [frontClusteredMarkers, setFrontClusteredMarkers] = useState([]);

  //Dynamic clusters and events.
  const [currentEvents, setCurrentEvents] = useState([]);
  const [currentClusters, setCurrentClusters] = useState([]);
  const [currentDestinations, setCurrentDestinations] = useState([]);

  //State to manage when/why the dynamic clusters and events update
  const [currentBounds, setCurrentBounds] = useState({});
  const [currentZoom, setCurrentZoom] = useState(0);
  const [queryOnCooldown, setQueryOnCooldown] = useState(false);
  const [queryInProgress, setQueryInProgress] = useState(false);
  const updateRateLimitMS = 500;

  useEffect(() => {
    //Whenever the query is put on cooldown, take it off cooldown the appropriate amount of time later
    if (queryOnCooldown) {
      setTimeout(() => {
        setQueryOnCooldown(false);
      }, updateRateLimitMS);

      return;
    }
  }, [queryOnCooldown]);

  useEffect(() => {
    //The conditions in which an dynamic marker load could be triggered
    if (
      queryOnCooldown ||
      queryInProgress ||
      !mapBounds ||
      !zoom ||
      !usingBackendCache
    ) {
      return;
    }

    if (
      mapBounds.minLatitude !== currentBounds.minLatitude ||
      mapBounds.minLongitude !== currentBounds.minLongitude ||
      mapBounds.maxLatitude !== currentBounds.maxLatitude ||
      mapBounds.minLatitude !== currentBounds.minLatitude
    ) {
      //Only update if a change in viewport has occured, triggering the new load
      setQueryOnCooldown(true);
      setQueryInProgress(true);
      setCurrentBounds(mapBounds);
      setCurrentZoom(zoom);
    }
  }, [
    queryOnCooldown,
    queryInProgress,
    mapBounds,
    zoom,
    currentBounds,
    currentZoom,
    usingBackendCache,
  ]);

  useEffect(() => {
    /*
    //TODO: currentBounds is not set before the map is moved. Re-enable this filter when it is being properly set on initial load
    if (
      !currentBounds.minLatitude ||
      !currentBounds.minLongitude ||
      !currentBounds.maxLatitude ||
      !currentBounds.maxLongitude
    ) {
      return;
    }
    */

    var markerOptionRequest = {
      sourceIds: queryString.sourceIds,
      memberId: queryString.member_id,
      categories: queryString.categories,
      analyticsRestrictDate: queryString.analyticsRestrictDate,
      analyticsRestrictEvents: queryString.analyticsRestrictEvents,
      primaryGroupShortCode: primaryGroupShortCode,
      markerOptions: markerOption,
      restrictToBox: currentBounds,
      zoomLevel: currentZoom,
      edSlug: queryString.q
    };

    console.log(
      `Performing a cluster request for group ${markerOption.destinationGroupShortCode} with the following bounds and zoom ${currentZoom}`
    );
    console.log(currentBounds);

    _restServicePost("getClusters", markerOptionRequest).then((response) => {
      var destinationGroupData = response.data.destinationGroup;
      var destinations = destinationGroupData.destinations;
      var mapClusters = response.data.mapClusters;
      var events = response.data.events;
      var backendClustering = response.data.backendClustering;
      events.forEach((event) => {
        event.markerDetail = {
          type: markerOption.marker.icon,
          color: markerOption.marker.color,
          activePrimaryColor: markerOption.marker.activePrimaryColor,
          activeSecondaryColor: markerOption.marker.activeSecondaryColor,
          deactivePrimaryColor: markerOption.marker.deactivePrimaryColor,
          deactiveSecondaryColor: markerOption.marker.deactiveSecondaryColor,
          name: markerOption.marker.name,
          size:
            markerOption.marker.size === undefined
              ? "2x"
              : markerOption.marker.size,
          circleActive: markerOption.marker.circleActive,
        };

        if (
          destinationGroupData.destinationSourceType !== undefined &&
          destinationGroupData.destinationSourceType === "txgb"
        ) {
          event.isTXGBavailable = true;
        }
      });

      setQueryInProgress(false);

      if (backendClustering) {
        //Is a dynamically loading group
        setFrontClusteredMarkers([]);
        setCurrentEvents(events);
        setCurrentDestinations(destinations);
        setCurrentClusters(mapClusters);
        console.log(mapClusters);
        console.log(events);
      } else {
        //Is a statically loading group. Create the markers and register them for clustering
        setCurrentEvents([]);
        setCurrentClusters([]);
        setUsingBackendCache(false);
        var markers = events
          .filter(
            (event) =>
              !event.hideLocationMarker && event?.accessPoints?.length > 0
          )
          .map((event, index) => {
            var destination = destinations.find(
              (dest) => dest.relatedId === event.id
            );
            return (
              <Marker
                key={"FrontClustered" + index}
                latitude={event.accessPoints[0].location.latitude}
                longitude={event.accessPoints[0].location.longitude}
                offsetLeft={-20}
                offsetTop={-40}
              >
                <ClusteringLocationOn
                  event={event}
                  onClick={() =>
                    setActiveEventSelectedOnMap(event, destination)
                  }
                />
              </Marker>
            );
          });

        setFrontClusteredMarkers(markers);
      }
    });
  }, [
    _restServicePost,
    primaryGroupShortCode,
    isMultiDestination,
    markerOption,
    queryString,
    currentBounds,
    currentZoom,
    setActiveEventSelectedOnMap,
  ]);

  useEffect(() => {
    if (!usingBackendCache) {
      registerMarkers(frontClusteredMarkers);
      return () => {
        //Unregister the markers on unmount
        unRegisterMarkers(frontClusteredMarkers);
      };
    }
  }, [
    usingBackendCache,
    registerMarkers,
    unRegisterMarkers,
    frontClusteredMarkers,
  ]);

  return (
    <>
      {currentClusters.map((cluster, index) => (
        <ClusterMarker
          key={"ClusterMarker" + index}
          onClick={() => {
            onClickCluster({
              zoom: Math.max(...cluster.zoomLevels) + 1,
              longitude: cluster.location.coordinates.x,
              latitude: cluster.location.coordinates.y,
            });
          }}
          latitude={cluster.location.coordinates.y}
          longitude={cluster.location.coordinates.x}
          pointCount={cluster.count}
        />
      ))}
      {currentEvents.map((event, index) => {
        var destination = currentDestinations.find(
          (dest) => dest.relatedId === event.id
        );
        return (
          <Marker
            key={"BackClustered" + index}
            latitude={event.accessPoints[0].location.latitude}
            longitude={event.accessPoints[0].location.longitude}
            offsetLeft={-20}
            offsetTop={-40}
            clusterMe={true}
          >
            <ClusteringLocationOn
              event={event}
              onClick={() => setActiveEventSelectedOnMap(event, destination)}
            />
          </Marker>
        );
      })}
    </>
  );
};

export default MarkerGroup;
