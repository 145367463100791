import { Component } from "react";
import styles from "./EditProfile.module.scss";

import { ReactComponent as Accessibility } from "../../assets/Step_free.svg";
import { ReactComponent as AccessibilityActive } from "../../assets/Step_free_on.svg";
import { ReactComponent as Adults } from "../../assets/Adults.svg";
import { ReactComponent as AdultsActive } from "../../assets/Adults_w.svg";
import { ReactComponent as Bicycle } from "../../assets/Bicycle.svg";
import { ReactComponent as BicycleActive } from "../../assets/Bicycle_w.svg";
import { ReactComponent as Children } from "../../assets/Children.svg";
import { ReactComponent as ChildrenActive } from "../../assets/Children_w.svg";
import { ReactComponent as Guidedog } from "../../assets/Guidedog.svg";
import { ReactComponent as GuidedogActive } from "../../assets/Guidedog_w.svg";
import { ReactComponent as Luggage } from "../../assets/Luggage.svg";
import { ReactComponent as LuggageActive } from "../../assets/Luggage_w.svg";
import { ReactComponent as Plus } from "../../assets/plus_passive.svg";
import { ReactComponent as PlusActive } from "../../assets/plus_active.svg";
import { ReactComponent as Minus } from "../../assets/minus_passive.svg";
import { ReactComponent as MinusActive } from "../../assets/minus_active.svg";
import { ReactComponent as ComboCCS } from "../../assets/combo_unselected.svg";
import { ReactComponent as ComboCCSActive } from "../../assets/combo_selected.svg";
import { ReactComponent as Chademo } from "../../assets/chademo_unselected.svg";
import { ReactComponent as ChademoActive } from "../../assets/chademo_selected.svg";
import { ReactComponent as RapidAC43 } from "../../assets/ac43_unselected.svg";
import { ReactComponent as RapidAC43Active } from "../../assets/ac43_selected.svg";
import infoImg from "../../assets/information.svg";
import useCollapse from "react-collapsed";
import { ThemeContext } from "../../../Context/ThemeContext";
import styled from "styled-components";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";
import loadable from "@loadable/component";

import userLocalDataService from "./../../../services/data/userLocalDataService";
import { defaultFilters } from "../../../domain/constants/filters";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const FontAwesomeIcon = loadable(
  () => import("@fortawesome/react-fontawesome"),
  {
    resolveComponent: (components) => components.FontAwesomeIcon,
  }
);

library.add(fas, far, fad);
function Collapse(props) {
  const collapseProps = useCollapse(props);
  return props.children(collapseProps);
}
class EditProfile extends Component {
  constructor(props) {
    super(props);
    const apimUser = userLocalDataService.getAPIMUser();
    this.state = {
      usr: apimUser.firstName,
      ...defaultFilters,
    };
  }
  static contextType = ThemeContext;

  componentDidMount = () => {
    //Verify is request is targeted directly at viewing "Edit Profile"
    this.handleShowEditProfile();
  };

  handleSaveUpdates = () => {
    var filters;
    filters = {
      filterAdults: this.state.filterAdults,
      filterChildren: this.state.filterChildren,
      filterWheelchair: this.state.filterWheelchair,
      filterLuggage: this.state.filterLuggage,
      filterGuideDog: this.state.filterGuideDog,
      filterBicycle: this.state.filterBicycle,
      filterRemainingRange: this.state.filterRemainingRange,
      filterMaximumRange: this.state.filterMaximumRange,
      filterChargingTime: this.state.filterChargingTime,
      filterChademo: this.state.filterChademo,
      filterComboCCS: this.state.filterComboCCS,
      filterRapidAC43: this.state.filterRapidAC43,
    };
    this.props.saveProfileFilters(filters);
    this.props.toggleEditProfile();
    this.props.close();
  };

  handleShowEditProfile = () => {
    this.setState({
      filterAdults: this.props.profilefilters.filterAdults,
      filterChildren: this.props.profilefilters.filterChildren,
      filterWheelchair: this.props.profilefilters.filterWheelchair,
      filterLuggage: this.props.profilefilters.filterLuggage,
      filterGuideDog: this.props.profilefilters.filterGuideDog,
      filterBicycle: this.props.profilefilters.filterBicycle,
      filterRemainingRange: this.props.profilefilters.filterRemainingRange,
      filterMaximumRange: this.props.profilefilters.filterMaximumRange,
      filterChargingTime: this.props.profilefilters.filterChargingTime,
      filterChademo: this.props.profilefilters.filterChademo,
      filterComboCCS: this.props.profilefilters.filterComboCCS,
      filterRapidAC43: this.props.profilefilters.filterRapidAC43,
    });

    if (this.props.assistanceSettings.contacts[0].email !== null) {
      this.setState({
        filterAccess: true,
      });
    } else {
      this.setState({
        filterAccess: false,
      });
    }
  };

  handleAccessFilter = () => {
    this.handleNominatedAssistance(true);
  };

  handleWheelchairFilter = () => {
    const currentFilter = this.state.filterWheelchair;
    this.setState({ filterWheelchair: !currentFilter });
  };

  handleNominatedAssistance = (nominatePage) => {
    this.props.nominatedAssistancePage(nominatePage);
  };

  handleLuggageFilter = () => {
    const currentFilter = this.state.filterLuggage;
    this.setState({ filterLuggage: !currentFilter });
  };

  handleChademoFilter = () => {
    const currentFilter = this.state.filterChademo;
    this.setState({ filterChademo: !currentFilter });
  };

  handleComboCCSFilter = () => {
    const currentFilter = this.state.filterComboCCS;
    this.setState({ filterComboCCS: !currentFilter });
  };

  handleRapidAC43Filter = () => {
    const currentFilter = this.state.filterRapidAC43;
    this.setState({ filterRapidAC43: !currentFilter });
  };

  handleGuideDogFilter = () => {
    const currentFilter = this.state.filterGuideDog;
    this.setState({ filterGuideDog: !currentFilter });
  };

  handleBicycleFilter = () => {
    const currentFilter = this.state.filterBicycle;
    this.setState({ filterBicycle: !currentFilter });
  };

  handleFilterQuantity = (stateField, operator) => {
    if (stateField === "filterChildren") {
      if (operator === "minus" && this.state.filterChildren > 0) {
        this.setState((prevState) => {
          return { filterChildren: prevState.filterChildren - 1 };
        });
      } else if (operator === "plus" && this.state.filterChildren < 100) {
        this.setState((prevState) => {
          return { filterChildren: prevState.filterChildren + 1 };
        });
      }
    } else if (stateField === "filterAdult") {
      if (operator === "minus" && this.state.filterAdults > 0) {
        this.setState((prevState) => {
          return { filterAdults: prevState.filterAdults - 1 };
        });
      } else if (operator === "plus" && this.state.filterAdults < 100) {
        this.setState((prevState) => {
          return { filterAdults: prevState.filterAdults + 1 };
        });
      }
    } else if (stateField === "filterRemainingRange") {
      if (operator === "minus" && this.state.filterRemainingRange > 0) {
        this.setState((prevState) => {
          return { filterRemainingRange: prevState.filterRemainingRange - 5 };
        });
      } else if (
        operator === "plus" &&
        this.state.filterRemainingRange < 10000
      ) {
        this.setState((prevState) => {
          return { filterRemainingRange: prevState.filterRemainingRange + 5 };
        });
      }
    } else if (stateField === "filterMaximumRange") {
      if (operator === "minus" && this.state.filterMaximumRange > 0) {
        this.setState((prevState) => {
          return { filterMaximumRange: prevState.filterMaximumRange - 5 };
        });
      } else if (operator === "plus" && this.state.filterMaximumRange < 10000) {
        this.setState((prevState) => {
          return { filterMaximumRange: prevState.filterMaximumRange + 5 };
        });
      }
    } else if (stateField === "filterChargingTime") {
      if (operator === "minus" && this.state.filterChargingTime > 0) {
        this.setState((prevState) => {
          return { filterChargingTime: prevState.filterChargingTime - 5 };
        });
      } else if (operator === "plus" && this.state.filterChargingTime < 10000) {
        this.setState((prevState) => {
          return { filterChargingTime: prevState.filterChargingTime + 5 };
        });
      }
    }
  };

  render() {
    const { usr } = this.state;

    const { theme } = this.context;

    const StyledMinusActive = styled(MinusActive)`
      circle,
      path {
        fill: ${theme.primaryColour} !important;
      }
    `;

    const StyledPlusActive = styled(PlusActive)`
      circle,
      path {
        fill: ${theme.primaryColour} !important;
      }
    `;

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <>
            {this.props.editProfile ? (
              <div>
                <div
                  id="edit_header"
                  className={styles["edit-profile-text-span"]}
                >
                  <span>
                    Hi {usr}, the following profile settings will be applied
                    when you plan a trip.
                  </span>
                  <div>
                    <img
                      src={infoImg}
                      className={styles["icon-information"]}
                      alt="info"
                    />
                    <span>
                      You can change these settings when editing the travel
                      details for a specific trip.
                    </span>
                  </div>
                </div>
                {!theme?.hideClientElements?.includes("hideWhosTravelling") ? (
                  <>
                    <div className={styles["modal-subtitle"]}>
                      <span>
                        <b>Who is usually travelling ?</b>
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className={styles["numeric-buttons-container"]}>
                  {!theme?.hideClientElements?.includes(
                    "hideWhosTravelling"
                  ) ? (
                    <>
                      <div
                        style={{
                          background:
                            this.state.filterAdults > 0
                              ? theme.theme.primaryColour
                              : "white",
                        }}
                        className={`${styles["numeric-button-container"]} ${
                          this.state.filterAdults > 0
                            ? styles["active"]
                            : styles["inactive"]
                        }`}
                      >
                        {this.state.filterAdults > 0 ? (
                          <div
                            className={
                              styles["numeric-button-container-icon-box"]
                            }
                          >
                            <div
                              className={
                                styles["numeric-button-sign-container"]
                              }
                              onClick={() =>
                                this.handleFilterQuantity(
                                  "filterAdult",
                                  "minus"
                                )
                              }
                              onKeyPress={() =>
                                this.handleFilterQuantity(
                                  "filterAdult",
                                  "minus"
                                )
                              }
                              tabIndex="0"
                              title="Reduce the number of adults travelling by one"
                            >
                              <StyledMinusActive
                                className={
                                  styles["numeric-button-container-minus"]
                                }
                              />
                            </div>

                            <AdultsActive
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                            <div
                              className={
                                styles["numeric-button-sign-container"]
                              }
                              onClick={() =>
                                this.handleFilterQuantity("filterAdult", "plus")
                              }
                              onKeyPress={() =>
                                this.handleFilterQuantity("filterAdult", "plus")
                              }
                              tabIndex="0"
                              title="Increase the number of adults travelling by one"
                            >
                              <StyledPlusActive
                                className={
                                  styles["numeric-button-container-plus"]
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              styles["numeric-button-container-icon-box"]
                            }
                          >
                            <div
                              className={
                                styles["numeric-button-sign-container"]
                              }
                              onClick={() =>
                                this.handleFilterQuantity(
                                  "filterAdult",
                                  "minus"
                                )
                              }
                              onKeyPress={() =>
                                this.handleFilterQuantity(
                                  "filterAdult",
                                  "minus"
                                )
                              }
                              tabIndex="0"
                              title="Reduce the number of adults travelling by one"
                            >
                              <Minus
                                style={{
                                  backgroundColor: "#404040",
                                  borderRadius: "50%",
                                }}
                                className={
                                  styles["numeric-button-container-minus"]
                                }
                              />
                            </div>
                            <Adults
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                            <div
                              className={
                                styles["numeric-button-sign-container"]
                              }
                              onClick={() =>
                                this.handleFilterQuantity("filterAdult", "plus")
                              }
                              onKeyPress={() =>
                                this.handleFilterQuantity("filterAdult", "plus")
                              }
                              tabIndex="0"
                              title="Increase the number of adults travelling by one"
                            >
                              <Plus
                                style={{
                                  backgroundColor: "#404040",
                                  borderRadius: "50%",
                                }}
                                className={
                                  styles["numeric-button-container-plus"]
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className={styles["numeric-button-text-box"]}
                          tabIndex="0"
                        >
                          {this.state.filterAdults !== 1 ? (
                            <span>{this.state.filterAdults} Adults </span>
                          ) : (
                            <span>{this.state.filterAdults} Adult </span>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          background:
                            this.state.filterChildren > 0
                              ? theme.theme.primaryColour
                              : "white",
                        }}
                        className={`${
                          styles["numeric-button-container-right"]
                        } ${
                          this.state.filterChildren > 0
                            ? styles["active"]
                            : styles["inactive"]
                        }`}
                      >
                        {this.state.filterChildren > 0 ? (
                          <div
                            className={
                              styles["numeric-button-container-icon-box"]
                            }
                          >
                            <div
                              className={
                                styles["numeric-button-sign-container"]
                              }
                              onClick={() =>
                                this.handleFilterQuantity(
                                  "filterChildren",
                                  "minus"
                                )
                              }
                              onKeyPress={() =>
                                this.handleFilterQuantity(
                                  "filterChildren",
                                  "minus"
                                )
                              }
                              tabIndex="0"
                              title="Reduce the number of children travelling by one"
                            >
                              <StyledMinusActive
                                className={
                                  styles["numeric-button-container-minus"]
                                }
                              />
                            </div>
                            <ChildrenActive
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                            <div
                              className={
                                styles["numeric-button-sign-container"]
                              }
                              onClick={() =>
                                this.handleFilterQuantity(
                                  "filterChildren",
                                  "plus"
                                )
                              }
                              onKeyPress={() =>
                                this.handleFilterQuantity(
                                  "filterChildren",
                                  "plus"
                                )
                              }
                              tabIndex="0"
                              title="Increase the number of children travelling by one"
                            >
                              <StyledPlusActive
                                className={
                                  styles["numeric-button-container-plus"]
                                }
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={
                              styles["numeric-button-container-icon-box"]
                            }
                          >
                            <div
                              className={
                                styles["numeric-button-sign-container"]
                              }
                              onClick={() =>
                                this.handleFilterQuantity(
                                  "filterChildren",
                                  "minus"
                                )
                              }
                              onKeyPress={() =>
                                this.handleFilterQuantity(
                                  "filterChildren",
                                  "minus"
                                )
                              }
                              tabIndex="0"
                              title="Reduce the number of children travelling by one"
                            >
                              <Minus
                                style={{
                                  backgroundColor: "#404040",
                                  borderRadius: "50%",
                                }}
                                className={
                                  styles["numeric-button-container-minus"]
                                }
                              />
                            </div>
                            <Children
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                            <div
                              className={
                                styles["numeric-button-sign-container"]
                              }
                              onClick={() =>
                                this.handleFilterQuantity(
                                  "filterChildren",
                                  "plus"
                                )
                              }
                              onKeyPress={() =>
                                this.handleFilterQuantity(
                                  "filterChildren",
                                  "plus"
                                )
                              }
                              tabIndex="0"
                              title="Increase the number of children travelling by one"
                            >
                              <Plus
                                style={{
                                  backgroundColor: "#404040",
                                  borderRadius: "50%",
                                }}
                                className={
                                  styles["numeric-button-container-plus"]
                                }
                              />
                            </div>
                          </div>
                        )}
                        <div
                          className={styles["numeric-button-text-box"]}
                          tabIndex="0"
                        >
                          {this.state.filterChildren !== 1 ? (
                            <span>{this.state.filterChildren} Children </span>
                          ) : (
                            <span>{this.state.filterChildren} Child </span>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    " "
                  )}
                  <Collapse defaultOpen>
                    {({ getToggleProps, getCollapseProps, isExpanded }) => (
                      <>
                        <div className={styles["title-container"]}>
                          <div
                            {...getToggleProps()}
                            className={styles["title-custom-cursor"]}
                            tabIndex={0}
                          >
                            <span>
                              <FontAwesomeIcon
                                icon={isExpanded ? "arrow-down" : "arrow-right"}
                              />
                              <b className={styles["modal-subtitle-icon"]}>
                                Electric Vehicle Filters
                              </b>
                            </span>
                          </div>
                        </div>
                        <div {...getCollapseProps()}>
                          <div
                            style={{
                              background:
                                this.state.filterRemainingRange > 0
                                  ? theme.theme.primaryColour
                                  : "white",
                            }}
                            className={`${styles["numeric-button-container"]} ${
                              this.state.filterRemainingRange > 0
                                ? styles["active"]
                                : styles["inactive"]
                            }`}
                          >
                            <div
                              className={styles["numeric-button-text-header"]}
                              tabIndex="0"
                            >
                              {this.state.filterRemainingRange > 1 ? (
                                <span>Remaining Range</span>
                              ) : (
                                <span> Remaining Range</span>
                              )}
                            </div>
                            {this.state.filterRemainingRange >= 5 ? (
                              <div
                                className={
                                  styles["numeric-button-container-icon-box"]
                                }
                              >
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterRemainingRange",
                                      "minus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterRemainingRange",
                                      "minus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <StyledMinusActive
                                    className={
                                      styles["numeric-button-container-minus"]
                                    }
                                  />
                                </div>

                                <span
                                  className={
                                    styles[
                                      "numeric-button-container-middle-text"
                                    ]
                                  }
                                >
                                  {this.state.filterRemainingRange}{" "}
                                </span>
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterRemainingRange",
                                      "plus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterRemainingRange",
                                      "plus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <StyledPlusActive
                                    className={
                                      styles["numeric-button-container-plus"]
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={
                                  styles["numeric-button-container-icon-box"]
                                }
                              >
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterRemainingRange",
                                      "minus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterRemainingRange",
                                      "minus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <Minus
                                    style={{
                                      backgroundColor: "#404040",
                                      borderRadius: "50%",
                                    }}
                                    className={
                                      styles["numeric-button-container-minus"]
                                    }
                                  />
                                </div>
                                <span
                                  className={
                                    styles[
                                      "numeric-button-container-middle-text"
                                    ]
                                  }
                                >
                                  {this.state.filterRemainingRange}{" "}
                                </span>

                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterRemainingRange",
                                      "plus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterRemainingRange",
                                      "plus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <Plus
                                    style={{
                                      backgroundColor: "#404040",
                                      borderRadius: "50%",
                                    }}
                                    className={
                                      styles["numeric-button-container-plus"]
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div
                              className={styles["numeric-button-text-box"]}
                              tabIndex="0"
                            >
                              <span>
                                {theme.isKilometres ? "Kilometres" : "Miles"}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              background:
                                this.state.filterMaximumRange > 0
                                  ? theme.theme.primaryColour
                                  : "white",
                            }}
                            className={`${
                              styles["numeric-button-container-right"]
                            } ${
                              this.state.filterMaximumRange > 0
                                ? styles["active"]
                                : styles["inactive"]
                            }`}
                          >
                            <div
                              className={styles["numeric-button-text-header"]}
                              tabIndex="0"
                            >
                              {this.state.filterMaximumRange > 1 ? (
                                <span>Maximum Range</span>
                              ) : (
                                <span> Maximum Range</span>
                              )}
                            </div>
                            {this.state.filterMaximumRange >= 5 ? (
                              <div
                                className={
                                  styles["numeric-button-container-icon-box"]
                                }
                              >
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterMaximumRange",
                                      "minus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterMaximumRange",
                                      "minus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <StyledMinusActive
                                    className={
                                      styles["numeric-button-container-minus"]
                                    }
                                  />
                                </div>
                                <span
                                  className={
                                    styles[
                                      "numeric-button-container-middle-text"
                                    ]
                                  }
                                >
                                  {this.state.filterMaximumRange}{" "}
                                </span>
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterMaximumRange",
                                      "plus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterMaximumRange",
                                      "plus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <StyledPlusActive
                                    className={
                                      styles["numeric-button-container-plus"]
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={
                                  styles["numeric-button-container-middle-text"]
                                }
                              >
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterMaximumRange",
                                      "minus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterMaximumRange",
                                      "minus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <Minus
                                    style={{
                                      backgroundColor: "#404040",
                                      borderRadius: "50%",
                                    }}
                                    className={
                                      styles["numeric-button-container-minus"]
                                    }
                                  />
                                </div>
                                <span
                                  className={
                                    styles[
                                      "numeric-button-container-middle-text"
                                    ]
                                  }
                                >
                                  {this.state.filterMaximumRange}{" "}
                                </span>
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterMaximumRange",
                                      "plus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterMaximumRange",
                                      "plus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <Plus
                                    style={{
                                      backgroundColor: "#404040",
                                      borderRadius: "50%",
                                    }}
                                    className={
                                      styles["numeric-button-container-plus"]
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div
                              className={styles["numeric-button-text-box"]}
                              tabIndex="0"
                            >
                              <span>
                                {theme.isKilometres ? "Kilometres" : "Miles"}
                              </span>
                            </div>
                          </div>
                          <div
                            style={{
                              background:
                                this.state.filterChargingTime > 0
                                  ? theme.theme.primaryColour
                                  : "white",
                            }}
                            className={`${styles["numeric-button-container"]} ${
                              this.state.filterChargingTime > 0
                                ? styles["active"]
                                : styles["inactive"]
                            }`}
                          >
                            <div
                              className={styles["numeric-button-text-header"]}
                              tabIndex="0"
                            >
                              <span> Charging Time</span>
                            </div>
                            {this.state.filterChargingTime >= 5 ? (
                              <div
                                className={
                                  styles["numeric-button-container-icon-box"]
                                }
                              >
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterChargingTime",
                                      "minus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterChargingTime",
                                      "minus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <StyledMinusActive
                                    className={
                                      styles["numeric-button-container-minus"]
                                    }
                                  />
                                </div>

                                <span
                                  className={
                                    styles[
                                      "numeric-button-container-middle-text"
                                    ]
                                  }
                                >
                                  {this.state.filterChargingTime}{" "}
                                </span>

                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterChargingTime",
                                      "plus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterChargingTime",
                                      "plus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <StyledPlusActive
                                    className={
                                      styles["numeric-button-container-plus"]
                                    }
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={
                                  styles["numeric-button-container-icon-box"]
                                }
                              >
                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterChargingTime",
                                      "minus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterChargingTime",
                                      "minus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <Minus
                                    style={{
                                      backgroundColor: "#404040",
                                      borderRadius: "50%",
                                    }}
                                    className={
                                      styles["numeric-button-container-minus"]
                                    }
                                  />
                                </div>
                                <span
                                  className={
                                    styles[
                                      "numeric-button-container-middle-text"
                                    ]
                                  }
                                >
                                  {this.state.filterChargingTime}{" "}
                                </span>

                                <div
                                  className={
                                    styles["numeric-button-sign-container"]
                                  }
                                  onClick={() =>
                                    this.handleFilterQuantity(
                                      "filterChargingTime",
                                      "plus"
                                    )
                                  }
                                  onKeyPress={() =>
                                    this.handleFilterQuantity(
                                      "filterChargingTime",
                                      "plus"
                                    )
                                  }
                                  tabIndex="0"
                                >
                                  <Plus
                                    style={{
                                      backgroundColor: "#404040",
                                      borderRadius: "50%",
                                    }}
                                    className={
                                      styles["numeric-button-container-plus"]
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            <div
                              className={styles["numeric-button-text-box"]}
                              tabIndex="0"
                            >
                              <span>Minutes</span>
                            </div>
                          </div>

                          <div
                            onClick={this.handleChademoFilter}
                            onKeyPress={this.handleChademoFilter}
                            style={{
                              background: this.state.filterChademo
                                ? theme.theme.primaryColour
                                : "white",
                            }}
                            className={`${
                              styles["numeric-button-container-right"]
                            } ${
                              this.state.filterChademo
                                ? styles["active"]
                                : styles["inactive"]
                            }`}
                            tabIndex="0"
                          >
                            {this.state.filterChademo > 0 ? (
                              <div
                                className={styles["button-container-icon-box"]}
                              >
                                <ChademoActive
                                  className={
                                    styles["numeric-button-container-icon"]
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={styles["button-container-icon-box"]}
                              >
                                <Chademo
                                  className={
                                    styles["numeric-button-container-icon"]
                                  }
                                />
                              </div>
                            )}
                            <div className={styles["numeric-button-text-box"]}>
                              <span className={styles["numeric-button-text"]}>
                                My vehicle can use CHAdeMO
                              </span>
                            </div>
                          </div>
                          <div
                            onClick={this.handleComboCCSFilter}
                            onKeyPress={this.handleComboCCSFilter}
                            style={{
                              background: this.state.filterComboCCS
                                ? theme.theme.primaryColour
                                : "white",
                            }}
                            className={`${styles["numeric-button-container"]} ${
                              this.state.filterComboCCS
                                ? styles["active"]
                                : styles["inactive"]
                            }`}
                            tabIndex="0"
                          >
                            {this.state.filterComboCCS > 0 ? (
                              <div
                                className={styles["button-container-icon-box"]}
                              >
                                <ComboCCSActive
                                  className={
                                    styles["numeric-button-container-icon"]
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={styles["button-container-icon-box"]}
                              >
                                <ComboCCS
                                  className={
                                    styles["numeric-button-container-icon"]
                                  }
                                />
                              </div>
                            )}
                            <div className={styles["numeric-button-text-box"]}>
                              <span className={styles["numeric-button-text"]}>
                                My vehicle can use Combo CCS
                              </span>
                            </div>
                          </div>
                          <div
                            onClick={this.handleRapidAC43Filter}
                            onKeyPress={this.handleRapidAC43Filter}
                            style={{
                              background: this.state.filterRapidAC43
                                ? theme.theme.primaryColour
                                : "white",
                            }}
                            className={`${
                              styles["numeric-button-container-right"]
                            } ${
                              this.state.filterRapidAC43
                                ? styles["active"]
                                : styles["inactive"]
                            }`}
                            tabIndex="0"
                          >
                            {this.state.filterRapidAC43 > 0 ? (
                              <div
                                className={styles["button-container-icon-box"]}
                              >
                                <RapidAC43Active
                                  className={
                                    styles["numeric-button-container-icon"]
                                  }
                                />
                              </div>
                            ) : (
                              <div
                                className={styles["button-container-icon-box"]}
                              >
                                <RapidAC43
                                  className={
                                    styles["numeric-button-container-icon"]
                                  }
                                />
                              </div>
                            )}
                            <div className={styles["numeric-button-text-box"]}>
                              <span className={styles["numeric-button-text"]}>
                                My vehicle can use Rapid AC43
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </Collapse>
                  <>
                    <div>
                      <span>
                        <b className={styles["modal-subtitle2"]}>
                          Set your usual requirements
                        </b>
                      </span>
                    </div>
                    <div
                      onClick={this.handleAccessFilter}
                      onKeyPress={this.handleAccessFilter}
                      className={`${styles["numeric-button-container"]} ${
                        this.props.assistanceSettings.contacts[0].email !== null
                          ? styles["active"]
                          : styles["inactive"]
                      }`}
                      style={{
                        background:
                          this.props.assistanceSettings.contacts[0].email !==
                          null
                            ? theme.theme.primaryColour
                            : "white",
                      }}
                      tabIndex="0"
                    >
                      {this.state.filterAccess > 0 ? (
                        <div className={styles["Step-free-container-icon"]}>
                          <FontAwesomeIcon
                            icon={"user-friends"}
                            size="2x"
                            color={"white"}
                            className={styles["numeric-button-container-icon"]}
                          />
                        </div>
                      ) : (
                        <div className={styles["Step-free-container-icon"]}>
                          <FontAwesomeIcon
                            icon={"user-friends"}
                            size="2x"
                            className={styles["numeric-button-container-icon"]}
                          />
                        </div>
                      )}
                      <div className={styles["numeric-button-text-box"]}>
                        <span
                          className={
                            styles["numeric-step-free-button-text-box"]
                          }
                        >
                          Nominate assistance contact
                        </span>
                      </div>
                    </div>
                    {/* filterWheelchair */}
                    {!theme?.hideClientElements?.includes("hideStepFree") ? (
                      <div
                        onClick={this.handleWheelchairFilter}
                        onKeyPress={this.handleWheelchairFilter}
                        style={{
                          background:
                            this.state.filterWheelchair > 0
                              ? theme.theme.primaryColour
                              : "white",
                        }}
                        className={`${
                          styles["numeric-button-container-right"]
                        } ${
                          this.state.filterWheelchair
                            ? styles["active"]
                            : styles["inactive"]
                        }`}
                        tabIndex="0"
                      >
                        {this.state.filterWheelchair > 0 ? (
                          <div className={styles["button-container-icon-box"]}>
                            <AccessibilityActive
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                          </div>
                        ) : (
                          <div className={styles["button-container-icon-box"]}>
                            <Accessibility
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                          </div>
                        )}
                        <div className={styles["numeric-button-text-box"]}>
                          <span className={styles["numeric-button-text"]}>
                            I require step-free access
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* filterGuideDog */}
                    {!theme?.hideClientElements?.includes("hideGuideDog") ? (
                      <div
                        onClick={this.handleGuideDogFilter}
                        onKeyPress={this.handleGuideDogFilter}
                        style={{
                          background:
                            this.state.filterGuideDog > 0
                              ? theme.theme.primaryColour
                              : "white",
                        }}
                        className={`${styles["numeric-button-container"]} ${
                          this.state.filterGuideDog
                            ? styles["active"]
                            : styles["inactive"]
                        }`}
                        tabIndex="0"
                      >
                        {this.state.filterGuideDog > 0 ? (
                          <div className={styles["button-container-icon-box"]}>
                            <GuidedogActive
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                          </div>
                        ) : (
                          <div className={styles["button-container-icon-box"]}>
                            <Guidedog
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                          </div>
                        )}
                        <div className={styles["numeric-button-text-box"]}>
                          <span className={styles["numeric-button-text"]}>
                            I'll have an assistance
                          </span>
                          <span
                            className={styles["numeric-button-bottom-text"]}
                          >
                            dog with me
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* filterBicycle */}
                    {!theme?.hideClientElements?.includes("hideBikePark") ? (
                      <div
                        onClick={this.handleBicycleFilter}
                        onKeyPress={this.handleBicycleFilter}
                        style={{
                          background: this.state.filterBicycle
                            ? theme.theme.primaryColour
                            : "white",
                        }}
                        className={`${
                          styles["numeric-button-container-right"]
                        } ${
                          this.state.filterBicycle
                            ? styles["active"]
                            : styles["inactive"]
                        }`}
                        tabIndex="0"
                      >
                        {this.state.filterBicycle ? (
                          <div className={styles["button-container-icon-box"]}>
                            <BicycleActive
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                          </div>
                        ) : (
                          <div className={styles["button-container-icon-box"]}>
                            <Bicycle
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                          </div>
                        )}
                        <div className={styles["numeric-button-text-box"]}>
                          <span className={styles["numeric-button-text"]}>
                            I'll need a place to
                          </span>
                          <span
                            className={styles["numeric-button-bottom-text"]}
                          >
                            park my bicycle
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* filterLuggage */}
                    {!theme?.hideClientElements?.includes(
                      "hideHeavyLuggage"
                    ) ? (
                      <div
                        onClick={this.handleLuggageFilter}
                        onKeyPress={this.handleLuggageFilter}
                        style={{
                          background: this.state.filterLuggage
                            ? theme.theme.primaryColour
                            : "white",
                        }}
                        className={`${styles["numeric-button-container"]} ${
                          this.state.filterLuggage
                            ? styles["active"]
                            : styles["inactive"]
                        }`}
                        tabIndex="0"
                      >
                        {this.state.filterLuggage > 0 ? (
                          <div className={styles["button-container-icon-box"]}>
                            <LuggageActive
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                          </div>
                        ) : (
                          <div className={styles["button-container-icon-box"]}>
                            <Luggage
                              className={
                                styles["numeric-button-container-icon"]
                              }
                            />
                          </div>
                        )}
                        <div className={styles["numeric-button-text-box"]}>
                          <span
                            className={
                              styles["numeric-step-free-button-text-box"]
                            }
                          >
                            I'll have heavy luggage with me
                          </span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                </div>
                <div className={styles["copy-button-container"]}>
                  <div className={styles["edit-profile-button-container"]}>
                    <button
                      //type="button"
                      className={styles["update-profile-button"]}
                      onClick={() => this.handleSaveUpdates()}
                      onKeyPress={() => this.handleSaveUpdates()}
                    >
                      <span
                        style={{ color: theme.theme.primaryColour }}
                        className={styles["update-profile-button-span"]}
                      >
                        Update Profile
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles["copy-button-container"]}>
                <div className={styles["edit-profile-button-container"]}>
                  <button
                    type="button"
                    className={styles["edit-profile-button"]}
                    onClick={() => this.handleShowEditProfile()}
                    onKeyPress={() => this.handleShowEditProfile()}
                  >
                    <span
                      style={{ color: theme.theme.primaryColour }}
                      className={styles["edit-profile-button-span"]}
                    >
                      Edit Profile
                    </span>
                  </button>
                  {this.props.auth.isAuthenticated() === false ? (
                    this.props.thirdPartyAuthenticated === true ? (
                      ""
                    ) : (
                      <span className={styles["auth-required-text"]}>
                        Please log in to view planned and past trips
                      </span>
                    )
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles["filter-button-container"]}>
                  <button
                    type="button"
                    style={{
                      background: !this.state.filterPastTravelPlans
                        ? theme.theme.primaryColour
                        : "white",
                    }}
                    className={`${styles["filter-button"]} ${
                      !this.state.filterPastTravelPlans
                        ? styles["active"]
                        : styles["inactive"]
                    }`}
                    onClick={() => this.filterPastTravelPlans(false)}
                    onKeyPress={() => this.filterPastTravelPlans(false)}
                  >
                    <span className={styles["filter-button-span"]}>
                      Planned trips
                    </span>
                  </button>
                  <button
                    type="button"
                    className={`${styles["filter-button2"]} ${
                      this.state.filterPastTravelPlans
                        ? styles["active"]
                        : styles["inactive"]
                    }`}
                    onClick={() => this.filterPastTravelPlans(true)}
                    onKeyPress={() => this.filterPastTravelPlans(true)}
                  >
                    <span className={styles["filter-button-span2"]}>
                      Past trips
                    </span>
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default EditProfile;
