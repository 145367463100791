import React, { Component } from "react";
import styles from "./Sharing.module.scss";
import { isMobile } from "react-device-detect";
import facebookImg from "../../assets/facebook.png";
import twitterImg from "../../assets/twitter.png";
import whatsappImg from "../../assets/whatsapp.png";
import mailImg from "../../assets/mail.png";
import linkedImg from "../../assets/linkedin.png";
import QRCode from "react-qr-code";
import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../../Context/ThemeContext";

import { ReactComponent as Close } from "../../assets/round-close-24px.svg";
class Sharing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCopyLinkButtonHovered: false,
      isCopyEmbedButtonHovered: false,
      isAPIButtonHovered: false,
    };

    this.whatsapp_url = "https://wa.me/?text=Plan your trip to ";
  }

  close = () => {
    this.props.toggleSharepage(false);
  };

  finish = () => {
    this.props.finish();
  };

  copyEmbedCode = () => {
    const el = this.input;

    /* Get the text field */
    el.select();
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Copied the text: " + el.value);
  };

  copyLink = () => {
    /* Get the text field */
    const el = this.textArea;

    el.select();
    document.execCommand("copy");

    /* Alert the copied text */
    alert("Copied the text: " + el.value);
  };

  isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  render() {
    if (!isMobile) {
      this.whatsapp_url =
        "https://web.whatsapp.com/send?text=Plan your trip to ";
    }
    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <div className={styles["Modal"]}>
            <div
              className={styles["content"]}
              role="dialog"
            >
              <div className={styles["header"]}>
                <div className={styles["button-container"]}>
                  <button
                    type="button"
                    className={styles["button"]}
                    onClick={this.close}
                  >
                    <Close className={styles["Close"]} />
                  </button>
                </div>
                <div className={styles["title-container"]}>
                  <div className={styles["title"]}>
                    <span>
                      <FormattedMessage id="share" />
                    </span>
                  </div>
                </div>
                <div className={styles["action-container"]}>
                  <div className={styles["action"]} />
                </div>
              </div>
              <div className={styles["main"]}>
                <div className={styles["share-buttons"]}>
                  <a
                    title="send to Facebook"
                    href={
                      "http://www.facebook.com/sharer.php?s=100&p= Plan your trip to " +
                      this.props.eventName +
                      "&u=" +
                      window.location.origin +
                      this.props.initialUrlExtension
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles["share-button"]}
                      border="0"
                      src={facebookImg}
                      alt=""
                    />
                  </a>
                  <div className={styles["icon-seperator"]} />
                  <a
                    href={
                      "https://twitter.com/intent/tweet?text=" +
                      " Plan your trip to " +
                      this.props.eventName +
                      " " +
                      window.location.origin +
                      this.props.initialUrlExtension
                    }
                    data-size="large"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles["share-button"]}
                      border="0"
                      src={twitterImg}
                      alt=""
                    />
                  </a>
                  <div className={styles["icon-seperator"]} />
                  <a
                    href={
                      //this.isMobileDevice(),
                      //? "whatsapp://send?text=Plan your trip to " +
                      // "https://wa.me/?text=Plan your trip to "+
                      //"https://web.whatsapp.com/send?text=Plan your trip to "+
                      this.whatsapp_url +
                      this.props.eventName +
                      " " +
                      window.location.origin +
                      this.props.initialUrlExtension
                      // : ""
                    }
                    //data-action="share/whatsapp/share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles["share-button"]}
                      border="0"
                      src={whatsappImg}
                      alt=""
                    />
                  </a>
                  <div className={styles["icon-seperator"]} />
                  <a
                    id="emailMe"
                    href={
                      "mailto:?subject=Plan your trip to " +
                      this.props.eventName +
                      " &body=" +
                      window.location.origin +
                      this.props.initialUrlExtension
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles["share-button"]}
                      border="0"
                      src={mailImg}
                      alt=""
                    />
                  </a>
                  <div className={styles["icon-seperator"]} />
                  <a
                    href={
                      "https://www.linkedin.com/shareArticle?mini=true&url=" +
                      window.location.origin +
                      this.props.initialUrlExtension +
                      "&title=" +
                      " Plan your trip to " +
                      this.props.eventName +
                      " " +
                      "&summary=Personalised travel by You. Smart. Thing."
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles["share-button"]}
                      border="0"
                      src={linkedImg}
                      alt=""
                    />
                  </a>
                </div>
                <div className={styles["input-container"]}>
                  <input
                    className={`${styles["non-editable-textarea"]} ${styles["short"]}`}
                    type="text"
                    value={
                      window.location.origin + this.props.initialUrlExtension
                    }
                    ref={(textarea) => (this.textArea = textarea)}
                    readOnly
                  />
                </div>
                <div className={styles["copy-button-container"]}>
                  <button
                    type="button"
                    className={styles["confirm-button"]}
                    onClick={this.copyLink}
                    style={{
                      background: !this.state.isCopyLinkButtonHovered
                        ? theme.theme.primaryColour
                        : theme.theme.hoverColour,
                    }}
                    onMouseEnter={() => {
                      this.setState({ isCopyLinkButtonHovered: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ isCopyLinkButtonHovered: false });
                    }}
                  >
                    <span className={styles["confirm-button-span"]}>
                      <FormattedMessage id="copyLink" />
                    </span>
                  </button>
                </div>
                {window.location.href.includes("travelplanid") ? (
                  <>
                    <div className={styles["embed-container"]}>
                      <span className={styles["embed-text"]}>
                        <FormattedMessage id="scanQR" />
                      </span>
                    </div>
                    <QRCode
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={
                        window.location.origin + this.props.initialUrlExtension
                      }
                    />
                  </>
                ) : this.props.journeyPlanID && this.props.journeyOptionId ? (
                  <>
                    <div className={styles["embed-container"]}>
                      <span className={styles["embed-text"]}>
                        <FormattedMessage id="scanQR" />
                      </span>
                    </div>
                    <QRCode
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={
                        window.location.protocol +
                        "//" +
                        window.location.host +
                        `/` +
                        this.props.destinationGroup +
                        `?journeyplanid=` +
                        this.props.journeyPlanID +
                        `&journeyoptionid=` +
                        this.props.journeyOptionId
                      }
                    />
                  </>
                ) : (
                  <>
                    <div className={styles["embed-container"]}>
                      <span className={styles["embed-text"]}>
                        <FormattedMessage id="scanQR" />
                      </span>
                    </div>
                    <QRCode
                      style={{
                        height: "auto",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={
                        window.location.origin + this.props.initialUrlExtension
                      }
                    />
                  </>
                )}
                <div className={styles["embed-container"]}>
                  <span className={styles["embed-text"]}>
                    <FormattedMessage
                      id="embedIframe"
                      values={{
                        eventName: this.props.eventName,
                      }}
                    />
                  </span>
                </div>
                <div className={styles["input-container"]}>
                  <textarea
                    className={`${styles["non-editable-textarea"]} ${styles["long"]}`}
                    type="text"
                    //value={window.location.href}
                    value={
                      '<iframe src="' +
                      window.location.origin +
                      this.props.initialUrlExtension +
                      '" width="100%" height="680" frameborder="0" allow="geolocation" scrolling="no" title="Map and directions for ' +
                      this.props.eventName +
                      '">Your browser does not support iframes.</iframe>'
                    }
                    ref={(input) => (this.input = input)}
                    readOnly
                  />
                </div>
                <div className={styles["copy-button-container"]}>
                  <button
                    type="button"
                    className={styles["confirm-button"]}
                    onClick={this.copyEmbedCode}
                    style={{
                      background: !this.state.isCopyEmbedButtonHovered
                        ? theme.theme.primaryColour
                        : theme.theme.hoverColour,
                    }}
                    onMouseEnter={() => {
                      this.setState({ isCopyEmbedButtonHovered: true });
                    }}
                    onMouseLeave={() => {
                      this.setState({ isCopyEmbedButtonHovered: false });
                    }}
                  >
                    <span className={styles["confirm-button-span"]}>
                      <FormattedMessage id="copyEmbed" />
                    </span>
                  </button>
                </div>
                <div className={styles["embed-container"]}>
                  <span className={styles["embed-text"]}>
                    {" "}
                    <FormattedMessage id="softwareDeveloper" />
                  </span>
                </div>
                <div className={styles["copy-button-container"]}>
                  <a
                    id="emailMe"
                    href={
                      "mailto:?subject=Apply for API access " +
                      "&body=Dear You. Smart. Thing. Team, Please could you contact me regarding API access for your Travel Assistant.&to=hello@yousmartthing.com"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles["button-link"]}
                  >
                    <button
                      type="button"
                      className={styles["api-button"]}
                      style={{
                        // TODO: Potentially adjust these colours?
                        background: !this.state.isAPIButtonHovered
                          ? "#e0e0e0"
                          : "darkgrey",
                      }}
                      onMouseEnter={() => {
                        this.setState({ isAPIButtonHovered: true });
                      }}
                      onMouseLeave={() => {
                        this.setState({ isAPIButtonHovered: false });
                      }}
                    >
                      <span className={styles["api-button-span"]}>
                        <FormattedMessage id="applyForAPI" />
                      </span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default Sharing;
