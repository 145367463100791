import React from "react";
import {
  Walk,
  Ferry,
  Rail,
  Bus,
  Tram,
  Underground,
  ChevronRight,
  Drive,
  Park,
  Cycle,
  CycleDock,
  Destination,
  Plane,
  EvStation,
  Alert,
  CarbonInfo,
  LocationOn,
} from "../../../utils/index";
import styles from "./VehicleDisplay.module.scss";

const VehicleDisplay = ({ vehicleType }) => {
  let Component;
  if (vehicleType === "Tram") {
    Component = <Tram className={styles["Tram"]} />;
  } else if (vehicleType === "Rail") {
    Component = <Rail className={styles["Rail"]} />;
  } else if (vehicleType === "Bus") {
    Component = <Bus className={styles["Bus"]} />;
  } else if (vehicleType === "Taxi") {
    Component = <Drive className={styles["Drive"]} />;
  } else if (vehicleType === "Ferry") {
    Component = <Ferry className={styles["Ferry"]} />;
  } else if (vehicleType === "Underground") {
    Component = <Underground className={styles["Underground"]} />;
  } else {
    Component = <LocationOn className={styles["LocationOn"]} />;
  }

  return <div>{Component}</div>;
};

export default VehicleDisplay;
