import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import _ from "lodash";
import { withRouter } from "react-router";
import styles from "./Event.module.scss";
import mapLockImg from "../../components/assets/map-locked.svg";
import mapUnlockImg from "../../components/assets/map-unlocked.svg";
import settingsInactive from "../../components/assets/map-settings-inactive.svg";
import settingsActive from "../../components/assets/map-settings-active.svg";
import mapClose from "../../components/assets/map-close.svg";
import mapExpand from "../../components/assets/map-expand.svg";
import WebMercatorViewport from "@math.gl/web-mercator";
import "mapbox-gl/dist/mapbox-gl.css";
import AuthenticationService from "../../services/authenticationService";
import cookieService from "../../services/cookieService";
import authProviderConfig from "../../config/authProviderConfig";
import mapService from "../../services/mapService";
import geolocationService from "../../services/geolocationService";
import { ThemeContext } from "../../Context/ThemeContext";
import safeLocalStorageService from "../../services/useSafeLocalStorage";

import {
  MAPBOX_TOKEN,
  MAPTILER_PATH_WITH_TOKEN,
  MOBILE_JOURNEY_MAP_HEIGHT,
  MOBILE_VIEW_THRESHOLD,
  DESKTOP_JOURNEY_MAP_WIDTH_GAP,
  MOBILE_MAP_HEIGHT_GAP,
} from "./../../domain/constants";
import {
  destinationError,
  travelPlanError,
  journeyPlanError,
  mercatorError,
} from "./../../domain/constants/error";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import userLocalDataService from "./../../services/data/userLocalDataService";
import authLocalDataService from "./../../services/data/authLocalDataService";
import DisruptionsModal from "../../components/common/DisruptionsModal";
import { Marker, Source, Layer, Popup } from "@urbica/react-map-gl";
import EditJourney from "../../components/EditJourney/";
import Footer from "../../components/common/Footer";
import Cluster from "@urbica/react-map-gl-cluster";
import JourneyCollection from "../../components/JourneyCollection";
import JourneyLoadingMessages from "../../components/JourneyLoadingMessages";
import Loading from "../../components/common/Loading";

import ReactMapGL from "@urbica/react-map-gl";
import ClusterTrafficMarker from "../../components/MapMarkers/ClusterTrafficMarker";
import CurrentLocation from "../../components/common/CurrentLocation";
import Walk from "../../components/assets/round-directions_walk.png";
import Ferry from "../../components/assets/round-directions_boat.png";
import Rail from "../../components/assets/round-directions_railway.png";
import Bus from "../../components/assets/round-directions_bus.png";
import Tram from "../../components/assets/round-tram.png";
import Underground from "../../components/assets/round-directions_subway.png";
import Drive from "../../components/assets/round-directions_car.png";
import Park from "../../components/assets/round-local_parking.png";
import EvStation from "../../components/assets/round-ev_station.png";
import Cycle from "../../components/assets/round-local_cycle.png";
import CycleDock from "../../components/assets/cycle_hire_map_icon.svg";
import Flight from "../../components/assets/round-flight-24px.svg";
import { ReactComponent as LocationOn } from "../../components/assets/round-location_on-24px.svg";
import ErrorBoundary from "../../components/common/Error/ErrorBoundary";
import InitialLoadingMessages from "../../components/InitialLoadingMessages";
import RedirectionPopup from "../../components/common/RedirectionPopup";
import InfoPopup from "../../components/common/InfoPopup";
import RequestAssistance from "../../components/common/RequestAssistance";
import AddNotification from "../../components/AddNotification";
import Confirmation from "../../components/common/Confirmation";
import Profile from "../../components/common/Profile";
import ClusteringTrafficLocationOn from "../../components/common/ClusteringTrafficLocationOn";
import Error from "../../components/common/Error/Error";
import Sharing from "../../components/common/Sharing";
import Menu from "../../components/common/Menu";
import MapMarkerPopup from "../../components/MapMarkers/MapMarkerPopup";
import MapMarkerTrafficPopup from "../../components/MapMarkers/MapMarkerTrafficPopup";
import CrossSiteWarning from "../../components/common/CrossSiteWarning";
import { defaultFilters } from "../../domain/constants/filters";
import MarkerGroups from "../../components/MarkerGroups";

library.add(fas);

const authenticationService = new AuthenticationService(authProviderConfig);

// Lazy loaded components
// const ReactMapGL = loadable( () => import( "@urbica/react-map-gl" ) );

// const FontAwesomeIcon = loadable(() => import("@fortawesome/react-fontawesome"), {
//   resolveComponent: (components) => components.FontAwesomeIcon,
// });

class Event extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPosition: { latitude: 0, longitude: 0, timestamp: 0 },
      lastScrollTop: 0,
      searched: false,
      showResults: false,
      showLoading: false,
      showInitialLoading: false,
      showLoadingJourneyMessages: false,
      showForm: false,
      showVia1: false,
      showConfirmation: false,
      isEvDestinationGroup: false,
      isPTOptions: false,
      isAssistanceConfirmation: false,
      ConfirmedAssistance: false,
      showPendingAssistance: false,
      journeys: {},
      returnJourneys: null,
      activeLeg: null,
      activeJourney: 0,
      activeReturnJourney: 0,
      activeJourneyLegs: null,
      activeReturnJourneyLegs: null,
      sharing: false,
      travelPlanID: null,
      updateMap: 0,
      journeyPlanID: null,
      returnJourneyPlanId: null,
      journeyOptionId: null,
      returnJourneyOptionId: null,
      profile: false,
      disruptionMessages: [],
      menu: false,
      requestAssistance: false,
      travelForecastId: null,
      profileInfo: null,
      profilefilter: {
        ...defaultFilters,
      },
      lineDatas: [],
      assistanceSettings: {
        isCurrentLocationShared: false,
        isTravelPlanShared: false,
        contacts: [
          {
            email: null,
            name: null,
            phone: null,
            status: null,
          },
        ],
      },
      assistanceRequestData: null,
      destinationElement: null,
      previousTravelPlans: null,
      pastTravelPlans: null,
      usrid: null,
      mapFocusActived: true,
      uniqueuserid: null,
      profileId: null,
      isMultiDestination: false,
      isEnableLocation: true,
      isEnableNavigation: true,
      autoCompleteParams: null,
      destination: "",
      destinationGroupShortCode: null,
      showTravelPlan: false,
      travelForecast: null,
      filters: null,
      venue_name: null,
      legs: [],
      isItineraryActive: false,
      singleNonMDEvent: null,
      events: [
        {
          id: "",
          name: "",
          description: "",
          accessPoints: [{ location: { longitude: 0.0, latitude: 0.0 } }],
          markerDetail: {
            type: "",
            color: "",
            name: "",
            size: "",
          },
        },
      ],
      trafficData: [],
      locationDropDownOpen: false,
      activeEvent: "",
      destinations: [],
      viewport: {
        latitude: 0,
        longitude: 0,
      },
      viewportMarker: null,
      mapBounds: {},
      setViewport: false,
      width: window.innerWidth,
      markerPopup: true,
      customMapURL: null,
      eventSelectedOnMap: null,
      mapRegion: null,
      lastLat: null,
      lastLong: null,
      language: "en",
      languageFromUrl: false,
      isCrossSiteChecked: false,
      crossSite: null,
      showCrossSiteWarning: false,
      defaultFrom: null,
      defaultTo: null,
      defaultSuggestionActive: false,
      disableGoogleSuggestionList: false,
      mapNotReadyToRender: true,
      boundingBox: null,
      journeyPlanButtonTxt: "",
      consentStatement: "",
      itineraryEnabled: false,
      displayDisruptionsModal: false,
      showEVCluster: false,
      clusteringEnabled: true,
      clusteringFunctionalityEnabled: false,
      groupName: "",
      thirdPartyLogin: "",
      thirdPartyAuthenticated: false,
      thirdPartyData: "",
      mapLocked: false,
      isPrioritizedPopUp: false,
      prioritizedEvent: null,
      publicTransportationOptions: {
        filterBus: true,
        filterTram: true,
        filterTrain: true,
      },
      markerOptions: [
        {
          markerId: "",
          destinationGroupShortCode: "",
          initialDisplay: false,
          marker: {
            id: "",
            name: "",
            type: "",
            color: "",
            icon: "",
            keyIcon: "",
            keyDisplayName: "",
            description: "",
            size: "",
          },
          isFocused: false,
        },
      ],
      showMarkerModal: false,
      showDirectionsModal: false,
      showIntermediateStops: false,
      isIntermediateStopsVisible: true,
      fullscreen: false,
      errorMapLoad: false,
      error: false,
      errorType: null,
      errormessage: null,
      departureTime: null,
      arrivalTime: null,
      returnDepartureTime: null,
      returnArrivalTime: null,
      selectedDateTime: null,
      selectedReturnDateTime: null,
      destinationGroupName: null,
      destinationGroupId: null,
      destinationGroup: null,
      destinationGroupAssistanceContacts: [],
      organisationId: null,
      organisation: {
        id: "",
        name: "",
        notificationAutomaticApproval: "",
        desktopFooter: {
          heading: "",
          quickLink: [],
        },
        mobileFooter: {
          heading: "",
          quickLink: [],
        },
      },
      thirdPartyPopUp: false,
      thirdPartyRedirectionURL: null,
      thirdPartyJourneyDetails: null,
      showAssistantInformation: true,
      initOperations: false,
      infoPopup: false,
      infoContent: "",
      chargePointReservationSystemAvailable: true,
      trafficForecast: false,
      isTrafficForecastAvailable: false,
      showPolyPopUp: false,
      PolyPopUp: {},
      isCheckForUpdate: false,
      endLocationEvent: null,
      startLocationEvent: null,
      editProfile: false,
      terminateLegAnimation: false,
      selectedLegIndex: 0,
      autoCompleteCustomPlaces: [],
      alertsButtonDisabled: true,
      hideEditJourneyButtons: true,
      showReturn: false,
      isReturn: false,
      headerMapVerticalOffset: false,
      unsecureUserPosition: null,
      isUserWayActive: false,
    };

    this._clusterTraffic = React.createRef();

    this.onClickTrafficCluster = this.onClickTrafficCluster.bind(this);

    this.onViewportChange = this.onViewportChange.bind(this);
  }

  static contextType = ThemeContext;

  setShowReturn = (shouldShow) => {
    this.setState({ showReturn: shouldShow });
  };

  setIsReturn = (newValue) => {
    this.setState((prevState) => {
      if (prevState.isReturn !== newValue) {
        return { isReturn: newValue };
      }
      return null;
    });
  };

  clearReturnJourneys = () => {
    this.setState({
      returnJourneys: null,
      activeReturnJourney: 0,
      activeReturnJourneyLegs: null,
      returnJourneyPlanId: null,
      returnJourneyOptionId: null,
      showReturn: false,
    });
  };

  setSelectedLegIndex = (newIndex) => {
    this.setState({ selectedLegIndex: newIndex });
  };

  setAlertsButton = (value) => {
    this.setState({ alertsButtonDisabled: value });
  };

  setTerminateLegAnimation = (shouldTerminateLegAnimation) => {
    this.setState({ terminateLegAnimation: shouldTerminateLegAnimation });
  };

  _restServiceGet = (path, options = {}) => {
    return axios.get(process.env.REACT_APP_API_BASE_URL + path, {
      ...options,
      headers: { Authorization: this.props.apiToken },
    });
  };

  _restServicePost = (path, data, options = {}) => {
    return axios.post(process.env.REACT_APP_API_BASE_URL + path, data, {
      ...options,
      headers: { Authorization: this.props.apiToken },
    });
  };

  _restServicePut = (path, data, options = {}) => {
    return axios.put(process.env.REACT_APP_API_BASE_URL + path, data, {
      ...options,
      headers: { Authorization: this.props.apiToken },
    });
  };

  closeCrossSiteWarning = () => {
    this.setState({ showCrossSiteWarning: false });
  };

  onViewportChange(viewport) {
    this.setState({ viewport });
  }

  setViewport = (latitude, longitude, zoom) => {
    this.setState({
      viewport: {
        latitude,
        longitude,
        zoom,
      },
    });
  };

  setViewportAndMarker = (latitude, longitude, zoom) => {
    this.setState({
      viewport: {
        latitude,
        longitude,
        zoom,
      },
      viewportMarker: {
        latitude,
        longitude,
      },
    });
  };

  onClickCluster = (clusterViewport) => {
    this.setState((state) => {
      const newViewport = {
        ...state.viewport,
        ...clusterViewport,
      };

      return { ...state, viewport: newViewport, activeEvent: "" };
    });
  };

  //TODO: Fold in
  onClickTrafficCluster(cluster) {
    const { clusterId, longitude, latitude } = cluster;

    const supercluster = this._clusterTraffic.current.getCluster();
    const zoom = supercluster.getClusterExpansionZoom(clusterId);

    this.setState((state) => {
      const newVewport = {
        ...state.viewport,
        latitude,
        longitude,
        zoom,
      };

      return { ...state, viewport: newVewport, activeEvent: "" };
    });
  }

  crossSiteValidationOperation = () => {
    cookieService.setCookie("checkCrossSite", "enabled", 1);
    var checkCrossSiteValue = cookieService.getCookie("checkCrossSite");

    this.setState({
      isCrossSiteChecked: true,
      crossSite: checkCrossSiteValue,
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.reactMap !== undefined && this.reactMap !== null) {
      if (this.state.showResults !== nextState.showResults) {
        this.setState({ updateMap: this.state.updateMap + 1 });
      }

      const map = this.reactMap.getMap();
      if (this.state.fullscreen === nextState.fullscreen) {
        map.resize();
      }

      if (this.state.mapLocked !== nextState.mapLocked) {
        const isMobile = this.refs.Map.clientWidth < MOBILE_VIEW_THRESHOLD;
        if (nextState.mapLocked || isMobile) {
          map.scrollZoom.disable();
          map.dragPan.disable();
          // = "pan-y";
        } else {
          map.scrollZoom.enable();
          map.dragPan.enable();
          //map.touchAction = "none";
        }
      }
    }

    if (
      this.state.assistanceRequestData !== null &&
      nextState.assistanceRequestData !== null &&
      nextState.currentPosition !== undefined &&
      this.state.currentPosition.latitude !== nextState.currentPosition.latitude
    ) {
      var boundingbox = {
        maxLatitude: nextState.assistanceRequestData.assistedLatitude,
        maxLongitude: nextState.assistanceRequestData.assistedLongitude,
        minLatitude: nextState.currentPosition.latitude,
        minLongitude: nextState.currentPosition.longitude,
      };

      try {
        console.log("Assistance Request location logic");
        this.setState({
          viewport: this.createViewPort(
            boundingbox,
            null,
            null,
            50,
            100,
            false
          ),
        });
      } catch (error) {
        console.log("Assistance Request bounding box failed");
        this.setState({
          errorType: error.response
            ? error.response.data
              ? error.response.data.toString()
              : error.message.toString()
            : error.message.toString(),
          errorMessage: mercatorError,
        });
      }
      return true;
    }

    //Keeping the travelPlanId since the map needs to wait for rendering until travelplanId is set, otherwise it tries to render early
    if (
      (this.state.boundingBox !== nextState.boundingBox &&
        this.state.boundingBox !== null) ||
      (this.state.boundingBox !== nextState.boundingBox &&
        this.state.travelPlanId !== null)
    ) {
      if (this.refs.Map.clientWidth) {
        try {
          console.log("should component update create viewport");
          this.setState({
            viewport: this.createViewPort(
              nextState.boundingBox,
              nextState.boundingBox.zoomLevel,
              nextState.boundingBox.maxZoomLevel,
              50,
              100,
              true
            ),
          });
        } catch (error) {
          console.log(
            "Can not load destinations right now, please change screen orientation"
          );
          this.setState({
            errorType: error.response
              ? error.response.data
                ? error.response.data.toString()
                : error.message.toString()
              : error.message.toString(),
            errorMessage: mercatorError,
          });
        }
      }
    }

    if (this.reactMap) {
      var rawBounds = this.reactMap.getMap().getBounds();
      var newBounds = {
        maxLatitude: rawBounds._ne.lat,
        maxLongitude: rawBounds._ne.lng,
        minLatitude: rawBounds._sw.lat,
        minLongitude: rawBounds._sw.lng,
      };

      if (
        (this.state.mapBounds.minLatitude !== newBounds.minLatitude &&
          newBounds.minLatitude) ||
        (this.state.mapBounds.maxLatitude !== newBounds.maxLatitude &&
          newBounds.maxLatitude) ||
        (this.state.mapBounds.minLongitude !== newBounds.minLongitude &&
          newBounds.minLongitude) ||
        (this.state.mapBounds.maxLongitude !== newBounds.maxLongitude &&
          newBounds.maxLongitude)
      ) {
        this.setState({ mapBounds: newBounds });
      }
    }

    return true;
  };

  speed = (bitsPerSecond) => {
    const bytesInAKilobyte = 1024;
    var KBps = (bitsPerSecond / bytesInAKilobyte).toFixed(2);
    if (KBps <= 1) return { value: bitsPerSecond, units: "Bps" };
    var MBps = (KBps / bytesInAKilobyte).toFixed(2);
    if (MBps <= 1) return { value: KBps, units: "KBps" };
    else return { value: MBps, units: "MBps" };
  };

  updateUnsecureUserPosition = async () => {
    if (navigator.geolocation) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const userPosition = {
          LastUpdated: moment(position.timestamp).parseZone().format(),
          GeoPosition: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        };

        this.setState({
          unsecureUserPosition: userPosition,
        });
      } catch (error) {
        // Handle error, e.g., user denied geolocation, etc.
      }
    }
  };

  postUnsecureUserPosition = (journeyPlanId, userPosition) => {
    this._restServicePost(
      `updateUnsecureUserPosition/${journeyPlanId}`,
      userPosition
    ).then((response) => {
      // We don't care about the response.
    });
  };

  componentDidMount = async () => {
    // if (!this.state.isCrossSiteChecked) {
    //   this.crossSiteValidationOperation();
    // }

    window.addEventListener("blur", this.onWindowBlur);
    const values = this.props.queryString;
    const fields = this.props.location.pathname.substring(1).split("/");
    const firstParam = fields[0];
    values.fields = fields;

    const searchParams = new URLSearchParams(window.location.search);
    const language = searchParams.get("lang");

    const domainName = window.location.hostname;

    let shortCode = "global"; // Set a default value for shortCode

    if (firstParam !== "" && firstParam !== "journeyplan") {
      shortCode = firstParam;
    }

    console.log("ShortCode: " + shortCode);

    var favIconUrl = window.location.origin + "/favicon-32x32.png";
    var favIconUrlApple = window.location.origin + "/apple-touch-icon.png";
    const { setTheme } = this.context;

    await this._restServiceGet("domainSettings/" + domainName)
      .then((domainResponse) => {
        if (
          domainResponse.data !== null &&
          domainResponse.data !== undefined &&
          domainResponse.data !== ""
        ) {
          shortCode = domainResponse.data.destinationGroupCode;
          if (domainResponse.data.theme) {
            document.documentElement.style.setProperty(
              "--main-font-family",
              domainResponse.data.theme.fontFamily || ""
            );
            if (domainResponse.data.theme.siteTitle) {
              document.title = domainResponse.data.theme.siteTitle;
            }
            if (domainResponse?.data?.theme.isHeaderEnabled) {
              document.documentElement.style.setProperty(
                "--header-top-padding",
                "42px"
              );
            }
            const { setTheme } = this.context;
            setTheme(domainResponse.data.theme);
          }
          safeLocalStorageService.setItem(
            "callbackURL",
            domainResponse.data.callbackURL
          );
          safeLocalStorageService.setItem(
            "logoutRedirectURL",
            domainResponse.data.logoutRedirectURL
          );
        } else {
          safeLocalStorageService.removeItem("callbackURL");
          safeLocalStorageService.removeItem("logoutRedirectURL");
        }
        this._restServiceGet("organisationByDestinationGroup/" + shortCode)
          .then((organisationResponse) => {
            console.log("Organisation Object: ", organisationResponse.data);

            this.applyOrganisationSettings(organisationResponse.data, true);
          })
          .catch((error) => {
            console.log(error);
            this.setState({
              organisation: null,
            });
          });
      })
      .catch((error) => {
        // this.updateFavicon("icon", favIconUrl);
        // this.updateFavicon("apple-touch-icon", favIconUrlApple);
        console.log(error);
      });

    const istfgm = fields[0] === "bee_network";

    if (!istfgm && language) {
      safeLocalStorageService.setItem("lang", language);
      this.props.contextWrapper.selectLanguage(language);
      this.setState({
        languageFromUrl: true,
        language: language,
      });
    }

    if (firstParam === "callback") return;

    if (firstParam === "logout")
      window.location.href = safeLocalStorageService.getItem("base_Url");

    try {
      if (!istfgm) {
        if (!this.props.auth.isAuthenticated()) {
          safeLocalStorageService.clear();
          safeLocalStorageService.setItem("lang", language);
        } else {
          this.createInitialUserIdentity();
        }
      }
    } catch (err) {
      console.log(err.message);
    }
    // Authentication result is fetched from get travel plan button NOT ACTIVE
    if (
      authLocalDataService.getAPIMUser() !== null &&
      safeLocalStorageService.getItem("travelForecast") !== null
    ) {
      var apimUser = authLocalDataService.getAPIMUser();
      var travelForecast = safeLocalStorageService.getItem("travelForecast");
      this.updateUserPosition();
      this.postUser(
        apimUser,
        JSON.parse(travelForecast),
        safeLocalStorageService.getItem("journeyOptionId"),
        safeLocalStorageService.getItem("destinationName")
      );
      this.setState({
        destination: this.props.location.pathname.substring(1),
      });
    } else if (values.travelplanid) {
      this.getTravelPlan(
        values.travelplanid,
        values.checkforupdates,
        values.assist
      );
    } else if (values.journeyplanid && values.journeyoptionid) {
      this.getJourneyPlanResponse(
        values.journeyplanid,
        values.journeyoptionid,
        firstParam
      );
      this.postUnsecureUserPosition(values.travelplanid, {
        ...this.state.unsecureUserPosition,
        RequestSent: moment.parseZone().format(),
      });
    } else if (values.userId) {
      this.toggleAssistanceConfirmation(values.userId);
    } else {
      this.setState({ events: [], destinations: [] });
      if (shortCode === "global") {
        axios
          .get("https://geolocation-db.com/json/", { timeout: 5000 })
          .then((response) => {
            let ip = response.data.IPv4;
            this.getDestinationGroups(shortCode, values, ip);
          })
          .catch((error) => {
            console.error(
              "Failed to get user location from https://geolocation-db.com/json/. Using default values.",
              error.message
            );
            this.getDestinationGroups(shortCode, values, "default");
          });
      } else {
        this.getDestinationGroups(shortCode, values);
      }
    }

    try {
      safeLocalStorageService.removeItem("travelForecast");
      safeLocalStorageService.removeItem("journeyOptionId");
      safeLocalStorageService.removeItem("journeyPlanId");
      safeLocalStorageService.removeItem("destinationName");
      safeLocalStorageService.removeItem("itineraryInputControl");
    } catch (err) {
      console.log(err.message);
    }

    window.addEventListener("resize", this.updateDimensions);

    this.getProfileFilters();

    if (!istfgm) {
      var loggedIn = safeLocalStorageService.getItem("loggedIn");
      if (loggedIn === "false") {
        if (this.props.auth.isAuthenticated()) {
          this.checkFirstLogin();
        }
      }
    }

    //show edit profile should be false by default on each component mount
    // this.setState({ editProfile: false });

    // Log network information here
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection ||
      null;

    var downloadSize = 1048576; //1Mb
    var bitsLoaded = downloadSize * 8;
    var duration = performance.now() / 1000;
    var speedBps = (bitsLoaded / duration).toFixed(2);
    var displaySpeed = this.speed(speedBps);

    const connectionType = connection ? connection.effectiveType : "unknown";
    const connectionDownlink = connection ? connection.downlink : 0;

    const data = {
      connectionType: connectionType,
      networkSpeed: connectionDownlink,
      pageLoadSpeed: performance.now(),
      userAgent: navigator.userAgent,
      //latitude: 0,
      //longitude: 0,
      internetSpeed: displaySpeed.value + "" + displaySpeed.units,
      speedBps: speedBps,
    };

    console.log("Network Information:", data);

    await this.updateUnsecureUserPosition();
  };

  applyOrganisationSettings = (organisationData, setHeaderOffset = false) => {
    if (!organisationData) return;

    // Apply theme settings
    if (organisationData.theme) {
      if (organisationData.theme.fontFamily) {
        document.documentElement.style.setProperty(
          "--main-font-family",
          organisationData.theme.fontFamily
        );
      }

      if (organisationData.theme.isHeaderEnabled) {
        document.documentElement.style.setProperty(
          "--header-top-padding",
          "42px"
        );

        if (setHeaderOffset) {
          this.setState({ headerMapVerticalOffset: true });
        }
      }

      if (organisationData.theme.siteTitle) {
        document.title = organisationData.theme.siteTitle;
      }

      // Set theme in context
      const { setTheme } = this.context;
      setTheme(organisationData.theme);
    }

    // Hide elements on page if specified.
    if (organisationData.hideClientElements?.length > 0) {
      const { setHideClientElements } = this.context;
      setHideClientElements(organisationData.hideClientElements);
    }

    // Set getTravelForecast text if specified.
    if (organisationData.getTravelForecastText) {
      const { setGetTravelForecastText } = this.context;
      setGetTravelForecastText(organisationData.getTravelForecastText);
    }

    if (organisationData.expandPTOptionsModalComponent) {
      const { setExpandPTOptions } = this.context;
      setExpandPTOptions(organisationData.expandPTOptionsModalComponent);
    }

    if (organisationData.showReturnButton) {
      const { setShowReturnButton } = this.context;
      setShowReturnButton(organisationData.showReturnButton);
    }

    if (organisationData.carbonRemoval) {
      const { setCarbonRemovalValues } = this.context;
      setCarbonRemovalValues(organisationData.carbonRemoval);
    }

    const isPTOptions = organisationData.showPTOptionsModalComponent;

    this.setState({
      hideEditJourneyButtons: false,
      isPTOptions: isPTOptions,
      organisation: organisationData,
      isTrafficForecastAvailable:
        this.state.isTrafficForecastAvailable ||
        organisationData.isTrafficForecastAvailable,
    });
  };

  updateFavicon = (rel, href) => {
    let link =
      document.querySelector(`link[rel='${rel}']`) ||
      document.createElement("link");
    link.type = "image/png";
    link.rel = rel;
    link.href = `${href}?v=${new Date().getTime()}`; // Append a timestamp to force refresh
    document.head.appendChild(link);
  };

  toggleEditProfile = () => {
    this.setState({ editProfile: !this.state.editProfile });
  };

  toggleMarkerModal = () => {
    const { showMarkerModal } = this.state;
    this.setState({ showMarkerModal: !showMarkerModal });
  };

  toggleDirectionsModal = () => {
    const { showDirectionsModal } = this.state;
    this.setState({ showDirectionsModal: !showDirectionsModal });
  };

  toggleIntermediateStopsModal = () => {
    const { showIntermediateStops } = this.state;
    this.setState({ showIntermediateStops: !showIntermediateStops });
  };

  toggleFullscreen = () => {
    const { fullscreen } = this.state;
    this.setState({ fullscreen: !fullscreen });
  };

  onRegionChange(region, lastLat, lastLong) {
    this.setState({
      mapRegion: region,
      // If there are no new values set the current ones
      lastLat: lastLat || this.state.lastLat,
      lastLong: lastLong || this.state.lastLong,
    });
  }

  componentWillUnmount() {
    if (
      typeof navigator.geolocation === "object" &&
      typeof navigator.geolocation.watchPosition === "function"
    ) {
      navigator.geolocation.clearWatch(this.watchID);
    }
    window.removeEventListener("blur", this.onWindowBlur);
  }

  onWindowBlur = () => {
    this.setState({ mapFocusActived: false });
  };

  getCurrentPosition = (changeViewport) => {
    function setCurrentPosition(location) {
      var latitude = location.coords.latitude;
      var longitude = location.coords.longitude;

      if (changeViewport) {
        this.setState({
          currentPosition: {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
            timestamp: location.timestamp,
          },
          setViewport: true,
          viewport: {
            latitude: latitude,
            longitude: longitude,
            zoom: 12,
          },
        });
      } else {
        this.setState({
          currentPosition: {
            latitude: location.coords.latitude,
            longitude: location.coords.longitude,
            timestamp: location.timestamp,
            altitude: location.coords.altitude,
            altitudeAccuracy: location.coords.altitudeAccuracy,
          },
        });
      }
      this.sendCurrentPosition(location);
    }
    navigator.geolocation.getCurrentPosition(
      setCurrentPosition.bind(this),
      () => {}
    );
  };

  authenticateThirdParty = (destinationGroupId) => {
    const token = new URLSearchParams(window.location.search).get("token");
    const _this = this;

    authenticationService.authenticate(
      destinationGroupId,
      token,
      (newState) => {
        _this.setState(newState);
      }
    );
  };

  getDestinationGroups = (shortCode, urlValues, ip = null) => {
    this.authenticateThirdParty(shortCode);

    let getViewport = false;
    let viewport;
    this.setState({ showLoading: true, errorMapLoad: false });

    this._restServiceGet("boundingbox/" + shortCode + "/")
      .then((response) => {
        getViewport = response.data.setViewport;
        if (getViewport) {
          /// TODO: Delete Log TA-2466

          console.log("get Bounding Box create viewPort");
          viewport = this.createViewPort(
            response.data.boundingBox,
            response.data.zoomLevel,
            response.data.maxZoomLevel,
            70,
            150,
            false
          );
          this.setState({
            setViewport: getViewport,
            viewport: viewport,
            boundingBox: response.data.boundingBox,
          });
        }

        var params = [];
        var url = `destinationgroup/${shortCode}/`;

        if (ip) {
          params.push(`iPv4=${ip}`);
        }

        if (urlValues.sourceIds?.[0]) {
          params.push(`sourceIds=${urlValues.sourceIds.split(",")}`);
          this.setState({ destinationGroupShortCode: urlValues.sourceIds });
        }

        //Used for Event Decision DGS
        if (urlValues.q) {
          params.push(`q=${urlValues.q}`);
          this.setState({ destinationGroupShortCode: urlValues.q });
        }

        if (urlValues.categories?.[0]) {
          params.push(`categories=${urlValues.categories.split(",")}`);
          this.setState({ destinationGroupShortCode: urlValues.categories });
        }
        if (
          urlValues.fields[1] !== undefined &&
          urlValues.fields[1] !== "journeyplan"
        ) {
          params.push(`memberValue=${urlValues.fields[1]}`);
          this.setState({ destinationGroupShortCode: urlValues.fields[1] });
        }
        if (urlValues.analyticsRestrictEvents?.[0]) {
          params.push(
            `analyticsRestrictEvents=${urlValues.analyticsRestrictEvents.split(
              ","
            )}`
          );
        }

        if (urlValues.analyticsRestrictDate?.[0]) {
          params.push(
            `analyticsRestrictDate=${urlValues.analyticsRestrictDate.split(
              ","
            )}`
          );
        }

        if (params.length > 0) {
          url = url + `?${params.join("&")}`;
        }

        return this._restServiceGet(url);
      })
      .then((response) => {
        // Set miles/kilometres.
        if (response?.data?.destinationGroup?.isKilometres === true) {
          const { setIsKilometres } = this.context;
          setIsKilometres(true);
        }

        // Destination Type Logic
        let destinations;
        let destinationGroup = null;
        let singleDestinationLocation = true;
        let isMultiDestination = false;

        // Default Suggestion Logic
        let defaultTo = "None";
        let defaultFrom = "None";
        let defaultSuggestionActive = false;
        let disableGoogleSuggestionList = false;

        let setViewport = false;
        let latitude = 0;
        let longitude = 0;
        let isclusteringEnabled = false;
        let isEnableNavigation = true;
        let autoCompleteParams = null;
        let isMapLocked = false;
        let isTrafficForecastAvailable = false;
        let destinationGroupId = null;
        let isEvDestinationGroup = false;
        let autoCompleteCustomPlaces = [];
        let isIntermediateStopsVisible = true;

        let events = response.data.events;

        destinationGroup = response.data.destinationGroup;

        destinationGroupId = destinationGroup.id;
        destinations = destinationGroup.destinations;
        autoCompleteParams = destinationGroup.autoCompleteParams;
        singleDestinationLocation =
          destinationGroup.boundingBox.maxLongitude ===
            destinationGroup.boundingBox.minLongitude &&
          destinationGroup.boundingBox.maxLatitude ===
            destinationGroup.boundingBox.minLatitude
            ? true
            : false;
        isMultiDestination = !!destinationGroup.multiDestination;
        isclusteringEnabled = !!destinationGroup.clusteringEnabled;
        isMapLocked = !!destinationGroup.mapLocked;
        isTrafficForecastAvailable = !!destinationGroup.trafficForecastEnabled;
        isIntermediateStopsVisible =
          destinationGroup.intermediateStopsVisible !== undefined
            ? destinationGroup.intermediateStopsVisible
            : true;
        var defaultToDestinationId = destinationGroup.destinations.filter(
          (dest) =>
            dest.id === urlValues.defaultTo || dest.id === urlValues.toEvent
        )?.[0]?.id;
        defaultTo =
          defaultToDestinationId ?? destinationGroup?.defaultTo ?? defaultTo;
        var defaultFromDestinationId = destinationGroup.destinations.filter(
          (dest) =>
            dest.id === urlValues.defaultFrom || dest.id === urlValues.fromEvent
        )?.[0]?.id;
        defaultFrom =
          defaultFromDestinationId ??
          destinationGroup?.defaultFrom ??
          defaultFrom;

        defaultSuggestionActive =
          !!defaultToDestinationId !== !!defaultFromDestinationId //Exclusive Or
            ? true
            : destinationGroup.defaultSuggestionActive !== undefined
            ? destinationGroup.defaultSuggestionActive
            : defaultSuggestionActive;
        disableGoogleSuggestionList =
          destinationGroup.disableGoogleSuggestionList !== undefined
            ? destinationGroup.disableGoogleSuggestionList
            : disableGoogleSuggestionList;
        autoCompleteCustomPlaces =
          destinationGroup.isCustomSuggestedListActive !== undefined
            ? destinationGroup.customPlaces
            : [];

        var enable = destinationGroup.enableNavigation;
        if (enable !== undefined) {
          isEnableNavigation = enable ? true : false;
        }

        if (
          destinationGroup.language !== undefined &&
          !this.state.languageFromUrl
        ) {
          safeLocalStorageService.setItem("lang", destinationGroup.language);
          this.props.contextWrapper.selectLanguage(destinationGroup.language);
        }

        isEvDestinationGroup =
          destinationGroup.journeyConfig &&
          destinationGroup.journeyConfig.journeyOrder
            ? destinationGroup.journeyConfig.journeyOrder.filter(
                (x) => x === 65
              ).length > 0
            : false;
        // Accessibility Logic
        if (destinationGroup.accessibilityToolEnabled) {
          this.handleAccessibility();
        }

        if (!getViewport) {
          var overrideZoomLevel = destinationGroup.zoomLevel;
          if (singleDestinationLocation && destinations.length !== 0) {
            const location = destinations.find(
              (el) => el.location !== undefined
            ).location;
            latitude = location.latitude;
            longitude = location.longitude;
            setViewport = true;
            viewport = {
              latitude: latitude,
              longitude: longitude,
              zoom: 12,
            };
          } else if (!isMultiDestination) {
            console.log("Creating single destination viewport...");

            viewport = this.createViewPort(
              destinationGroup.boundingBox,
              overrideZoomLevel,
              destinationGroup.maxZoomLevel,
              50,
              190,
              false
            );
            setViewport = true;
          } else if (isMultiDestination) {
            console.log("Creating multi-destination viewport...");
            viewport = this.createViewPort(
              destinationGroup.boundingBox,
              overrideZoomLevel,
              destinationGroup.maxZoomLevel,
              50,
              150,
              false
            );
            setViewport = true;
          } else {
            console.log("list of destinations create viewport");

            const location = destinations.find(
              (el) => el.location !== undefined
            ).location;
            latitude =
              (this.state.currentPosition &&
                this.state.currentPosition.latitude) ||
              location.latitude;
            longitude =
              (this.state.currentPosition &&
                this.state.currentPosition.longitude) ||
              location.longitude;

            viewport = this.createViewPort(
              destinationGroup.boundingBox,
              overrideZoomLevel,
              destinationGroup.maxZoomLevel,
              50,
              150,
              false
            );
            setViewport = true;
          }
        }

        // TODO: Separate this out into it's own function/logic.
        if (
          urlValues.longitude &&
          urlValues.latitude &&
          parseFloat(urlValues.longitude) &&
          parseFloat(urlValues.latitude)
        ) {
          const defaultZoom = 12;
          viewport = {
            latitude: urlValues.latitude,
            longitude: urlValues.longitude,
            zoom:
              urlValues.zoom && parseInt(urlValues.zoom)
                ? urlValues.zoom
                : defaultZoom,
          };
          setViewport = true;
        }

        if (
          defaultSuggestionActive &&
          (defaultFrom === "currentlocation" || defaultTo === "currentlocation")
        ) {
          this.getCurrentPosition(true);
        } else {
          //Check if permissions have already been granted, and if they have, display the users current position anyway
          const displayLocationIfPermissionGiven = async () => {
            if (navigator.permissions && navigator.permissions.query) {
              const permissionStatus = await navigator.permissions.query({
                name: "geolocation",
              });
              if (permissionStatus.state === "granted") {
                this.getCurrentPosition(false);
              }
            }
          };
          displayLocationIfPermissionGiven();
        }

        var object = {
          destination: shortCode,
          setViewport: getViewport ? getViewport : setViewport,
          viewport: viewport,
          isEvDestinationGroup: isEvDestinationGroup,
          isMultiDestination: isMultiDestination,
          clusteringEnabled: isclusteringEnabled,
          isEnableNavigation: isEnableNavigation,
          isIntermediateStopsVisible: isIntermediateStopsVisible,
          customMapURL: destinationGroup.customMapURL,
          defaultFrom: defaultFrom,
          defaultTo: defaultTo,
          defaultSuggestionActive: defaultSuggestionActive,
          disableGoogleSuggestionList: disableGoogleSuggestionList,
          journeyPlanButtonTxt: destinationGroup.travelplanbutntxt,
          consentStatement: destinationGroup.consentstatement,
          itineraryEnabled: destinationGroup.itineraryenabled,
          autoCompleteParams: autoCompleteParams,
          mapLocked: isMapLocked,
          isTrafficForecastAvailable: isTrafficForecastAvailable,
          autoCompleteCustomPlaces: autoCompleteCustomPlaces,
          markerOptions: destinationGroup.markerOptions,
          organisationId: destinationGroup.organisationId,
          destinationGroup: destinationGroup,
          destinationGroupId: destinationGroupId,
          destinationGroupAssistanceSettings:
            destinationGroup.assistanceSettings,
          destinationGroupName: destinationGroup.name,
        };

        if (
          destinationGroup.organisationId !== undefined &&
          destinationGroup.organisationId !== null
        ) {
          this._restServiceGet(
            "organisation/" + destinationGroup.organisationId
          )
            .then((organisationResponse) => {
              // Hide elements on page if specified.
              if (organisationResponse?.data?.hideClientElements?.length > 0) {
                const { setHideClientElements } = this.context;
                setHideClientElements(
                  organisationResponse?.data?.hideClientElements
                );
              }

              // Set getTravelForecast text if specified.
              if (organisationResponse?.data?.getTravelForecastText) {
                const { setGetTravelForecastText } = this.context;
                setGetTravelForecastText(
                  organisationResponse?.data?.getTravelForecastText
                );
              }

              if (organisationResponse?.data?.expandPTOptionsModalComponent) {
                const { setExpandPTOptions } = this.context;
                setExpandPTOptions(
                  organisationResponse?.data?.expandPTOptionsModalComponent
                );
              }

              var isPTOptions =
                organisationResponse?.data?.showPTOptionsModalComponent;

              this.setState(
                {
                  ...object,
                  hideEditJourneyButtons: false,
                  isPTOptions: isPTOptions,
                  organisation: organisationResponse.data,
                  isTrafficForecastAvailable:
                    isTrafficForecastAvailable ||
                    organisationResponse.data.trafficForecastEnabled,
                },
                () => {
                  this.getEventsByDestinationGroup(events, destinations);
                  this.getUrlJourneyPlan(
                    urlValues,
                    destinations,
                    events,
                    organisationResponse?.data?.showReturnButton
                  );
                }
              );
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.setState(
            {
              ...object,
              organisation: null,
            },
            () => {
              this.getEventsByDestinationGroup(events, destinations);
              this.getUrlJourneyPlan(urlValues, destinations, events);
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
          showResults: false,
          showLoading: false,
          showInitialLoading: false,
          showLoadingJourneyMessages: false,
          showForm: false,
          mapNotReadyToRender: false,
          errorMapLoad: true,
          errorType: error.response
            ? error.response.data
              ? error.response.data.toString()
              : error.message.toString()
            : error.message.toString(),
          errorMessage: destinationError,
        });
      });
  };

  getUrlJourneyPlan = async (
    values,
    destinations = null,
    events = null,
    showReturn = false
  ) => {
    // Clear URL after loading journeyPlan Params
    if (values?.fields) {
      const currentPath = window.location.pathname;
      const pathSegments = currentPath
        .split("/")
        .filter((segment) => segment !== "");

      // check if personalized travel plan from the url
      const journeyPlanIndex = pathSegments.indexOf("journeyplan");
      if (journeyPlanIndex !== -1) {
        pathSegments.splice(journeyPlanIndex);
      }

      // build again from segments removing query params
      const cleanedPath =
        pathSegments.length > 0 ? `/${pathSegments.join("/")}` : "/";

      window.history.pushState({}, "", cleanedPath);
    }

    // Convert all values to lower case.
    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "string") {
        values[key] = values[key].toLowerCase();
      }
    });

    if (!values.from && !values.fromEvent && !values.to && !values.toEvent) {
      this.setState({
        showLoading: false,
        showInitialLoading: false,
        showLoadingJourneyMessages: false,
        showForm: false,
      });
      return;
    }
    var startLocation = {
      description: values.from || null,
      eventId: values.fromEvent || null,
    };

    var endLocation = {
      description: values.to || null,
      eventId: values.toEvent || null,
    };

    var isTimeUpdated = false;
    var leaveAfterTime = null;
    var arriveByTime = null;

    if (values.leaveafter) {
      leaveAfterTime = new Date(values.leaveafter);
      isTimeUpdated = true;
    } else if (values.arrivebefore) {
      arriveByTime = new Date(values.arrivebefore);
      isTimeUpdated = true;
    }

    var fromDest = null;
    var toDest = null;

    // If the URL value matches a destination name exactly, use that destinations location.
    if (destinations && destinations.length > 0) {
      fromDest = destinations.find(
        (destination) =>
          destination.name.toLowerCase() === values?.from?.toLowerCase()
      );

      toDest = destinations.find(
        (destination) =>
          destination.name.toLowerCase() === values?.to?.toLowerCase()
      );
    }

    // If the toEvent has an endTime, enable return and use that as the return departure time by default.
    if (events && events.length > 0) {
      const toEvent = toDest
        ? events.find((event) => event.id === toDest?.relatedId)
        : events.find((event) => event.id === values?.toEvent);
      const fromEvent = fromDest
        ? events.find((event) => event.id === fromDest?.relatedId)
        : events.find((event) => event.id === values?.fromEvent);
      if (!leaveAfterTime && !arriveByTime && toEvent?.endTime && showReturn) {
        this.setState({
          selectedReturnDateTime: toEvent.endTime,
          isReturn: true,
        });
      }

      if (toEvent) {
        endLocation.description = toEvent.name;
        endLocation.eventId = toEvent.id;
        endLocation.latitude = toEvent?.accessPoints[0]?.location?.latitude;
        endLocation.longitude = toEvent?.accessPoints[0]?.location?.longitude;
      }

      if (fromEvent) {
        startLocation.description = fromEvent.name;
        startLocation.id = fromEvent.id;
        startLocation.latitude = fromEvent?.accessPoints[0]?.location?.latitude;
        startLocation.longitude =
          fromEvent?.accessPoints[0]?.location?.longitude;
      }
    }

    if (values.from === "currentlocation" || values.to === "currentlocation") {
      if (!geolocationService.getCurrentPosition()) {
        await geolocationService.fetchCurrentPosition();
      }

      const userLocation = geolocationService.getCurrentPosition();

      if (userLocation) {
        if (values.from === "currentlocation") {
          startLocation = {
            description: "Current Location",
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          };
        }
        if (values.to === "currentlocation") {
          endLocation = {
            description: "Current Location",
            latitude: userLocation.latitude,
            longitude: userLocation.longitude,
          };
        }
      } else {
        if (
          values.from === "currentlocation" ||
          values.to === "currentlocation"
        ) {
          this.setState({
            showLoading: false,
            showInitialLoading: false,
            showLoadingJourneyMessages: false,
            showForm: false,
          });
          return;
        }
      }
    }

    if (values.toEventSourceId) {
      endLocation.sourceId = values.toEventSourceId;
    }

    if (values.fromEventSourceId) {
      startLocation.sourceId = values.fromEventSourceId;
    }

    if (values.fromEventSectionId) {
      startLocation.sectionId = values.fromEventSectionId;
    }

    if (values.toEventSectionId) {
      endLocation.sectionId = values.toEventSectionId;
    }

    this.setState({
      startLocation: startLocation,
      endLocation: endLocation,
    });

    if (
      _.every(startLocation, (value) => value == null) ||
      _.every(endLocation, (value) => value == null)
    ) {
      this.setState({
        showLoading: false,
        showInitialLoading: false,
        showLoadingJourneyMessages: false,
        showForm: false,
      });
      return;
    }

    var journeyPlan = {
      startLocation,
      endLocation,
      departureTime: leaveAfterTime,
      arrivalTime: arriveByTime,
    };

    console.log(journeyPlan);
    var filters = {
      ...defaultFilters,
      startLocation: journeyPlan.startLocation,
      endLocation: journeyPlan.endLocation,
    };
    this.postJourneyOptions(journeyPlan, filters, isTimeUpdated, null, true);

    if (values.isReturn === "true") {
      this.setIsReturn(true);
    }
  };

  maxPaddingCalculator = (padding, width, height) => {
    const maxPadding = Math.min(width, height) / 3;
    return maxPadding < padding ? maxPadding : padding;
  };

  createViewPort = (
    boundingBox,
    overrideZoomLevel,
    maxZoomLevel,
    mobilePadding,
    desktopPadding,
    isJourneyMap
  ) => {
    maxZoomLevel = maxZoomLevel ? maxZoomLevel : 19;
    const calculatedJourneyMapHeight =
      this.refs.Map.clientWidth < MOBILE_VIEW_THRESHOLD
        ? MOBILE_JOURNEY_MAP_HEIGHT
        : this.refs.Map.clientHeight;
    const calculatedJourneyMapWidth =
      this.refs.Map.clientWidth < MOBILE_VIEW_THRESHOLD
        ? this.refs.Map.clientWidth
        : this.refs.Map.clientWidth - DESKTOP_JOURNEY_MAP_WIDTH_GAP;
    const calculatedMapHeight =
      this.refs.Map.clientWidth < MOBILE_VIEW_THRESHOLD
        ? this.refs.Map.clientHeight - MOBILE_MAP_HEIGHT_GAP
        : this.refs.Map.clientHeight;

    // console.log( "calculatedMapHeight ", calculatedMapHeight );
    // console.log( " this.refs.Map.clientHeight", this.refs.Map.clientHeight );
    // console.log( "isJourneyMap", isJourneyMap )

    var viewport;

    if (
      boundingBox.maxLongitude === boundingBox.minLongitude ||
      boundingBox.maxLatitude === boundingBox.minLatitude
    ) {
      viewport = {
        width: this.refs.Map.clientWidth,
        height: this.refs.Map.clientHeight,
        latitude:
          (boundingBox.minLatitude * 1 + boundingBox.maxLatitude * 1) / 2,
        longitude:
          (boundingBox.minLongitude * 1 + boundingBox.maxLongitude * 1) / 2,
        zoom:
          boundingBox.zoomLevel && boundingBox.zoomLevel < maxZoomLevel
            ? boundingBox.zoomLevel
            : maxZoomLevel,
      };
    } else {
      // console.log( "isJourneyMap", isJourneyMap );
      // console.log( "calculatedJourneyMapWidth", calculatedJourneyMapWidth );
      // console.log( " this.refs.Map.clientWidth", this.refs.Map.clientWidth );
      // console.log( "calculatedJourneyMapHeight", calculatedJourneyMapHeight );
      // console.log( "calculatedMapHeight", calculatedMapHeight );
      // console.log( this.maxPaddingCalculator(
      //   this.refs.Map.clientWidth < MOBILE_VIEW_THRESHOLD ? mobilePadding : desktopPadding,
      //   isJourneyMap ? calculatedJourneyMapWidth : this.refs.Map.clientWidth,
      //   calculatedMapHeight
      // ) );

      viewport = new WebMercatorViewport({
        width: isJourneyMap
          ? calculatedJourneyMapWidth
          : this.refs.Map.clientWidth,
        height: isJourneyMap ? calculatedJourneyMapHeight : calculatedMapHeight,
      }).fitBounds(
        [
          [boundingBox.maxLongitude, boundingBox.maxLatitude],
          [boundingBox.minLongitude, boundingBox.minLatitude],
        ],
        this.refs.Map.clientWidth < MOBILE_VIEW_THRESHOLD
          ? {
              padding: this.maxPaddingCalculator(
                mobilePadding,
                this.refs.Map.clientWidth, // FULL WIDTH IN ANY CASE
                isJourneyMap ? calculatedJourneyMapHeight : calculatedMapHeight
              ),
              // maxZoom does not seem like essential removed with TA-2525
              // maxZoom: maxZoomLevel
            }
          : {
              padding: this.maxPaddingCalculator(
                desktopPadding,
                isJourneyMap
                  ? calculatedJourneyMapWidth
                  : this.refs.Map.clientWidth,
                calculatedMapHeight
              ),
              // maxZoom does not seem like essential removed with TA-2525
              // maxZoom: maxZoomLevel
            }
      );
    }

    if (overrideZoomLevel != null) {
      viewport = {
        latitude: viewport.latitude,
        longitude: viewport.longitude,
        zoom: overrideZoomLevel,
      };
    }

    return viewport;
  };

  updateViewPortByPopUp = (clientHeight, latitude, longitude) => {
    if (clientHeight !== null && clientHeight !== 0) {
      var _map = this.reactMap?.getMap();

      if (!_map) return;

      var xyReturn = _map.project({
        lng: longitude,
        lat: latitude,
      });

      console.log(clientHeight); ///TODO: Client Height is not correct sometimes

      var locationReturn = _map.unproject({
        x: xyReturn.x,
        y: xyReturn.y + 10,
      });

      const newViewport = {
        ...this.state.viewport,
        longitude: locationReturn.lng,
        latitude: locationReturn.lat,
      };
      this.setState({
        viewport: newViewport,
      });
    }
  };

  getEventsByDestinationGroup = (events, destinationList) => {
    this.setState({ error: false });

    var prioritizedEvent = this.state.activeEvent;
    var isPrioritizedPopUp = false;
    var destinations = [...this.state.destinations];

    destinationList.forEach(function (destination) {
      var relatedEvent = events.find((el) => el.id === destination.relatedId);
      if (relatedEvent) {
        if (destination.prioritizedPopUp === true) {
          isPrioritizedPopUp = true;
          prioritizedEvent = relatedEvent;
        }
        destination.isMainDestinationGroup = true;
        destinations.push(destination);
      }
    });

    events.forEach(function (event, index) {
      if (event.prioritizedPopUp === true) {
        isPrioritizedPopUp = true;
        prioritizedEvent = event;
      }
    });

    this.setState({
      singleNonMDEvent: events[0],
      selectedReturnDateTime: events[0]?.endTime
        ? moment(events[0]?.endTime)
        : this.state.selectedReturnDateTime,
      destinations: destinations,
      events: events,
      departureTime: null,
      mapNotReadyToRender: false,
      isPrioritizedPopUp: isPrioritizedPopUp,
      prioritizedEvent: prioritizedEvent,
      activeEvent: prioritizedEvent,
      initOperations: true,
    });

    if (this.state.markerOptions !== undefined) {
      for (var index = 0; index < this.state.markerOptions.length; index++) {
        if (
          this.state.markerOptions[index].initialDisplay !== undefined &&
          this.state.markerOptions[index].initialDisplay === true
        ) {
          this.markerOptionsAction(index);
        }
      }
    }

    let activeDestination = null;
    if (this.state.defaultSuggestionActive) {
      if (this.state.defaultFrom !== "None") {
        activeDestination = destinations.find(
          (d) => d.id === this.state.defaultFrom
        );
      } else if (this.state.defaultTo !== "None") {
        activeDestination = destinations.find(
          (d) => d.id === this.state.defaultTo
        );
      } else {
        activeDestination = destinationList[0];
      }
    } else {
      activeDestination = destinationList[0];
    }

    if (this.state.isPrioritizedPopUp) {
      activeDestination = destinations.find(
        (d) => d.id === this.state.prioritizedEvent.id
      );
      if (this.state.isMultiDestination)
        this.setActiveEventOnMap(activeDestination, events);
    }

    if (!this.state.isMultiDestination) {
      this.setActiveEventOnMap(activeDestination, events);
    }
  };

  getFilterUserTravelPlans = (past) => {
    const apimUser = userLocalDataService.getAPIMUser();
    const userIdentityEmail = this.state.thirdPartyAuthenticated
      ? this.state.thirdPartyLogin.email
      : apimUser.identityEmail;

    this.getUserTravelPlans(userIdentityEmail, past);
  };

  getUserTravelPlans = (userIdentityEmail, past) => {
    this.setState({
      profile: true,
      error: false,
    });
    this._restServiceGet(
      "travelForecastsByUser/" + userIdentityEmail + "/" + past
    )
      .then((response) => {
        if (response.data.length !== 0) {
          if (past) {
            this.setState({
              pastTravelPlans: response.data,
            });
          } else {
            this.setState({
              previousTravelPlans: response.data,
            });
          }
        }
        this.setState({
          showLoading: false,
          showInitialLoading: false,
          showLoadingJourneyMessages: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          error: true,
          showLoading: false,
          errorType: error.response
            ? error.response.data
              ? error.response.data.toString()
              : error.message.toString()
            : error.message.toString(),
          errorMessage: travelPlanError,
        });
        if (error.response && error.response.status === 401)
          this.props.auth.login();
      });
  };

  _fetchAssistanceRequestData = async (travelPlanId) => {
    var url = "travelForecast/" + travelPlanId + "/assistance";

    this._restServiceGet(url).then((response) => {
      const assistanceRequestData = response.data;

      var assistedLatitude = null;
      var assistedLongitude = null;
      if (assistanceRequestData.userPositions) {
        var assistedPosition = assistanceRequestData.userPositions
          .slice(-1)
          .pop();
        if (
          assistedPosition &&
          assistedPosition.geoPosition &&
          assistedPosition.geoPosition.coordinates[1] &&
          assistedPosition.geoPosition.coordinates[0]
        ) {
          assistedLatitude = assistedPosition.geoPosition.coordinates[1];
          assistedLongitude = assistedPosition.geoPosition.coordinates[0];
        }
        assistanceRequestData.assistedLatitude = assistedLatitude;
        assistanceRequestData.assistedLongitude = assistedLongitude;
      }

      if (assistedLatitude && assistedLongitude) {
        this.setState(
          {
            assistanceRequestData,
          },
          () => {
            //If location information could not be fetched dont use current location logic
            if (assistedLatitude !== 0) {
              this.getCurrentPosition(false);
            }
          }
        );
      }
    });
  };

  getJourneyPlanResponseService = async (
    url,
    travelPlanId,
    checkForUpdates,
    assist
  ) => {
    this._restServiceGet(url)
      .then((response) => {
        let journeyResponse = response.data.journeyResponse;
        let returnJourneyResponse = response.data.returnJourneyResponse;

        this.postUnsecureUserPosition(journeyResponse.journeyPlanId, {
          ...this.state.unsecureUserPosition,
          RequestSent: moment.parseZone().format(),
        });

        // Enable the alerts button.
        this.setAlertsButton(false);

        // Set miles/kilometres.
        if (journeyResponse?.destinationGroup?.isKilometres === true) {
          const { setIsKilometres } = this.context;
          setIsKilometres(true);
        }

        // Hide elements on page if specified.
        if (journeyResponse?.organisation?.hideClientElements?.length > 0) {
          const { setHideClientElements } = this.context;
          setHideClientElements(
            journeyResponse.organisation.hideClientElements
          );
        }

        // Set getTravelForecast text if specified.
        if (journeyResponse?.organisation?.getTravelForecastText) {
          const { setGetTravelForecastText } = this.context;
          setGetTravelForecastText(
            journeyResponse?.organisation?.getTravelForecastText
          );
        }

        if (journeyResponse?.organisation?.carbonRemoval) {
          const { setCarbonRemovalValues } = this.context;
          setCarbonRemovalValues(journeyResponse.organisation.carbonRemoval);
        }

        this.setState(
          {
            customMapURL: journeyResponse.destinationGroup.customMapURL,
            mapNotReadyToRender: false,
            boundingBox: journeyResponse.boundingBox,
            hideEditJourneyButtons: false,
          },
          () => {
            // set assistance request bounding box after initial bounding box operations
            if (assist) {
              this._fetchAssistanceRequestData(travelPlanId);
            }
          }
        );

        let destinations = journeyResponse.destinations;
        const request = journeyResponse.request;
        let tempEvents = [];

        const tempResp = journeyResponse;
        const journeyPlanID = journeyResponse.journeyPlanId;
        const travelPlan = travelPlanId;
        const destinationGroup = journeyResponse.destinationGroup;
        const journeyOptions = journeyResponse.journeyOptions;
        const journeyOptionId = journeyResponse.journeyOptionId;
        const isEvDestinationGroup =
          destinationGroup &&
          destinationGroup.journeyConfig &&
          destinationGroup.journeyConfig.journeyOrder
            ? destinationGroup.journeyConfig.journeyOrder.filter(
                (x) => x === 65
              ).length > 0
            : false;

        const selectedJourneyOption = journeyOptions.find(
          (el) => el.id === journeyOptionId
        );

        var returnTempResp;
        var returnJourneyPlanID;
        var returnDepartureTime;
        var returnArrivalTime;
        var returnJourneyOptionId;
        var selectedReturnJourneyOption;

        if (returnJourneyResponse) {
          const { setShowReturnButton } = this.context;
          setShowReturnButton(true);

          returnTempResp = returnJourneyResponse;
          returnDepartureTime = returnJourneyResponse.request.departureTime;
          returnArrivalTime = returnJourneyResponse.request.arrivalTime;
          returnJourneyPlanID = returnJourneyResponse.journeyPlanId;
          returnJourneyOptionId = returnJourneyResponse.journeyOptionId;
          selectedReturnJourneyOption =
            returnJourneyResponse.journeyOptions.find(
              (el) => el.id === returnJourneyOptionId
            );
          returnTempResp.journeyOptions.forEach((journey) => {
            if (
              !selectedReturnJourneyOption ||
              journey.order === selectedReturnJourneyOption.order
            ) {
              journey.show = true;
              journey.active = true;
            } else {
              journey.show = false;
              journey.active = false;
            }
          });

          if (selectedReturnJourneyOption === null) {
            returnTempResp.journeyOptions[0].active = true;
          }
        }

        var startEventId = null;
        var endEventId = null;
        var startLocation = null;
        var endLocation = null;

        if (journeyResponse.request.startLocation.eventId) {
          startEventId = journeyResponse.request.startLocation.eventId;
        }

        if (journeyResponse.request.endLocation.eventId) {
          endEventId = journeyResponse.request.endLocation.eventId;
        }

        if (destinationGroup.accessibilityToolEnabled) {
          this.handleAccessibility();
        }

        var destination = destinationGroup.shortCode;

        var data = {
          organisation: journeyResponse.organisation,
          destinationGroup: destinationGroup,
          destinationGroupId: destinationGroup.id,
          organisationId: destinationGroup.organisationId,
          markerOptions: destinationGroup.markerOptions,
          clusteringEnabled: destinationGroup.clusteringEnabled ? true : false,
          isTrafficForecastAvailable:
            journeyResponse.organisation.trafficForecastEnabled === true
              ? true
              : false,
          destinationGroupAssistanceSettings:
            destinationGroup.assistanceSettings,
          isCheckForUpdate: !checkForUpdates,
        };

        var filters = journeyResponse.request.additionalFilters;

        let responseEvents = response.data.events;

        var destinationsInState = [...this.state.destinations];
        var startLocationEvent = startEventId
          ? responseEvents.find((el) => el.id === startEventId)
          : null;

        if (startLocationEvent === null) {
          startLocation = journeyResponse.request.startLocation;
        }
        var endLocationEvent = endEventId
          ? responseEvents.find((el) => el.id === endEventId)
          : null;
        if (endLocationEvent === null) {
          endLocation = journeyResponse.request.endLocation;
        }
        destinations.forEach(function (destination) {
          if (
            typeof responseEvents.find(
              (el) => el.id === destination.relatedId
            ) !== "undefined"
          ) {
            destination.isMainDestinationGroup = true;
            destinationsInState.push(destination);
          }
        });
        this.setState({ destinations: destinationsInState });
        if (this.state.isMultiDestination) {
          tempEvents = responseEvents;
        } else {
          responseEvents.forEach(function (event, index) {
            tempEvents.push(event);
          });
        }

        const selectedDestination = destinations.find((el) =>
          el.relatedId === endEventId ? endEventId : startEventId
        );

        var events = tempEvents;

        events.push(responseEvents);
        //console.log(selectedReturnJourneyOption?.order);
        this.setState((prevState) => {
          return {
            ...data,
            events: events,
            startLocationEvent: startLocationEvent,
            endLocationEvent: endLocationEvent,
            startLocation: startLocation,
            endLocation: endLocation,
            journeyPlanID: journeyPlanID,
            isReturn: !!returnJourneyResponse,
            returnJourneyPlanId: returnJourneyPlanID,
            journeyOptionId: journeyOptionId,
            returnJourneyOptionId: returnJourneyOptionId,
            travelPlanID: travelPlan,
            activeJourney: selectedJourneyOption.order,
            activeReturnJourney: selectedReturnJourneyOption
              ? selectedReturnJourneyOption.order
              : 0,
            activeJourneyLegs: selectedJourneyOption.legs,
            activeReturnJourneyLegs: selectedReturnJourneyOption
              ? selectedReturnJourneyOption.legs
              : [],
            itineraryEnabled:
              destinationGroup !== undefined
                ? destinationGroup.itineraryenabled
                : false,
            journeys: tempResp,
            returnJourneys: returnTempResp,
            searched: true,
            showResults: true,
            isEvDestinationGroup: isEvDestinationGroup, // ??
            destination: destination,
            destinationElement: selectedDestination,
            customMapURL:
              destinationGroup.customMapURL !== undefined
                ? destinationGroup.customMapURL
                : MAPTILER_PATH_WITH_TOKEN,
            showLoading: false,
            showInitialLoading: false,
            showLoadingJourneyMessages: false,
            showTravelPlan: true,
            filters: {
              filterAdults: filters.filterAdults,
              filterChildren: filters.filterChildren,
              filterWheelchair: filters.filterWheelchair,
              filterLuggage: filters.filterLuggage,
              filterGuideDog: filters.filterGuideDog,
              filterBicycle: filters.filterBicycle,
              filterRemainingRange: filters.filterRemainingRange,
              filterMaximumRange: filters.filterMaximumRange,
              filterChargingTime: filters.filterChargingTime,
              filterChademo: filters.filterChademo,
              filterComboCCS: filters.filterComboCCS,
              filterRapidAC43: filters.filterRapidAC43,
              departureTime: tempResp.request.departureTime,
              arrivalTime: tempResp.request.arrivalTime,
              returnDepartureTime: returnDepartureTime,
              returnArrivalTime: returnArrivalTime,
              startLocation: request.startLocation,
              endLocation: request.endLocation,
              viaLocations: request.viaLocations,
            },
            destinationGroupName: destinationGroup.name,
            chargePointReservationSystemAvailable:
              destinationGroup.chargePointReservationSystemAvailable !==
              undefined
                ? destinationGroup.chargePointReservationSystemAvailable
                : true,
          };
        });

        journeyOptions.forEach((journey) => {
          if (
            selectedJourneyOption === null ||
            journey.order === selectedJourneyOption.order
          ) {
            journey.show = true;
            journey.active = true;
          } else {
            journey.show = false;
            journey.active = false;
          }
        });

        if (selectedJourneyOption === null) {
          journeyOptions[0].active = true;
        }

        this.setState({
          journeyOptions: journeyOptions,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showResults: false,
          showLoading: false,
          showInitialLoading: false,
          showLoadingJourneyMessages: false,
          showForm: false,
        });
      });
  };

  getJourneyPlanResponse = (journeyplanid, journeyoptionid, shortcode) => {
    this.setState({
      showLoading: false,
      showInitialLoading: true,
      showResults: false,
      error: false,
    });
    var url = "journeyplan/" + journeyplanid + "/" + journeyoptionid + "/";

    this.getJourneyPlanResponseService(url, null, false, false);
  };

  getTravelPlan = (travelPlanId, checkForUpdates, assist) => {
    //Check if permissions have already been granted, and if they have, display the users current position anyway
    if (!assist) {
      //Check if permissions have already been granted, and if they have, display the users current position anyway
      const displayLocationIfPermissionGiven = async () => {
        if (navigator.permissions && navigator.permissions.query) {
          const permissionStatus = await navigator.permissions.query({
            name: "geolocation",
          });
          if (permissionStatus.state === "granted") {
            this.getCurrentPosition(false);
          }
        }
      };
      displayLocationIfPermissionGiven();
    }

    this.setState({
      showLoading: false,
      showInitialLoading: true,
      showResults: false,
      error: false,
    });
    var url =
      "travelForecast/" + travelPlanId + "/" + (checkForUpdates ? true : false);
    this.getJourneyPlanResponseService(
      url,
      travelPlanId,
      checkForUpdates,
      assist
    );
  };

  createUserProfile = () => {
    const apimUser = userLocalDataService.getAPIMUser();
    let user = this.state.thirdPartyAuthenticated
      ? this.state.thirdPartyLogin.email
      : apimUser.identityEmail ?? "";
    let data = {
      additionalFilters: [
        {
          ...defaultFilters,
        },
      ],
      AssistanceSettings: null,
      isDayBeforeChecked: false,
      isDisruptionChecked: false,
      isWeekBeforeChecked: false,
    };

    this._restServicePost("profile/" + user, data)
      .then((response) => {
        this.setState({ profileId: response.data });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  updateUserPosition = () => {
    navigator.geolocation.getCurrentPosition(
      this.sendCurrentPosition.bind(this),
      () => {}
    );
  };

  sendCurrentPosition = (location) => {
    if (
      location &&
      location.coords &&
      location.coords.latitude &&
      location.coords.longitude
    ) {
      const apimUser = userLocalDataService.getAPIMUser();

      let data = {
        Latitude: location.coords.latitude,
        Longitude: location.coords.longitude,
        Elevation: location.coords.altitude,
        Accuracy: location.coords.altitudeAccuracy,
      };

      if (apimUser?.identityEmail) {
        this._restServicePost(
          "updateUserLastPosition/" + apimUser.identityEmail,
          data
        )
          .then((response) => {})
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  saveEditProfileInfo = (param) => {
    if (this.state.profileId === null) {
      this.createUserProfile();
    }

    this._restServiceGet("profile/" + this.state.profileId)
      .then((response) => {
        const updatedObj = [
          {
            ...response.data.additionalFilters[0],
            filterAdults: param.filterAdults,
            filterChildren: param.filterChildren,
            filterWheelchair: param.filterWheelchair,
            filterGuideDog: param.filterGuideDog,
            filterLuggage: param.filterLuggage,
            filterBicycle: param.filterBicycle,
            filterRemainingRange: param.filterRemainingRange,
            filterMaximumRange: param.filterMaximumRange,
            filterChargingTime: param.filterChargingTime,
            filterChademo: param.filterChademo,
            filterComboCCS: param.filterComboCCS,
            filterRapidAC43: param.filterRapidAC43,
          },
        ];
        const data = {
          ...response.data,
          additionalFilters: updatedObj,
        };

        return this._restServicePut(
          "profile/" + this.state.profileId,
          data
        ).catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  getProfileFilters = () => {
    const apimUser = authLocalDataService.getAPIMUser();
    if (apimUser) {
      let userEmail = this.state.thirdPartyAuthenticated
        ? this.state.thirdPartyLogin.email
        : apimUser.identityEmail ?? "";

      this._restServiceGet("profileByUserIdentity/" + userEmail)
        .then((response) => {
          this.setState({
            profileId: response.data.id,
            profilefilter: {
              filterAdults: response.data.additionalFilters[0].filterAdults,
              filterChildren: response.data.additionalFilters[0].filterChildren,
              filterWheelchair:
                response.data.additionalFilters[0].filterWheelchair,
              filterGuideDog: response.data.additionalFilters[0].filterGuideDog,
              filterLuggage: response.data.additionalFilters[0].filterLuggage,
              filterBicycle: response.data.additionalFilters[0].filterBicycle,
              filterRemainingRange:
                response.data.additionalFilters[0].filterRemainingRange,
              filterMaximumRange:
                response.data.additionalFilters[0].filterMaximumRange,
              filterChargingTime:
                response.data.additionalFilters[0].filterChargingTime,
              filterChademo: response.data.additionalFilters[0].filterChademo,
              filterComboCCS: response.data.additionalFilters[0].filterComboCCS,
              filterRapidAC43:
                response.data.additionalFilters[0].filterRapidAC43,
            },
            assistanceSettings: response.data.assistanceSettings
              ? {
                  isCurrentLocationShared:
                    response.data.assistanceSettings.isCurrentLocationShared,
                  isTravelPlanShared:
                    response.data.assistanceSettings.isTravelPlanShared,
                  contacts: [
                    {
                      email: response.data.assistanceSettings.contacts[0].email,
                      name: response.data.assistanceSettings.contacts[0].name,
                      phone: response.data.assistanceSettings.contacts[0].phone,
                      status:
                        response.data.assistanceSettings.contacts[0].status,
                    },
                  ],
                }
              : {
                  isCurrentLocationShared: false,
                  isTravelPlanShared: false,
                  contacts: [
                    {
                      email: null,
                      name: null,
                      phone: null,
                    },
                  ],
                },
          });
          if (this.state.assistanceSettings !== null) {
            if (this.state.assistanceSettings.contacts.length !== 0) {
              if (
                this.state.assistanceSettings.contacts[0].status === "Pending"
              ) {
                this.setState({ showPendingAssistance: true });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  postJourneyOptions = async (
    journeyPlan,
    filters,
    timeUpdated,
    publicTransportationOptions,
    shouldUpdateReturnJourneys
  ) => {
    if (!journeyPlan.startLocation || !journeyPlan.endLocation) return;

    var startLocationEvent;
    var endLocationEvent;
    var excludeModes = [];

    this.state.events.forEach(function (event) {
      if (journeyPlan.startLocation.eventId === event.id) {
        startLocationEvent = event;
      }
      if (journeyPlan.endLocation.eventId === event.id) {
        endLocationEvent = event;
      }
    });

    if (this.state.isPTOptions) {
      if (!publicTransportationOptions.filterBus) {
        excludeModes.push("Bus");
        excludeModes.push("Coach");
      }
      if (!publicTransportationOptions.filterTrain) {
        excludeModes.push("Rail");
      }
      if (!publicTransportationOptions.filterTram) {
        excludeModes.push("Tram");
      }
    }

    this.setState({
      showLoadingJourneyMessages: true,
      showResults: false,
      showForm: false,
      error: false,
      showAssistantInformation: false,
      activeLeg: null,
      viewportMarker: null,
    });

    if (shouldUpdateReturnJourneys) {
      this.clearReturnJourneys();
    }

    const { isKilometres } = this.context;

    const data = {
      journeyPlanID: this.state.journeyPlanID,
      isReturnJourney: journeyPlan.isReturnJourney,
      travelPlanID: this.state.travelPlanID,
      destinationGroupShortCode:
        this.state.destinationGroupShortCode ?? this.state.destination,
      destinationGroupId: this.state.destinationGroupId
        ? this.state.destinationGroupId
        : this.state.destinationGroup.Id,
      organisationId: this.state.organisationId
        ? this.state.organisationId
        : this.state.destinationGroup.organisationId,
      endLocation: journeyPlan.endLocation,
      departureTime:
        journeyPlan.departureTime === null && journeyPlan.arrivalTime == null
          ? moment.parseZone().format()
          : journeyPlan.departureTime,
      arrivalTime: journeyPlan.arrivalTime,
      startLocation: journeyPlan.startLocation,
      journeyPlanner: "Auto",
      includeWaypoints: false,
      includeTrackpoints: true,
      excludeModes: excludeModes,
      includeIntermediateStops: true,
      additionalFilters: {
        ...filters,
        isKilometres: isKilometres,
      },
      timeUpdated: timeUpdated,
      chargeWayPoint: this.state.chargeWayPoint,
      viaLocations: journeyPlan.viaLocations,
      latestUserPosition: {
        ...this.state.unsecureUserPosition,
        RequestSent: moment.parseZone().format(),
      },
    };
    console.log("journeyOptions:request", data);

    try {
      const response = await this._restServicePost("journeyOptions", data);
      console.log("journeyOptions:response", response);

      if (response.status !== 200) {
        throw new Error(response?.data?.statusMessage || journeyPlanError);
      }

      const {
        journeyOptions,
        request,
        journeyPlanId,
        departureTime,
        arrivalTime,
        boundingBox,
      } = response.data;

      journeyOptions.forEach((journey) => {
        journey.show = true;
        journey.active = false;
      });

      const publicTransportationOptions = {
        filterBus: !request.excludeModes.some(
          (mode) => mode.includes("Bus") || mode.includes("Coach")
        ),
        filterTram: !request.excludeModes.some((mode) => mode.includes("Tram")),
        filterTrain: !request.excludeModes.some((mode) =>
          mode.includes("Rail")
        ),
      };

      const journeyFilters = {
        ...request.additionalFilters,
        startLocation: {
          ...journeyPlan.startLocation,
          ...request.startLocation,
        },
        endLocation: { ...journeyPlan.endLocation, ...request.endLocation },
        viaLocations: request.viaLocations,
        arrivalTime: request.arrivalTime,
        departureTime: request.departureTime,
      };

      this.setState({
        startLocationEvent,
        endLocationEvent,
        journeyPlanID: journeyPlanId,
        activeJourney: 0,
        journeys: response.data,
        activeJourneyLegs: journeyOptions[0]?.legs || [],
        showResults: true,
        showLoading: false,
        showInitialLoading: false,
        showLoadingJourneyMessages: false,
        showForm: false,
        venue_name: journeyPlan.endLocation.description,
        departureTime,
        arrivalTime,
        boundingBox,
        startLocation: journeyFilters.startLocation,
        endLocation: journeyFilters.endLocation,
        showTravelPlan: true,
        searched: true,
        filters: { ...journeyFilters, isKilometres },
        activeEvent: endLocationEvent,
        publicTransportationOptions,
      });
    } catch (error) {
      console.log(error);

      this.setState({
        error: true,
        errorType: journeyPlanError,
        errorMessage: error?.response?.data || journeyPlanError,
        showResults: false,
        showLoading: false,
        showInitialLoading: false,
        showLoadingJourneyMessages: false,
        showForm: false,
      });
    }
  };

  postReturnJourneyOptions = async (
    journeyPlan,
    filters,
    timeUpdated,
    publicTransportationOptions
  ) => {
    if (journeyPlan.startLocation === "" || journeyPlan.endLocation === "")
      return;

    var startLocationEvent;
    var endLocationEvent;
    var excludeModes = [];

    this.state.events.forEach(function (event) {
      if (journeyPlan.startLocation.eventId === event.id) {
        startLocationEvent = event;
      }
      if (journeyPlan.endLocation.eventId === event.id) {
        endLocationEvent = event;
      }
    });
    if (this.state.isPTOptions) {
      if (!publicTransportationOptions.filterBus) {
        excludeModes.push("Bus");
        excludeModes.push("Coach");
      }
      if (!publicTransportationOptions.filterTrain) {
        excludeModes.push("Rail");
      }
      if (!publicTransportationOptions.filterTram) {
        excludeModes.push("Tram");
      }
    }

    const { isKilometres } = this.context;

    const returnData = {
      journeyPlanID: this.state.journeyPlanID,
      travelPlanID: this.state.travelPlanID,
      destinationGroupId: this.state.destinationGroupId
        ? this.state.destinationGroupId
        : this.state.destinationGroup.Id,
      organisationId: this.state.organisationId
        ? this.state.organisationId
        : this.state.destinationGroup.organisationId,
      departureTime: journeyPlan.returnDepartureTime,
      arrivalTime: journeyPlan.returnArrivalTime,
      startLocation: journeyPlan.startLocation,
      journeyPlanner: "Auto",
      includeWaypoints: false,
      includeTrackpoints: true,
      excludeModes: excludeModes,
      includeIntermediateStops: true,
      additionalFilters: {
        ...filters,
        isKilometres: isKilometres,
      },
      timeUpdated: timeUpdated,
      chargeWayPoint: this.state.chargeWayPoint,
      viaLocations: journeyPlan.viaLocations,
      isReturnJourney: true,
      startLocation: journeyPlan.endLocation,
      endLocation: journeyPlan.startLocation,
    };

    try {
      const promises = [];

      console.log("returnJourneyOptions:request", returnData);
      promises.push(this._restServicePost("journeyOptions", returnData));

      const responses = await Promise.all(promises);

      let returnResponse;

      returnResponse = responses[0];
      console.log("returnJourneyOptions:response", returnResponse);

      if (returnResponse.status === 200) {
        returnResponse.data.journeyOptions.forEach((journey) => {
          journey.show = true;
          journey.active = false;
        });

        this.setState({
          returnJourneyPlanId: returnResponse?.data
            ? returnResponse.data.journeyPlanId
            : null,
          returnJourneys: returnResponse?.data ? returnResponse.data : [],
          activeReturnJourneyLegs: returnResponse?.data
            ? returnResponse.data.journeyOptions[0].legs
            : [],
          returnDepartureTime: returnResponse?.data
            ? returnResponse.data.departureTime
            : null,
          returnArrivalTime: returnResponse?.data
            ? returnResponse.data.arrivalTime
            : null,
          filters: {
            ...this.state.filters,
            viaLocations: returnResponse.data.request.viaLocations,
            isKilometres: isKilometres,
            returnDepartureTime: returnResponse?.data
              ? returnResponse.data.departureTime
              : null,
            returnArrivalTime: returnResponse?.data
              ? returnResponse.data.arrivalTime
              : null,
          },
        });

        if (this.state.showTravelPlan) {
          this.setState({
            filters: {
              ...this.state.filters,
              returnDepartureTime: returnResponse?.data
                ? returnResponse.data.departureTime
                : null,
              returnArrivalTime: returnResponse?.data
                ? returnResponse.data.arrivalTime
                : null,
            },
          });
        } else if (returnResponse.status) {
          var errorType = returnResponse?.data?.statusMessage;
          this.setState({
            error: true,
            errorType: journeyPlanError,
            errorMessage: errorType ?? journeyPlanError,
            showResults: false,
            showLoading: false,
            showInitialLoading: false,
            showLoadingJourneyMessages: false,
            showForm: false,
          });
        }
      } else {
        const errorType = returnResponse?.data?.statusMessage;
        this.setState({
          error: true,
          errorType: journeyPlanError,
          errorMessage: errorType ?? journeyPlanError,
          showResults: false,
          showLoading: false,
          showInitialLoading: false,
          showLoadingJourneyMessages: false,
          showForm: false,
        });
      }
    } catch (error) {
      console.log(error);

      const errorType = error?.response?.data?.statusMessage;

      this.setState({
        error: true,
        errorType: journeyPlanError,
        errorMessage: errorType ?? journeyPlanError,
        showResults: false,
        showLoading: false,
        showInitialLoading: false,
        showLoadingJourneyMessages: false,
        showForm: false,
      });
    }
  };

  postUser = (user, travelForecast, journeyOptionId, destinationName) => {
    this.setState({
      showLoading: true,
    });
    let userIdentityEndPoint = "userIdentityUnsecure";

    const apimUser = userLocalDataService.getAPIMUser();

    user.identityEmail =
      apimUser?.identityEmail ?? user.identityEmail ?? user.emailAddress;
    user.channel = 1;

    const hasReturnJourneys =
      this.state.returnJourneys?.journeyOptions?.length > 0;

    this._restServicePost(userIdentityEndPoint, user)
      .then((response) => {
        console.log(userIdentityEndPoint, response.data);
        this.postTravelForecast(
          {
            departureTime: this.state.showTravelPlan
              ? this.state.filters.departureTime
              : this.state.departureTime,
            arrivalTime: this.state.showTravelPlan
              ? this.state.filters.arrivalTime
              : this.state.arrivalTime,
            journeyOptionId: journeyOptionId
              ? journeyOptionId
              : this.state.journeys.journeyOptions[this.state.activeJourney].id,
            returnJourneyOptionId: hasReturnJourneys
              ? this.state.returnJourneys.journeyOptions[
                  this.state.activeReturnJourney
                ].id
              : null,
            journeyPlanId: this.state.journeyPlanID,
            returnJourneyPlanId: this.state?.returnJourneyPlanId
              ? this.state.returnJourneyPlanId
              : null,
            userIdentityId: response.data.id,
            userNotificationMail: user.emailAddress,
            userNotificationMobile: user.mobilePhoneNumber,
            status:
              travelForecast.status.length === 0
                ? travelForecast.status.push("NeverNotify")
                : travelForecast.status,
            notifications: true,
            useWhatsApp: travelForecast.useWhatsApp,
            mode: this.state.journeys.journeyOptions[this.state.activeJourney]
              .mode,
            venuename: this.state.venue_name,

            destinationGroupName:
              destinationName === undefined
                ? this.state.destination
                : destinationName,
            destinationGroupId: this.state.destinationGroupId
              ? this.state.destinationGroupId
              : this.state.destinationGroup.Id,
            organisationId: this.state.organisationId
              ? this.state.organisationId
              : this.state.destinationGroup.organisationId,
            // null check required since it can be anywhere tto anywhere jou,rneys
            eventId:
              this.state.activeEvent != null ? this.state.activeEvent.id : null,
            eventName:
              this.state.activeEvent != null
                ? this.state.activeEvent.name
                : null,
            notificationAutomaticApproval:
              this.state.organisation != null
                ? this.state.organisation.notificationAutomaticApproval
                : null,
          },
          {
            itineraryId: this.state.journeyPlanID,
            journeyOptionId:
              this.state.journeys.journeyOptions[this.state.activeJourney].id,
            legs:
              this.state.legs.length !== 0
                ? this.state.legs
                : this.state.activeJourneyLegs,
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  postTravelForecast = (travelForecast, itinerary) => {
    let travelForecastEndpoint = "travelForecastUnsecure";

    this._restServicePost(travelForecastEndpoint, travelForecast)
      .then((response) => {
        if (this.state.isItineraryActive && this.state.legs.length !== 0) {
          let itineraryDwellTimeEndPoint = "itineraryDwellTime";
          this.updateUserPosition();
          this._restServicePost(itineraryDwellTimeEndPoint, itinerary)
            .then((response) => {
              this.setState({
                showForm: false,
                showResults: false,
                showLoading: false,
                showInitialLoading: false,
                showLoadingJourneyMessages: false,
                showConfirmation: true,
              });
            })
            .catch((error) => {
              console.log(error);
              if (error.response.status === 401) this.props.auth.login();
            });
        } else {
          this.setState({
            showForm: false,
            showResults: false,
            showLoading: false,
            showInitialLoading: false,
            showLoadingJourneyMessages: false,
            showConfirmation: true,
          });
        }
      })

      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) this.props.auth.login();
      });
  };

  postThirdPartyTravelForecast = (
    journeyPlanId,
    journeyOption,
    userIdentityId,
    customData,
    user
  ) => {
    this.setState({ showLoading: true });
    var status = ["NeverNotify"];
    var travelForecast = {
      journeyPlanId: journeyPlanId,
      journeyOptionId: journeyOption.id,
      userIdentityId: userIdentityId,
      status: status,
    };
    console.log("travelForecastThirdParty:request", travelForecast);

    this._restServicePost("travelForecastThirdParty", travelForecast)
      .then((response) => {
        console.log("travelForecastThirdParty:response", response.data);
        let thirdPartyURL = journeyOption.thirdPartySource;
        let travelForecastId = journeyOption.thirdPartySourceManipulation
          ? response.data.id
          : "";
        let email = "";

        if (customData) {
          thirdPartyURL = customData.sourceURL;
          travelForecastId = customData.uRLManipulation ? response.data.id : "";
          email = customData.includeEmail ? "&email=" + user.emailAddress : "";
        }
        this.navigate(thirdPartyURL + travelForecastId + email, customData);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 401) this.props.auth.login();
      });
  };

  checkFirstLogin = () => {
    const apimUser = userLocalDataService.getAPIMUser();

    let user = {
      identityEmail: null,
      emailAddress: null,
    };

    user.identityEmail = this.state.thirdPartyAuthenticated
      ? this.state.thirdPartyLogin.email
      : apimUser.identityEmail ?? "";
    user.emailAddress = user.identityEmail;

    this._restServiceGet("getLastLogin/" + user.emailAddress).then(
      (response) => {
        if (
          response.data === null ||
          response.data === undefined ||
          response.data === "0001-01-01T00:00:00+00:00"
        ) {
          this.setState({ editProfile: true });
          this.toggleProfile();
        } else {
          this.setState({ profile: true });
          this.getFilterUserTravelPlans(false);
        }

        this._restServicePost("updateLastLogin/" + user.emailAddress);
        safeLocalStorageService.setItem("loggedIn", true);
      }
    );
  };

  createInitialUserIdentity = () => {
    //For creating a userIdentity due to being logged in to APIM
    const apimUser = userLocalDataService.getAPIMUser();

    let user = {
      identityEmail: null,
      channel: "TravelAssistant",
      firstName: apimUser.firstName ?? "",
      lastName: apimUser.lastName ?? "",
      password: "",
      emailAddress: null,
      selectedOrganisations: null,
      mobilePhoneNumber: "",
      isDayBeforeChecked: false,
      isWeekBeforeChecked: false,
      isDisruptionChecked: false,
      organisationId: null,
      organisationName: "",
    };

    user.identityEmail = this.state.thirdPartyAuthenticated
      ? this.state.thirdPartyLogin.email
      : apimUser.identityEmail ?? "";
    user.emailAddress = user.identityEmail;

    this._restServiceGet("userIdentityByEmail/" + user.identityEmail)
      .then((response) => {
        this.updateUserPosition();
        // User found not action required
      })
      .catch((error) => {
        if (error.response?.status === 404) {
          this._restServicePost("userIdentityUnsecure", user)
            .then(() => {
              this.createUserProfile();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
  };

  navigate = (href, customData) => {
    this.setState({
      thirdPartyPopUp: true,
      thirdPartyRedirectionURL: href,
      thirdPartyJourneyDetails: customData,
      showLoading: false,
      showInitialLoading: false,
    });
  };

  setJourneysExpanded = (journeyId, expandJourneys) => {
    this.setState((prevState) => {
      return {
        journeys: {
          ...prevState.journeys,
          journeyOptions: prevState.journeys.journeyOptions.map((journey) => {
            if (journey.id === journeyId) {
              journey.subJourneysExpanded = expandJourneys;
            }
            return journey;
          }),
        },
      };
    });
  };

  setReturnJourneysExpanded = (journeyId, expandJourneys) => {
    this.setState((prevState) => {
      return {
        returnJourneys: {
          ...prevState.returnJourneys,
          journeyOptions: prevState.returnJourneys.journeyOptions.map(
            (journey) => {
              if (journey.id === journeyId) {
                journey.subJourneysExpanded = expandJourneys;
              }
              return journey;
            }
          ),
        },
      };
    });
  };

  toggleDisruptionsModal = (toggleDisruptions, disruptionMessages) => {
    this.setState({
      displayDisruptionsModal: toggleDisruptions,
      disruptionMessages: disruptionMessages,
    });
  };

  toggleActiveJourney = (id) => {
    this.setState((prevState) => {
      var showForm = prevState.showForm;
      var showResults = prevState.showResults;
      var searched = prevState.searched;

      if (id === null && showForm) {
        showForm = !showForm;
        showResults = !showResults;
      }

      if (prevState.activeJourney === null && id === null && showResults) {
        showResults = !showResults;
        searched = false;
      }

      var _journeyOptionID =
        this.state.journeys && this.state.journeys.journeyOptions[id]
          ? this.state.journeys.journeyOptions[id].id
          : null;

      // When the main journey is changed, stop showing the previous journey's trackpoints.
      var resetActiveLeg = prevState.activeJourney !== id;

      return {
        activeJourney: id,
        journeyOptionId: _journeyOptionID,
        showForm: showForm,
        showResults: showResults,
        searched: searched,
        activeLeg: resetActiveLeg ? null : prevState.activeLeg,
      };
    });
  };

  toggleActiveReturnJourney = (id) => {
    this.setState((prevState) => {
      var showForm = prevState.showForm;
      var showResults = prevState.showResults;
      var searched = prevState.searched;

      if (id === null && showForm) {
        showForm = !showForm;
        showResults = !showResults;
      }

      if (prevState.activeJourney === null && id === null && showResults) {
        showResults = !showResults;
        searched = false;
      }

      var _returnJourneyOptionId =
        this.state.returnJourneys &&
        this.state.returnJourneys.journeyOptions[id]
          ? this.state.returnJourneys.journeyOptions[id].id
          : null;

      // When the main journey is changed, stop showing the previous journey's trackpoints.
      var resetActiveLeg = prevState.activeReturnJourney !== id;

      return {
        activeReturnJourney: id,
        returnJourneyOptionId: _returnJourneyOptionId,
        activeLeg: resetActiveLeg ? null : prevState.activeLeg,
      };
    });
  };

  setViewportToJourney = (id) => {
    this.setState({
      viewport: this.createViewPort(
        {
          ...this.state.journeys.journeyOptions[id].boundingBox,
          zoomLevel: 17,
        },
        null,
        17,
        50,
        100,
        true
      ),
    });
  };

  toggleSelection = () => {
    this.setState({
      showForm: true,
      showResults: false,
      showLoading: false,
      showInitialLoading: false,
      showLoadingJourneyMessages: false,
    });
  };

  // setDefaultSearchParameters = () => {
  //   if ( this.props.auth.isAuthenticated() ) {
  //     this.getProfileFilters();
  //   }
  // };

  setInitialMap = () => {
    this.setState({
      showResults: false,
      viewportMarker: null,
    });
  };

  setActiveEventOnMap = (destinationElement, events) => {
    if (destinationElement != null) {
      const eventShownOnMap = events.find(
        (el) => el.id === destinationElement.relatedId
      );

      this.setState((prevState) => {
        return {
          activeEvent: eventShownOnMap,
          destinationElement: destinationElement,
          singleNonMDEvent: eventShownOnMap,
        };
      });
    }
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth });
  };

  handleJourneyLegClicked = (leg, boundingBox) => {
    if (boundingBox) {
      this.setState({
        viewport: this.createViewPort(
          { ...boundingBox, zoomLevel: 17 },
          null,
          17,
          70,
          150,
          true
        ),
        activeLeg: leg,
      });
    } else {
      this.setState({ activeLeg: leg });
    }
  };

  handleLegUpdateClicked = (updatedLegs) => {
    this.setState({ legs: updatedLegs });
  };

  handleJourneyClicked = (activeJourneyLegs) => {
    this.setState({ activeJourneyLegs: activeJourneyLegs }, () => {
      if (!this.state.travelForecast || !this.state.travelForecastId) {
        if (!this.state.journeys.journeyPlanId || !this.state.journeyOptionId)
          return;
        this.debouncedtagLastJourneyOptionId(
          this.state.journeys.journeyPlanId,
          this.state.journeyOptionId
        );
      }
    });
  };

  handleReturnJourneyClicked = (activeReturnJourneyLegs) => {
    this.setState({ activeReturnJourneyLegs: activeReturnJourneyLegs }, () => {
      if (!this.state.travelForecast || !this.state.travelForecastId) {
        if (
          !this.state.returnJourneys.journeyPlanId ||
          !this.state.returnJourneyOptionId
        )
          return;
        this.debouncedtagLastJourneyOptionId(
          this.state.returnJourneys.journeyPlanId,
          this.state.returnJourneyOptionId
        );
      }
    });
  };

  debouncedtagLastJourneyOptionId = _.debounce(
    (journeyPlanId, journeyOptionId) => {
      this._restServiceGet(
        `tagLastSelectedJourneyOption/${journeyPlanId}/${journeyOptionId}`
      ).then((response) => {
        // We don't care about the response.
      });
    },
    500
  );

  handleTravelPlanToNewJourney = () => {
    if (this.state.showTravelPlan) {
      var base_url = window.location.origin;
      if (this.state.destination)
        window.location.href = base_url + "/" + this.state.destination;
      else {
        window.location.href =
          base_url + "/event?eventId=" + this.state.activeEvent.id;
      }
    }
  };

  handleItineraryActive = (isItineraryActive) => {
    this.setState({ isItineraryActive: isItineraryActive });
  };

  toggleSharing = (fromEditJourney) => {
    if (fromEditJourney) {
      this.setState({ sharing: false });
    } else {
      const currentState = this.state.sharing;
      this.setState({ sharing: !currentState });
    }
  };

  toggleMenu = () => {
    let show = this.state.menu;
    this.setState({ menu: show ? false : true, error: false });
  };

  resetToggleProfile = () => {
    this.setState({ profile: false }, function () {
      this.toggleProfile();
    });
  };

  toggleProfile = () => {
    const currentState = this.state.profile;
    const apimUser = userLocalDataService.getAPIMUser();
    if (currentState === false) {
      this.setState({ showLoading: false, showInitialLoading: true });
      this.getUserTravelPlans(
        this.state.thirdPartyAuthenticated
          ? this.state.thirdPartyLogin.email
          : apimUser.identityEmail,
        false
      );
      if (
        this.props.auth.isAuthenticated() ||
        this.state.thirdPartyAuthenticated
      ) {
        this.getProfileFilters();
      }
    } else this.setState({ profile: !currentState, error: false });
    //for cross site logins check the base URL when we toggle the profile
    this.checkCrossSite();
  };

  toggleAssistanceProfileOnConfirmation = () => {
    this.setState({
      showConfirmation: true,
      error: false,
      isAssistanceConfirmation: true,
      profile: false,
      requestAssistance: false,
      showResults: false,
    });
  };

  checkCrossSite = () => {
    var baseUri = safeLocalStorageService.getItem("base_Url");
    var uri = window.location.href;

    if (baseUri !== uri) {
      safeLocalStorageService.setItem("base_Url", uri);
    }
  };

  useCurrentLocation = (travelForecastId) => {
    const setGeoLocation = (position) => {
      if (!position) {
        alert(
          "Location Services are disabled, please enable location services to use the Current Location."
        );
        return;
      }
      console.log(position);
      this.toggleRequestAssistance(
        travelForecastId,
        position.coords !== undefined ? position.coords : position
      );
    };

    // Use value if already stored.
    const position = geolocationService.getCurrentPosition();
    if (position) {
      setGeoLocation(position);
      return;
    }

    geolocationService.fetchCurrentPosition(
      (position) => {
        // Success.
        console.log(position);
        this.toggleRequestAssistance(travelForecastId, position.coords);
        if (position) {
          setGeoLocation(position);
          return;
        }
      },
      (error) => {
        // Fail.
        var notAlowedPosition = {
          coords: {
            latitude: 0,
            longitude: 0,
          },
        };
        console.log(error);
        this.toggleRequestAssistance(
          travelForecastId,
          notAlowedPosition.coords
        );
      }
    );
  };

  toggleRequestAssistance = (travelForecastId, position) => {
    if (this.state.profileId === null) {
      //GetProfileFilters run as part of componentDidMount
      this.createUserProfile();
    }
    let show = this.state.requestAssistance;
    this.setState({
      requestAssistance: show ? false : true,
      error: false,
      travelForecastId,
      currentPosition: position,
    });
  };

  toggleAssistanceConfirmation = (userId) => {
    this._restServicePut("confirmProfile/" + userId)
      .then(() => {
        this.setState({ showConfirmation: true, ConfirmedAssistance: true });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  toggleConfirmation = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const travelForecastId = searchParams.get("travelplanid");
    if (travelForecastId) {
      this.setState({
        showConfirmation: true,
        showResults: false,
        error: false,
      });
      this.toggleRequestAssistance(null);
      return;
    }

    let show = this.state.showConfirmation;
    this.setState({
      showConfirmation: show ? false : true,
      error: false,
      isAssistanceConfirmation: true,
    });
    this.toggleRequestAssistance(null);
    this.toggleProfile();
  };

  getPreviousTravelPlan = (travelPlanId, groupName) => {
    const url = `${window.location.origin}/${groupName}/travelplan?travelplanid=${travelPlanId}`;
    const searchParams = new URLSearchParams(window.location.search);
    const isAssistanceRequested = searchParams.get("assist") === "true";
    window.location.href = isAssistanceRequested ? url + "&assist=true" : url;
  };

  logout = () => {
    this.props.auth.logout();
    safeLocalStorageService.setItem("loggedIn", false);
  };

  setActiveEventSelectedOnMap = (event, destinationElement) => {
    if (event !== null) {
      //In the case of the primary marker group, find the destination element
      destinationElement =
        destinationElement ??
        this.state.destinations.find((dest) => dest.relatedId === event.id);
      // const newViewport = {
      //   ...this.state.viewport,
      //   latitude: event.accessPoints[0].location.latitude,
      //   longitude: event.accessPoints[0].location.longitude
      // };
      this.setState({
        clickedPreviousEvent: this.state.activeEvent,
        activeEvent: event,
        eventSelectedOnMap: event,
        // viewport: newViewport,
        destinationElement: destinationElement,
        markerPopup: true,
        destinationChangeStamp: new Date(),
        selectedReturnDateTime: event?.endTime
          ? moment(event?.endTime)
          : this.state.selectedReturnDateTime,
      });
    }
  };

  setActiveTrafficSelectedOnMap = (trafficObject, toggle) => {
    if (trafficObject != null) {
      const newViewport = {
        ...this.state.viewport,
        latitude:
          trafficObject.groupOfLocations.tpegPointLocation.point
            .pointCoordinates.latitude,
        longitude:
          trafficObject.groupOfLocations.tpegPointLocation.point
            .pointCoordinates.longitude,
      };
      this.setState({
        activeTrafficObject: trafficObject,
        viewport: newViewport,
        markerTrafficPopup: true,
      });
    }
  };

  getPolyLineEventFromMap = (event) => {
    console.log(event);
    const _polyPopUp = { ...this.state.PolyPopUp };
    _polyPopUp.longitude = event.lngLat.lng;
    _polyPopUp.latitude = event.lngLat.lat;
    _polyPopUp.content =
      this.state.lineDatas[
        event.features[0].source.toString().split("_")[1]
      ].description;

    this.setState({
      showPolyPopUp: !this.state.showPolyPopUp,
      PolyPopUp: _polyPopUp,
    });
  };

  togglePolyPopUp = (polyPopUp) => {
    this.setState({
      showPolyPopUp: !this.state.showPolyPopUp,
      PolyPopUp: polyPopUp,
    });
  };

  setJourneyPlanUpdated = (
    assistantPosition,
    position,
    assistantPosDesc,
    requestorPosDesc
  ) => {
    var journeyPlan = {
      startLocation: {
        latitude: assistantPosition.latitude,
        longitude: assistantPosition.longitude,
        description: assistantPosDesc,
      },
      endLocation: {
        latitude: position.coordinates[1],
        longitude: position.coordinates[0],
        description: requestorPosDesc,
      },
      departureTime: moment.parseZone(),
    };
    this.setState({
      startLocation: journeyPlan.startLocation,
      endLocation: journeyPlan.endLocation,
      initOperations: true,
    });

    console.log(journeyPlan);

    var filters;
    filters = this.getProfileFilters();

    if (!filters) {
      filters = {
        ...defaultFilters,
        startLocation: journeyPlan.startLocation,
        endLocation: journeyPlan.endLocation,
      };
    }
    console.log("Filters", filters);

    this.setState({
      startLocation: journeyPlan.startLocation,
      endLocation: journeyPlan.endLocation,
    });

    this.postJourneyOptions(journeyPlan, filters, true);
  };

  updateCloseCallBack = () => {
    this.setState({ markerPopup: false });
  };

  updateTrafficPopupCloseCallBack = () => {
    this.setState({ markerTrafficPopup: false });
  };
  setChargeWayPoint = (chargePointDestination) => {
    this.setState({ chargeWayPoint: chargePointDestination });
  };

  handleLoginOperation = () => {
    // const embeddedOniFrame = window.location !== window.parent.location;
    // if (
    //   !embeddedOniFrame ||
    //   (embeddedOniFrame && this.state.isCrossSiteChecked && this.state.crossSite === "enabled")
    // ) {
    this.props.auth.login();
    // } else {
    //   this.crossSiteValidationOperation();
    //   this.setState({ showCrossSiteWarning: true });
    // }
  };

  handleDisruptionChange = () => {
    this.setState({
      showEVCluster: !this.state.showEVCluster,
    });
  };

  lockUnlockMap = () => {
    this.setState({
      mapLocked: !this.state.mapLocked,
    });
  };

  switchTrafficForecast = () => {
    if (!this.state.trafficForecast) {
      this.getTrafficForecast();
    } else {
      this.setState({
        trafficData: [],
        showLoading: false,
        showInitialLoading: false,
        showLoadingJourneyMessages: false,
        showForm: false,
        mapNotReadyToRender: false,
      });
    }
    this.setState({
      trafficForecast: !this.state.trafficForecast,
    });
  };

  handleTimeChange = (selectedDateTime) => {
    this.setState({
      selectedDateTime,
    });
  };

  handleReturnTimeChange = (selectedReturnDateTime) => {
    this.setState({
      selectedReturnDateTime,
    });
  };

  markerOptionsAction = (index) => {
    //Toggle the markerOption isFocused, and let the component handle the rest
    this.setState((prevState) => {
      return {
        markerOptions: prevState.markerOptions.map((markerOption, curIndex) => {
          return {
            ...markerOption,
            isFocused:
              curIndex === index
                ? !markerOption.isFocused
                : markerOption.isFocused,
          };
        }),
      };
    });
  };

  getTrafficForecast = () => {
    this._restServiceGet("disruption/bd8e38d6-3175-6e80-13d5993e9122")
      .then((response) => {
        var trafficDataTemp = [];
        response.data.forEach(function (entry) {
          entry.situationRecord.forEach((element) => {
            trafficDataTemp.push(element);
          });
        });

        this.setState({
          trafficData: [...this.state.trafficData, ...trafficDataTemp],
          showLoading: false,
          showInitialLoading: false,
          showLoadingJourneyMessages: false,
          showForm: false,
          mapNotReadyToRender: false,
        });
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showForm: false,
        });
      });
  };

  handleAccessibility = () => {
    const script = document.createElement("script");
    script.setAttribute("data-account", "gXKL7gvqmQ");
    script.setAttribute("src", "https://cdn.userway.org/widget.js");
    script.async = true;

    document.body.appendChild(script);

    this.setState({ isUserWayActive: true });
  };

  _renderAddNotification = () => {
    const { showForm } = this.state;
    return (
      showForm && (
        <>
          <div className={styles["buffer"]} />
          <AddNotification
            organisation={this.state.organisation}
            journeyOptionId={
              this.state.journeys.journeyOptions[this.state.activeJourney].id
            }
            callbackFromParent={this.postUser}
            auth={this.props.auth}
            consent={this.state.consentStatement}
          />
        </>
      )
    );
  };

  _renderErrorMessage = () => {
    const { error, errorType, errorMessage } = this.state;
    return error && <Error errorType={errorType} errorMessage={errorMessage} />;
  };

  _renderConfirmation = () => {
    const { showConfirmation, ConfirmedAssistance, isAssistanceConfirmation } =
      this.state;
    return (
      showConfirmation && (
        <Confirmation
          destination={
            this.state.destination
              ? "/" + this.state.destination
              : "/" + this.state.destinationGroup?.shortCode
          }
          isAssistanceConfirmation={isAssistanceConfirmation}
          ConfirmedAssistance={ConfirmedAssistance}
        />
      )
    );
  };

  _renderCrossSiteWarning = () => {
    const { showCrossSiteWarning } = this.state;
    return (
      showCrossSiteWarning && (
        <CrossSiteWarning handleWarningClose={this.closeCrossSiteWarning} />
      )
    );
  };

  _renderThirdPartyPopup = () => {
    const {
      thirdPartyPopUp,
      thirdPartyRedirectionURL,
      thirdPartyJourneyDetails,
    } = this.state;
    return (
      thirdPartyPopUp && (
        <RedirectionPopup
          redirectionURL={thirdPartyRedirectionURL}
          popUpContent={thirdPartyJourneyDetails}
          handlePopupClose={() =>
            this.setState({
              thirdPartyPopUp: false,
              thirdPartyRedirectionURL: null,
              thirdPartyJourneyDetails: null,
            })
          }
        />
      )
    );
  };

  _renderInfoPopup = () => {
    const { infoPopup, infoContent, chargePointReservationSystemAvailable } =
      this.state;
    return (
      infoPopup &&
      !chargePointReservationSystemAvailable && (
        <InfoPopup
          popUpContent={infoContent}
          handlePopupClose={() =>
            this.setState({
              infoPopup: false,
              infoContent: "",
            })
          }
        />
      )
    );
  };

  showInfoPopup = (info) => {
    this.setState({ infoPopup: true, infoContent: info });
  };

  _renderEditJourney = () => {
    const {
      showConfirmation,
      searched,
      events,
      showResults,
      activeJourney,
      destinations,
      showForm,
      showVia1,
      showMenu,
      isEvDestinationGroup,
      isPTOptions,
    } = this.state;

    const selectedLeg =
      this.state?.activeJourneyLegs?.[this.state.selectedLegIndex];

    const selectedReturnLeg =
      this.state?.activeReturnJourneyLegs?.[this.state.selectedLegIndex];

    return (
      !showConfirmation && (
        <EditJourney
          apiToken={this.props.apiToken}
          destinationElement={this.state.destinationElement}
          postJourneyOptions={this.postJourneyOptions}
          postReturnJourneyOptions={this.postReturnJourneyOptions}
          toggleSelection={this.toggleSelection}
          complete={searched}
          events={events}
          showButton={showResults && activeJourney !== null}
          showCurrentLocation={this.getCurrentPosition}
          toggleMarker={this.setActiveEventOnMap}
          destinations={destinations}
          showForm={showForm}
          showVia1={showVia1}
          isEvDestinationGroup={isEvDestinationGroup}
          isPTOptions={isPTOptions}
          publicTransportationOptions={this.state.publicTransportationOptions}
          mapFocusActived={this.state.mapFocusActived}
          handleTimeChange={this.handleTimeChange}
          handleReturnTimeChange={this.handleReturnTimeChange}
          lang={this.state.language}
          // startNewSearch={this.setDefaultSearchParameters}
          filters={this.state.filters}
          profilefilters={this.state.profilefilter}
          auth={this.props.auth}
          showTravelPlan={this.state.showTravelPlan}
          // handleTravelPlanToNewJourney={this.handleTravelPlanToNewJourney}
          handleItineraryActive={this.handleItineraryActive}
          showProfile={this.toggleProfile}
          showMenu={this.toggleMenu}
          handleLoginOperation={this.handleLoginOperation}
          toggleSharepage={this.toggleSharing}
          defaultFrom={this.state.defaultFrom}
          defaultTo={this.state.defaultTo}
          organisation={this.state.organisation}
          defaultSuggestionActive={this.state.defaultSuggestionActive}
          disableGoogleSuggestionList={this.state.disableGoogleSuggestionList}
          suggestionSource={
            this.state.autoCompleteParams?.autoCompleteSource ?? "google"
          }
          suggestionApproximateLocation={this.state.viewport}
          journeyPlanBtntxt={this.state.journeyPlanButtonTxt}
          destinationGroupId={this.state.destinationGroup?.id}
          isItineraryEnabled={this.state.itineraryEnabled}
          autoCompleteParams={
            this.state.autoCompleteParams !== null ||
            this.state.autoCompleteParams !== undefined
              ? this.state.autoCompleteParams
              : null
          }
          journeyPlanID={this.state.journeyPlanID}
          travelPlanID={this.state.travelPlanID}
          departureTime={this.state.departureTime}
          arrivalTime={this.state.arrivalTime}
          returnDepartureTime={this.state.returnDepartureTime}
          returnArrivalTime={this.state.returnArrivalTime}
          setInitialMap={this.setInitialMap}
          initOperations={this.state.initOperations}
          destinationChangeStamp={this.state.destinationChangeStamp}
          setCheckForUpdates={(toValue) => {
            this.setState({ isCheckForUpdate: toValue });
          }}
          isCheckForUpdate={this.state.isCheckForUpdate}
          hideEditProfile={this.hideEditProfile}
          toggleEditProfile={this.toggleEditProfile}
          editProfile={this.state.editProfile}
          startLocation={this.state.startLocation}
          endLocation={this.state.endLocation}
          setTerminateLegAnimation={this.setTerminateLegAnimation}
          showMarkerModal={this.state.showMarkerModal}
          toggleMarkerModal={this.toggleMarkerModal}
          showDirectionsModal={this.state.showDirectionsModal}
          showIntermediateStops={this.state.showIntermediateStops}
          selectedLeg={selectedLeg}
          selectedReturnLeg={selectedReturnLeg}
          toggleDirectionsModal={this.toggleDirectionsModal}
          toggleIntermediateStopsModal={this.toggleIntermediateStopsModal}
          markerOptions={this.state.markerOptions}
          markerOptionsAction={this.markerOptionsAction}
          setViewport={this.setViewport}
          setViewportAndMarker={this.setViewportAndMarker}
          isTrafficForecastAvailable={this.state.isTrafficForecastAvailable}
          trafficForecast={this.state.trafficForecast}
          switchTrafficForecast={this.switchTrafficForecast}
          showLoading={this.state.showLoading}
          showLoadingJourneyMessages={this.state.showLoadingJourneyMessages}
          autoCompleteCustomPlaces={this.state.autoCompleteCustomPlaces}
          locationDropDownOpen={this.state.locationDropDownOpen}
          setLocationDropDownOpen={(setFunc, callback) => {
            this.setState((prev) => {
              return {
                locationDropDownOpen: setFunc(prev.locationDropDownOpen),
              };
            }, callback);
          }}
          setAlertsButton={this.setAlertsButton}
          alertsButtonDisabled={this.state.alertsButtonDisabled}
          hideButtons={this.state.hideEditJourneyButtons}
          resetViewportMarker={this.resetViewportMarker}
          selectedReturnDateTime={this.state.selectedReturnDateTime}
          showReturnButton={this.context.showReturnButton}
          showReturn={this.state.showReturn}
          activeEvent={this.state.activeEvent}
          setIsReturn={this.setIsReturn}
          isReturn={this.state.isReturn}
          setShowReturn={this.setShowReturn}
          hasReturnJourneys={this.state.returnJourneys?.journeyPlanId}
          handleUpdateMarkerOptions={this.handleUpdateMarkerOptions}
          isItineraryActive={this.state.isItineraryActive}
          clearReturnJourneys={this.clearReturnJourneys}
          isUserWayActive={this.state.isUserWayActive}
        />
      )
    );
  };

  _renderJourneyCollection = () => {
    const {
      showResults,
      journeys,
      returnJourneys,
      activeJourney,
      activeReturnJourney,
    } = this.state;

    return (
      showResults &&
      journeys && (
        <>
          <JourneyCollection
            journeys={journeys}
            returnJourneys={returnJourneys}
            showReturn={this.state.showReturn}
            isReturn={this.state.isReturn}
            setShowReturn={this.setShowReturn}
            departureTime={this.state.departureTime}
            arrivalTime={this.state.arrivalTime}
            returnArrivalTime={this.state.returnArrivalTime}
            returnDepartureTime={this.state.returnDepartureTime}
            activeJourney={activeJourney}
            activeReturnJourney={activeReturnJourney}
            enableNavigation={this.state.isEnableNavigation}
            toggleActiveJourney={this.toggleActiveJourney}
            toggleActiveReturnJourney={this.toggleActiveReturnJourney}
            setViewportToJourney={this.setViewportToJourney}
            onJourneyLegClicked={this.handleJourneyLegClicked}
            onJourneyClicked={this.handleJourneyClicked}
            onReturnJourneyClicked={this.handleReturnJourneyClicked}
            onLegUpdateClicked={this.handleLegUpdateClicked}
            thirdPartyData={this.state.thirdPartyData}
            apiToken={this.props.apiToken}
            toggleSelection={this.toggleSelection}
            showTravelPlan={this.state.showTravelPlan}
            travelForecast={this.state.travelForecast}
            toggleRequestAssistance={this.toggleRequestAssistance}
            auth={this.props.auth}
            _restServicePost={this._restServicePost}
            itineraryEnabled={this.state.itineraryEnabled}
            showInfoPopup={this.showInfoPopup}
            setJourneysExpanded={this.setJourneysExpanded}
            setReturnJourneysExpanded={this.setReturnJourneysExpanded}
            displayDisruptionsModal={this.state.displayDisruptionsModal}
            toggleDisruptionsModal={this.toggleDisruptionsModal}
            terminateLegAnimation={this.state.terminateLegAnimation}
            setTerminateLegAnimation={this.setTerminateLegAnimation}
            toggleDirectionsModal={this.toggleDirectionsModal}
            toggleIntermediateStopsModal={this.toggleIntermediateStopsModal}
            isIntermediateStopsVisible={this.state.isIntermediateStopsVisible}
            setSelectedLegIndex={this.setSelectedLegIndex}
            locationDropDownOpen={this.state.locationDropDownOpen}
            setAlertsButton={this.setAlertsButton}
            alertsButtonDisabled={this.state.alertsButtonDisabled}
            activeTimeTarget={this.state.activeTimeTarget}
            clearActiveTrackpoints={() => {
              this.setState({ activeLeg: null });
            }}
            selectedReturnDateTime={this.state.selectedReturnDateTime}
            activeEvent={this.state.activeEvent}
          />
        </>
      )
    );
  };

  _renderEvChargePoint = () => {
    if (this.state.evChargeLeg) {
      let event = this.state.evChargeLeg.chargePoint.location;
      event.name = this.state.evChargeLeg.chargePoint.location.description;
      event.destinationId = this.state.evChargeLeg.chargePoint.id;
      event.mapContent = this.state.evChargeLeg.chargePoint.mapContent;
      event.status = this.state.evChargeLeg.chargePoint.status;

      return (
        <>
          <MapMarkerPopup
            isVisGlLibrary={true}
            event={event}
            destinationElement={this.state.evChargeLeg.chargePoint}
            destinationGroup={this.state.destinationGroup}
            travelPlanId={this.state.travelForecastId}
            updateCloseCallBack={this.updateCloseCallBack}
            updateViewPortByPopUp={this.updateViewPortByPopUp}
            setChargeWayPoint={this.setChargeWayPoint}
            mapContent={this.state.evChargeLeg.chargePoint.mapContent}
            forceShowPopup={
              this.state.clickedPreviousEvent === this.state.activeEvent
                ? Math.floor(Math.random() * 100)
                : false
            }
          />
        </>
      );
    }
  };

  convertJourneysToPolylines = (journeys) => {
    return journeys.journeyOptions.map((journey) => {
      return journey.legs.flatMap((leg) => {
        return {
          isDotted: leg?.isPolylinePlaceholder || leg?.mode === "Walk",
          trackpoints:
            leg.trackpoints?.map((point) => [
              point.longitude,
              point.latitude,
            ]) || [],
        };
      });
    });
  };

  getLineData = (coordinates) => {
    return {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: coordinates,
      },
    };
  };

  renderRoute = (name, routes, themeColor) =>
    routes.map((route, index) =>
      route.map((routeObj, subIndex) => [
        <Source
          key={`${name}routeData${index}-${subIndex}`}
          id={`${name}routeData${index}-${subIndex}`}
          type="geojson"
          data={this.getLineData(routeObj.trackpoints)}
        />,
        <Layer
          key={`${name}route${index}-${subIndex}`}
          id={`${name}route${index}-${subIndex}`}
          type="line"
          source={`${name}routeData${index}-${subIndex}`}
          layout={{
            visibility:
              name === "main"
                ? index === this.state.activeJourney
                  ? "visible"
                  : "none"
                : index === this.state.activeReturnJourney
                ? "visible"
                : "none",
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": themeColor,
            "line-width":
              (name === "main" && !this.state.showReturn) ||
              (name === "return" && this.state.showReturn)
                ? 8
                : 0, // Don't show the return line!
            "line-opacity": 0.8,
            "line-dasharray": routeObj.isDotted ? [1, 2] : [1, 0],
          }}
        />,
      ])
    );

  _renderJourneyPolylines = () => {
    const { journeys, returnJourneys } = this.state;
    const isReturn = !!returnJourneys?.journeyPlanId;
    const routes = this.convertJourneysToPolylines(journeys);
    var returnRoutes;
    if (isReturn) {
      returnRoutes = this.convertJourneysToPolylines(returnJourneys);
    }

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <>
            {this.renderRoute(
              "main",
              routes,
              this.state.showReturn ? "#808080" : theme.theme.polylineColour
            )}
            {isReturn &&
              this.renderRoute(
                "return",
                returnRoutes,
                this.state.showReturn ? theme.theme.polylineColour : "#808080"
              )}
          </>
        )}
      </ThemeContext.Consumer>
    );
  };

  _renderPolylines = () => {
    return (
      this.state.lineDatas !== undefined &&
      this.state.lineDatas.map((lineData, index) => {
        return [
          <Source id={"Source_" + index} type="geojson" data={lineData} />,
          <Layer
            key={index}
            id={"Source_" + index}
            type="line"
            dataModel={lineData}
            source={"Source_" + index}
            onClick={(event) => this.getPolyLineEventFromMap(event)}
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            paint={{
              "line-color": lineData.color,
              "line-width": 8,
              "line-opacity": 1,
            }}
          />,
        ];
      })
    );
  };

  _renderCurrentLocation = () => {
    if (
      !this.state.currentPosition ||
      this.state.currentPosition.latitude === 0 ||
      this.state.currentPosition.longitude === 0
    ) {
      return;
    }

    return (
      <Marker
        latitude={this.state.currentPosition.latitude}
        longitude={this.state.currentPosition.longitude}
        offsetLeft={-20}
        offsetTop={-40}
      >
        <CurrentLocation colour="#FF0000" />
      </Marker>
    );
  };

  _renderPolylinePopUp = () => {
    return (
      <Popup
        longitude={this.state.PolyPopUp.longitude}
        latitude={this.state.PolyPopUp.latitude}
        closeButton={false}
        closeOnClick={false}
      >
        {this.state.PolyPopUp.content}
      </Popup>
    );
  };

  _renderSelectedLocations = () => {
    // TODO: Markers on via points in itinerary? Add them here!
    var locationsForMarkers = [];

    if (this.state.startLocationEvent) {
      var startMarkerEvent = this.state.startLocationEvent;
      startMarkerEvent.latitude =
        this.state.activeJourneyLegs[0].startLocation.latitude;
      startMarkerEvent.longitude =
        this.state.activeJourneyLegs[0].startLocation.longitude;
      locationsForMarkers.push(startMarkerEvent);
    } else if (this.state.startLocation) {
      locationsForMarkers.push(this.state.startLocation);
    }

    if (this.state.endLocationEvent) {
      var endMarkerEvent = this.state.endLocationEvent;
      endMarkerEvent.latitude =
        this.state.activeJourneyLegs[
          this.state.activeJourneyLegs.length - 1
        ].endLocation.latitude;
      endMarkerEvent.longitude =
        this.state.activeJourneyLegs[
          this.state.activeJourneyLegs.length - 1
        ].endLocation.longitude;
      locationsForMarkers.push(endMarkerEvent);
    } else if (this.state.endLocation) {
      locationsForMarkers.push(this.state.endLocation);
    }

    return locationsForMarkers.map((location, index) => {
      const { latitude, longitude } = location;
      return (
        <Marker
          key={index}
          latitude={latitude}
          longitude={longitude}
          offsetLeft={-20}
          offsetTop={-40}
        >
          <LocationOn
            data-cy={"markers"}
            className={styles["LocationOn"]}
            onClick={() => this.onClickMarker(location)}
          />
        </Marker>
      );
    });
  };

  _renderViewportMarker = () => {
    if (this.state.viewportMarker) {
      return (
        <>
          <Marker
            key={999}
            latitude={this.state.viewportMarker.latitude}
            longitude={this.state.viewportMarker.longitude}
            offsetLeft={-20}
            offsetTop={-40}
          >
            <LocationOn data-cy={"markers"} className={styles["LocationOn"]} />
          </Marker>
        </>
      );
    }
  };

  resetViewportMarker = () => {
    this.setState({
      viewportMarker: null,
    });
  };

  onClickMarker = (event) => {
    this.setState({
      activeEvent: event,
      clickedPreviousEvent: this.state.activeEvent,
      markerPopup: true,
    });
  };

  _renderActivePolyline = () => {
    if (!this.state.activeLeg) {
      return;
    }

    var activeTrackCoordinates = [];
    if (this.state.activeLeg != null) {
      this.state.activeLeg.trackpoints.forEach((trackpoint) => {
        activeTrackCoordinates.push([
          trackpoint.longitude,
          trackpoint.latitude,
        ]);
      });
    }

    return (
      <ThemeContext.Consumer>
        {(theme) => (
          <React.Fragment key={"activeTrackWrapper"}>
            <Source
              key={"activeTrackData"}
              id="activeTrackData"
              type="geojson"
              data={this.getLineData(activeTrackCoordinates)}
            />
            <Layer
              key={"activeTrack"}
              id={"activeTrack"}
              type="line"
              source="activeTrackData"
              layout={{
                visibility: "visible",
                "line-join": "round",
                "line-cap": "round",
              }}
              paint={{
                "line-color": theme.theme.activePolylineColour,
                "line-width": 8,
                "line-opacity": 1,
                "line-dasharray":
                  this.state.activeLeg.mode === "Walk" ? [1, 2] : [1, 0],
              }}
            />
          </React.Fragment>
        )}
      </ThemeContext.Consumer>
    );
  };

  //TODO: Componentise this!
  _renderTrafficData = () => {
    return (
      <Cluster
        ref={this._clusterTraffic}
        radius={40}
        extent={512}
        nodeSize={64}
        component={(cluster) => (
          <ClusterTrafficMarker
            onClick={this.onClickTrafficCluster}
            {...cluster}
          />
        )}
      >
        {this.state.trafficData.map(
          (trafficObject, index) =>
            trafficObject !== undefined &&
            trafficObject.groupOfLocations !== undefined &&
            trafficObject.groupOfLocations.tpegPointLocation !== undefined &&
            trafficObject.groupOfLocations.tpegPointLocation.point !==
              undefined &&
            trafficObject.groupOfLocations.tpegPointLocation.point
              .pointCoordinates !== undefined && (
              <Marker
                key={index}
                latitude={
                  trafficObject.groupOfLocations.tpegPointLocation.point
                    .pointCoordinates.latitude
                }
                longitude={
                  trafficObject.groupOfLocations.tpegPointLocation.point
                    .pointCoordinates.longitude
                }
                offsetLeft={-20}
                offsetTop={-40}
              >
                <ClusteringTrafficLocationOn
                  trafficObject={trafficObject}
                  onClick={() =>
                    this.setActiveTrafficSelectedOnMap(trafficObject, true)
                  }
                ></ClusteringTrafficLocationOn>
              </Marker>
            )
        )}
      </Cluster>
    );
  };

  isWithin100m = (lat1, lon1, lat2, lon2) => {
    var R = 6371; // Radius of the earth in km
    var dLat = (lat2 - lat1) * (Math.PI / 180); // deg2rad below
    var dLon = (lon2 - lon1) * (Math.PI / 180);
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in KM.
    if (d > 0.1) return true;
    return false;
  };

  _renderPolylineIcons = () => {
    const journeyLegsToRenderIconsFrom = this.state.showReturn
      ? this.state.activeReturnJourneyLegs
      : this.state.activeJourneyLegs;

    if (!journeyLegsToRenderIconsFrom) return;
    return journeyLegsToRenderIconsFrom.map((leg, index) => {
      const { trackpoints, startLocation, endLocation, mode } = leg;

      let latitude =
        trackpoints.length > 0
          ? trackpoints[Math.floor(trackpoints.length / 2)].latitude
          : startLocation.latitude;
      let longitude =
        trackpoints.length > 0
          ? trackpoints[Math.floor(trackpoints.length / 2)].longitude
          : startLocation.longitude;

      // Render the flight icon at the start of the leg as the data given is insufficient to accurately
      // render it at the midpoint of the line.
      if (leg.mode === "Flight") {
        latitude = startLocation.latitude;
        longitude = startLocation.longitude;
      }

      const distance = this.isWithin100m(
        latitude,
        longitude,
        endLocation.latitude,
        endLocation.longitude
      );

      return index < journeyLegsToRenderIconsFrom.length - 1 || distance ? (
        <Marker
          key={index}
          latitude={latitude}
          longitude={longitude}
          offsetLeft={-10}
          offsetTop={-10}
        >
          {this._renderTravelModeIcon(mode)}
        </Marker>
      ) : (
        ""
      );
    });
  };

  _renderTravelModeIcon(param) {
    const icons = {
      Walk: Walk,
      Ferry: Ferry,
      Rail: Rail,
      Bus: Bus,
      Underground: Underground,
      Tram: Tram,
      ElectricVehicle: Drive,
      Drive: Drive,
      Park: Park,
      EvStation: EvStation,
      Flight: Flight,
      CycleDock: CycleDock,
      Cycle: Cycle,
      CycleStreetsQuietest: Cycle,
      CycleStreetsFastest: Cycle,
      CycleStreetsBalanced: Cycle,
      CycleStreetsDefault: Cycle,
    };

    const iconSrc = icons[param];
    if (iconSrc) {
      return (
        <img
          src={iconSrc}
          className={styles[param === "Flight" ? "Flight" : "Icon"]}
          alt={param.toLowerCase()}
        />
      );
    } else {
      return null;
    }
  }

  updateStartEndLocation = (
    assistantPosition,
    requestorPosition,
    assistantPosDesc,
    requestorPosDesc
  ) => {
    if (
      !assistantPosition ||
      !assistantPosition.longitude ||
      !assistantPosition.latitude
    ) {
      alert(
        "Location Services are disabled, please enable location services to use this function"
      );
      return;
    }
    this.setJourneyPlanUpdated(
      assistantPosition,
      requestorPosition,
      assistantPosDesc,
      requestorPosDesc
    );
  };

  _renderActiveEvent = () => {
    if (!this.state.activeEvent) return;
    //If only the event to display has been set, assume it belongs to the primary group
    const destinationElement =
      this.state.destinations.find(
        (el) => el.relatedId === this.state.activeEvent.id
      ) ?? this.state.destinationElement;

    if (!destinationElement) return "";

    return (
      <div>
        {this.state.markerPopup && (
          //Attention, MapMarkerPopup development contains a workaround, forceShowPopup triggered by random number because MapMarkerPopup's hook architecture is not working as we want
          <MapMarkerPopup
            isVisGlLibrary={true}
            event={this.state.activeEvent}
            destinationElement={destinationElement}
            travelPlanId={this.state.travelForecastId}
            updateCloseCallBack={this.updateCloseCallBack}
            setChargeWayPoint={this.setChargeWayPoint}
            updateViewPortByPopUp={this.updateViewPortByPopUp}
            selectedDateTime={
              this.state.selectedDateTime === null
                ? moment.parseZone().format("yyyy-MM-DD")
                : moment(this.state.selectedDateTime).format("yyyy-MM-DD")
            }
            destinationGroup={this.state.destinationGroup}
            forceShowPopup={
              this.state.clickedPreviousEvent === this.state.activeEvent
                ? Math.floor(Math.random() * 100)
                : false
            }
          />
        )}
      </div>
    );
  };

  _renderUserPosition = () => {
    if (!this.state.assistanceRequestData) return;
    const { firstName, lastName, email, userPositions } =
      this.state.assistanceRequestData;
    const { geoPosition, lastUpdated } =
      userPositions[userPositions.length - 1];
    const [longitude, latitude] = geoPosition.coordinates;
    const displayRequestFrom =
      firstName && lastName ? `${firstName} ${lastName}` : email;
    const displayLastUpdated = moment
      .parseZone(lastUpdated)
      .format("DD/MM/YYYY HH:mm");
    var firstnamestring = "Find " + firstName;

    if (!geoPosition || !longitude || !latitude) return;

    return (
      <>
        <Marker
          latitude={latitude + 0.0005}
          longitude={longitude + 0.0005}
          offsetLeft={-20}
          offsetTop={-40}
        >
          <CurrentLocation colour="#0000FF" />
        </Marker>
        {this.state.showAssistantInformation && (
          <Popup
            anchor="bottom"
            tipSize={5}
            maxWidth={240}
            longitude={longitude + 0.0005}
            latitude={latitude + 0.0005}
            offsetLeft={-20}
            offsetTop={-40}
            closeButton={true}
            closeOnClick={true}
          >
            <div className={styles["pop-up-inner"]}>{displayRequestFrom}</div>
            <small>Last updated: {displayLastUpdated}</small>
            <br />
            <div className={styles["Button"]}>
              <div className={styles["button-container"]}>
                <button
                  type="button"
                  className={styles["popup-button"]}
                  onClick={() =>
                    this.updateStartEndLocation(
                      this.state.currentPosition,
                      geoPosition,
                      "Current Location",
                      firstName
                    )
                  }
                  onKeyPress={this.finish}
                >
                  <span className={styles["button-span"]}>
                    {firstnamestring}
                  </span>
                </button>
              </div>
            </div>
          </Popup>
        )}
      </>
    );
  };

  _renderActiveTrafficMarker = () => {
    return (
      <div>
        {this.state.markerTrafficPopup && (
          <MapMarkerTrafficPopup
            activeTrafficObject={this.state.activeTrafficObject}
            updateCloseCallBack={this.updateTrafficPopupCloseCallBack}
          />
        )}
      </div>
    );
  };

  _renderLoading = () => {
    const { showLoading, showInitialLoading, showLoadingJourneyMessages } =
      this.state;
    return (
      <>
        {showLoading && (
          <ErrorBoundary>
            <Loading fullscreen={true} />
          </ErrorBoundary>
        )}
        {showInitialLoading && <InitialLoadingMessages />}
        {showLoadingJourneyMessages && <JourneyLoadingMessages />}
      </>
    );
  };

  handleMapFocus = () => {
    this.setState({ mapFocusActived: true });
  };

  handleMapBlur = () => {
    this.setState({ mapFocusActived: false });
  };

  // Logic to handle scrolling in embedded versions of the app.
  handleTouchStart = (event) => {
    if (this.state.mapLocked) return;
    const isMobile = this.refs.Map?.clientWidth < MOBILE_VIEW_THRESHOLD;
    if (!isMobile) return;

    if (event?.points.length === 2) {
      this.reactMap.getMap().scrollZoom.enable();
      this.reactMap.getMap().dragPan.enable();
    }
  };

  handleTouchEnd = () => {
    const isMobile = this.refs.Map?.clientWidth < MOBILE_VIEW_THRESHOLD;
    if (isMobile) {
      this.reactMap.getMap().scrollZoom.disable();
      this.reactMap.getMap().dragPan.disable();
    }
  };

  handleUpdateMarkerOptions = () => {
    if (!this.state.destinationGroup.hasDiversions) {
      return;
    }
    let date =
      this.state.selectedDateTime === null
        ? moment.parseZone().format()
        : moment(this.state.selectedDateTime).format();

    let markerOptionsEndpoint = `markerOptionsWithGSI/${
      this.state.destinationGroup.shortCode
    }?dateTimeOffset=${encodeURIComponent(date)}`;
    this._restServiceGet(markerOptionsEndpoint)
      .then((response) => {
        if (response.data) {
          const prevState = this.state;
          this.setState(
            {
              markerOptions: response.data,
            },
            () => {
              if (this.state.markerOptions !== undefined) {
                for (
                  let index = 0;
                  index < this.state.markerOptions.length;
                  index++
                ) {
                  if (
                    (this.state.markerOptions[index].initialDisplay !==
                      undefined &&
                      this.state.markerOptions[index].initialDisplay ===
                        true) ||
                    (prevState.markerOptions &&
                      prevState.markerOptions[index] &&
                      prevState.markerOptions[index].isFocused)
                  ) {
                    this.markerOptionsAction(index);
                  }
                }
              }
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error while getting the marker options: " + error);
      });
  };

  _renderMap = () => {
    const {
      showInitialLoading,
      showLoadingJourneyMessages,
      showResults,
      showForm,
      viewport,
      events,
      showConfirmation,
      isMultiDestination,
      customMapURL,
      mapNotReadyToRender,
      markerOptions,
      errorMapLoad,
      width,
      journeys,
      activeJourney,
      clusteringEnabled,
      singleNonMDEvent,
      mapBounds,
    } = this.state;

    const changeOptions = { duration: 1000 };
    const mapStylePath = customMapURL ? customMapURL : MAPTILER_PATH_WITH_TOKEN;
    const showButton = showResults && activeJourney !== null;

    mapService.validateMapPath(mapStylePath, (validPath) => {
      if (mapStylePath !== validPath) {
        this.setState({ customMapURL: validPath });
      }
    });

    const isMobile = this.refs.Map?.clientWidth < MOBILE_VIEW_THRESHOLD;

    return (
      !errorMapLoad &&
      !mapNotReadyToRender &&
      !showConfirmation &&
      !showInitialLoading &&
      !showLoadingJourneyMessages &&
      ((showForm && width > 600) || !showForm) && (
        <>
          <div
            ref="JourneyMap"
            className={
              (this.state.showResults && this.state.journeys) ||
              this.state.showForm
                ? styles["JourneyMapWrapper"]
                : styles["MapWrapper"]
            }
            aria-hidden="true"
            tabIndex="-1"
          >
            <ThemeContext.Consumer>
              {(theme) => (
                <ReactMapGL
                  onTouchstart={this.handleTouchStart}
                  onTouchmove={this.handleTouchMove}
                  onTouchend={this.handleTouchEnd}
                  key={this.state.updateMap}
                  onfocus={this.handleMapFocus}
                  onblur={this.handleMapBlur}
                  mapStyle={mapStylePath}
                  ref={(reactMap) => (this.reactMap = reactMap)}
                  mapboxApiAccessToken={MAPBOX_TOKEN}
                  className={
                    (this.state.showResults && this.state.journeys) ||
                    this.state.showForm
                      ? styles["JourneyMap"]
                      : styles["Map"]
                  }
                  {...viewport}
                  onViewportChange={this.onViewportChange}
                  viewportChangeMethod={"flyTo"}
                  viewportChangeOptions={changeOptions}
                  scrollZoom={!this.state.mapLocked && !isMobile}
                  dragPan={!this.state.mapLocked && !isMobile}
                  style={{
                    top:
                      this.state.headerMapVerticalOffset && width > 600
                        ? "44px"
                        : "0px",
                    height: theme?.hideClientElements?.includes("hideFooter")
                      ? "102%"
                      : "100%",
                  }}
                >
                  {((showForm && width > 600) || (showResults && journeys)) && (
                    <>
                      {this._renderJourneyPolylines()}
                      {this._renderActivePolyline()}
                      {this._renderPolylineIcons()}
                      {this._renderEvChargePoint()}
                      {this._renderSelectedLocations()}
                      {this._renderUserPosition()}
                    </>
                  )}
                  {this.state.showPolyPopUp && this._renderPolylinePopUp()}
                  {this._renderPolylines()}
                  {this._renderCurrentLocation()}
                  {this._renderActiveEvent()}
                  {this._renderActiveTrafficMarker()}
                  {this._renderTrafficData()}
                  {this._renderViewportMarker()}
                  <MarkerGroups
                    _restServicePost={this._restServicePost}
                    queryString={this.props.queryString}
                    showPrimaryGroup={!showResults && !showForm}
                    primaryGroupShortCode={this.state.destination}
                    primaryGroupEvents={events}
                    isMultiDestination={isMultiDestination}
                    setActiveEventSelectedOnMap={
                      this.setActiveEventSelectedOnMap
                    }
                    togglePolyPopUp={this.togglePolyPopUp}
                    singleNonMDEvent={singleNonMDEvent}
                    clusteringEnabled={clusteringEnabled}
                    onClickCluster={this.onClickCluster}
                    markerOptions={markerOptions}
                    zoom={viewport.zoom}
                    mapBounds={mapBounds}
                  />
                </ReactMapGL>
              )}
            </ThemeContext.Consumer>
          </div>
          <div
            className={`${styles["right-side-bar"]} ${
              showButton ? styles["right-side-bar-show-travel"] : ""
            }`}
          >
            <div role="region" className={styles["map-lock"]}>
              {isMobile ? (
                ""
              ) : (
                <img
                  data-cy={"lockMapButton"}
                  title={
                    this.state.mapLocked
                      ? "Unlock to move or zoom map"
                      : "Lock map to enable page scrolling"
                  }
                  tabIndex="0"
                  role="button"
                  src={this.state.mapLocked ? mapLockImg : mapUnlockImg}
                  alt={
                    this.state.mapLocked
                      ? "Unlock to move or zoom map"
                      : "Lock map to enable page scrolling"
                  }
                  width="46px"
                  height="46px"
                  style={{
                    marginTop: "5px",
                  }}
                  onClick={this.lockUnlockMap}
                />
              )}
              <img
                title={"Show on Map"}
                data-cy={"mapSettings"}
                tabIndex="1"
                role="button"
                src={
                  this.state.markerOptions.find(
                    (markerOption) => markerOption.isFocused
                  )
                    ? settingsActive
                    : settingsInactive
                }
                alt={"Map Settings"}
                width="46px"
                height="46px"
                style={{
                  marginTop: "5px",
                }}
                onClick={this.toggleMarkerModal}
              />
              <img
                title={"mapCloseToggle"}
                tabIndex="2"
                role="button"
                src={this.state.fullscreen ? mapClose : mapExpand}
                alt={"mapCloseToggle"}
                width="46px"
                height="46px"
                className={styles["fullscreen-button"]}
                onClick={this.toggleFullscreen}
              />
            </div>
          </div>
        </>
      )
    );
  };

  _renderMenu = () => {
    const { menu, organisation } = this.state;
    return (
      menu && (
        <Menu
          organisation={organisation}
          token={this.props.apiToken}
          showProfile={this.resetToggleProfile}
          showMenu={this.toggleMenu}
          thirdPartyAuthenticated={this.state.thirdPartyAuthenticated}
          auth={this.props.auth}
          handleLoginOperation={this.handleLoginOperation}
          logout={this.logout}
        />
      )
    );
  };

  _renderDisruptionsModal = () => {
    const { displayDisruptionsModal, disruptionMessages } = this.state;
    return (
      displayDisruptionsModal && (
        <DisruptionsModal
          toggleDisruptionsModal={this.toggleDisruptionsModal}
          disruptionsMessages={disruptionMessages}
          isUserWayActive={this.state.isUserWayActive}
        />
      )
    );
  };

  _renderProfile = () => {
    const { profile } = this.state;
    return (
      profile && (
        <Profile
          token={this.props.apiToken}
          getprofilefilter={this.getProfileFilters}
          profilefilters={this.state.profilefilter}
          profileId={this.state.profileId}
          saveprofilefilter={this.saveEditProfileInfo}
          toggleProfile={this.toggleProfile}
          toggleRequestAssistance={this.useCurrentLocation}
          previousTravelPlans={this.state.previousTravelPlans}
          isTravelProfileItinerary={this.state.isTravelProfileItinerary}
          pastTravelPlans={this.state.pastTravelPlans}
          journeyOptions={this.state.journeys.journeyOptions}
          getPreviousTravelPlan={this.getPreviousTravelPlan}
          thirdPartyAuthenticated={this.state.thirdPartyAuthenticated}
          auth={this.props.auth}
          logout={this.logout}
          travelPlans={this.getFilterUserTravelPlans}
          destinationGroup={this.state.destination}
          assistanceSettings={this.state.assistanceSettings}
          destinationGroupAssistanceSettings={
            this.state.destinationGroupAssistanceSettings
          }
          createUserProfile={this.createUserProfile}
          defaultFrom={this.state.defaultFrom}
          defaultTo={this.state.defaultTo}
          travelForecast={this.state.travelForecast}
          editProfile={this.state.editProfile}
          toggleEditProfile={this.toggleEditProfile}
        />
      )
    );
  };

  _renderRequestAssistance = () => {
    const { requestAssistance, travelForecastId, showPendingAssistance } =
      this.state;
    return (
      requestAssistance && (
        <RequestAssistance
          showRequestAssistance={this.toggleRequestAssistance}
          toggleConfirmation={this.toggleConfirmation}
          toggleAssistanceProfileOnConfirmation={
            this.toggleAssistanceProfileOnConfirmation
          }
          logout={this.logout}
          destinationGroup={this.state.destination}
          assistanceSettings={this.state.assistanceSettings}
          destinationGroupAssistanceSettings={
            this.state.destinationGroupAssistanceSettings
          }
          travelForecastId={travelForecastId}
          apiToken={this.props.apiToken}
          showPendingAssistance={showPendingAssistance}
          currentPosition={this.state.currentPosition}
        />
      )
    );
  };

  _renderSharing = () => {
    const {
      sharing,
      destinationGroupName,
      activeEvent,
      journeyPlanID,
      journeyOptionId,
      destinationGroup,
    } = this.state;

    return (
      sharing && (
        <Sharing
          initialUrlExtension={this.props.initialUrlExtension}
          eventName={destinationGroupName}
          destinationGroup={destinationGroup?.shortCode}
          journeyPlanID={journeyPlanID}
          journeyOptionId={journeyOptionId}
          image={activeEvent?.image}
          toggleSharepage={this.toggleSharing}
        />
      )
    );
  };

  render() {
    return (
      <div>
        <div className={styles["Event"]} ref="Map">
          {this._renderEditJourney()}
          {this._renderMap()}
          {this._renderJourneyCollection()}
          {this._renderErrorMessage()}
          {this._renderAddNotification()}
          {this._renderLoading()}
          {this._renderConfirmation()}
          {this._renderSharing()}
          {this._renderProfile()}
          {this._renderDisruptionsModal()}
          {this._renderMenu()}
          {this._renderRequestAssistance()}
          {this._renderCrossSiteWarning()}
          {this._renderThirdPartyPopup()}
          {this._renderInfoPopup()}
          <ThemeContext.Consumer>
            {(theme) => (
              <div>
                {!theme?.hideClientElements?.includes("hideFooter") ? (
                  <Footer
                    organisation={this.state.organisation}
                    toggleSharepage={this.toggleSharing}
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </ThemeContext.Consumer>
        </div>
      </div>
    );
  }
}

export default withRouter(Event);
